export const offered_servicesListKeys = [
  "listed_docs",
  "pharmacies_list",
  "labs_list",
];
export const convertDataForPublishing = (currentData) => {
  const _publishData = {
    facility_name: "",
    facility_address: "",
    google_place_id: "",
    ticker_text_list: [],
    facility_welcome_message: "",
    facebook_link: "",
    linkedin_link: "",
    twitter_link: "",
    youtube_link: "",
    enable_consultation: false,
    doc_id_list: [],
    enable_pharmacy: false,
    ph_id_list: [],
    enable_lab: false,
    lab_id_list: [],
  };
  if ("facility_name" in currentData) {
    _publishData.facility_name = currentData.facility_name;
  }
  if ("address" in currentData) {
    _publishData.facility_address = currentData.address;
  }
  if ("google_place_id" in currentData) {
    _publishData.google_place_id = currentData.google_place_id;
  }

  if (
    "ticker_texts" in currentData &&
    Array.isArray(currentData.ticker_texts) &&
    currentData.ticker_texts.length
  ) {
    _publishData.ticker_text_list = currentData.ticker_texts;
  }

  if ("facility_social_links" in currentData) {
    const { facility_social_links } = currentData;
    if (
      typeof facility_social_links === "object" &&
      !Array.isArray(facility_social_links) &&
      facility_social_links !== null &&
      Object.keys(facility_social_links).length
    ) {
      for (const link of Object.keys(facility_social_links)) {
        const linkValue = facility_social_links[link];
        if (linkValue && typeof linkValue === "string" && linkValue.length) {
          _publishData[link] = linkValue;
        }
      }
    }
  }

  if ("offered_services" in currentData) {
    const { offered_services } = currentData;
    if (Array.isArray(offered_services) && offered_services.length) {
      for (const service of offered_services) {
        if ("listed_docs" in service) {
          _publishData.enable_consultation = service.is_enabled;
          if (
            Array.isArray(service.listed_docs) &&
            service.listed_docs.length
          ) {
            _publishData.doc_id_list = service.listed_docs;
          }
        }
        if ("pharmacies_list" in service) {
          _publishData.enable_pharmacy = service.is_enabled;
          _publishData.ph_id_list = service.pharmacies_list;
          if (
            Array.isArray(service.pharmacies_list) &&
            service.pharmacies_list.length
          ) {
            _publishData.ph_id_list = service.pharmacies_list;
          }
        }
        if ("labs_list" in service) {
          _publishData.enable_lab = service.is_enabled;
          if (Array.isArray(service.labs_list) && service.labs_list.length) {
            _publishData.lab_id_list = service.labs_list;
          }
        }
      }
    }
  }

  if ("welcome_message" in currentData) {
    _publishData.facility_welcome_message = currentData.welcome_message;
  }

  return _publishData;
};
