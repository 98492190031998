import { Label } from "../../common";
import PathologyLabCard from "./PathologyLabCard";

const PathologyLabs = ({ labs, name }) => {
  return (
    <div>
      <Label fontWeight="bold" className="p-3 pb-0" variant="h5">
        {name}
      </Label>
      {labs.map((lab, index) => (
        <PathologyLabCard key={index} lab={lab} />
      ))}
    </div>
  );
};

export default PathologyLabs;
