import React, { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Field, ErrorMessage, Form, FieldArray, Formik } from "formik";
import ModeContext from "../../context/mode-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import TextError from "./TextError";
import DeleteIcon from "@mui/icons-material/Delete";
import CreatableSelect from "react-select/creatable";
import Grid from "@mui/material/Grid";
import Toast from "../ui/Toast";
import { IconButton } from "@mui/material";
import { countriesList } from "../Countrylist";
import {Select, MenuItem} from "@mui/material";
const AddOrderForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropdowndetail, setDropdowndetail] = useState([]);
  const { mode } = useContext(ModeContext);
  const hos_id = searchParams.get("hospitalId");
  const category = searchParams.get("category");
  const [orderId, setOrderId] = useState();
  const [dtrue, setDtrue] = useState(0);
  const [adisabled, setAdisabled] = useState([false]);
  const [popupdata, setPopupdata] = useState(null);
  const [total, setTotal] = useState(0);
  const [total_tax, setTotal_tax] = useState(0);
  const [avialableservices, setAvialableSevices] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [phoneCode, setPhoneCode] = useState("+91"); // Add state for phone code

  const [initialValues, setInitialValues] = useState({
    customer_name: ``,
    country_code: phoneCode,
    phone: ``,
    email: ``,
    gstin: ``,
    address: ``,
    paymentMode: "cash",
    line_items: [
      {
        rate: 0,
        details: "",
        quantity: 1,
        discount: 0,
        gst_percentage: '',
        calc_amount: '',
      },
    ],
  });

  const navigate = useNavigate();

  const BackToMenu = () => { 
    navigate('/app/billing/Orders')  
  };

  const handleCloseButtonClick = (params) => {
    navigate(`/app/billing/Orders/details?hospitalId=${hos_id}&category=${category}&orderId=${orderId}`);
  };

  useEffect(() => {
    setInitialValues(initialValues);
  }, [initialValues.email]);


  const handlePhoneCodeChange = (event) => {
    const newCode = event.target.value;
    setPhoneCode(newCode);
    setInitialValues({
      ...initialValues,
      country_code: newCode
    });
  };
  const validationSchema = Yup.object({
    customer_name: Yup.string().required("required"),
    country_code: Yup.string().required("required"),
    phone: Yup.number().min(1000000000, "Not a valid Number").max(9999999999, "Not a valid Number"),
    line_items: Yup.array().of(
      Yup.object({
        details: Yup.string().required("pl fill the service to be taken"),
        quantity: Yup.number().min(1, "pl share the Quantity "),
        discount: Yup.number().min(0, " Cannot be negative"),
        rate: Yup.number().min(0.01, "pl share the Rate"),
        gst_percentage : Yup.number().min(0, "Cannot be Negative"),
      })
    ),
  });

  const totalCALC = (index, values) => {
    values.line_items[index].discount = values.line_items[index].discount * 1;
    values.line_items[index].rate = values.line_items[index].rate * 1;
    values.line_items[index].gst_percentage = values.line_items[index].gst_percentage * 1;
    values.line_items[index].quantity = values.line_items[index].quantity * 1;

    const x = values.line_items[index].rate * values.line_items[index].quantity - values.line_items[index].discount;
    const linegst = x * (values.line_items[index].gst_percentage / 100);

    values.line_items[index].calc_amount = (x + linegst) * 1;

    setTotal_tax(linegst + total_tax);
    setTotal(values.line_items[index].calc_amount + total);
    setInitialValues(values);
  };

  const RemoveLine = (index, values) => {
    adisabled.splice(index, 1);  

    const Y = values.line_items[index].rate * values.line_items[index].quantity - values.line_items[index].discount;
    const linegst = Y * (values.line_items[index].gst_percentage / 100);
    setTotal_tax(total_tax - linegst);
    setTotal(total - (Y + linegst));

    setInitialValues(values);
  };

  const postData = async (values) => {
    const { line_items } = values;
    const linest = line_items.map((item) => ({ 
      rate: item.rate * 100, 
      details: item.details, 
      quantity: item.quantity, 
      discount: item.discount * 100, 
      gst_percentage: item.gst_percentage, 
      calc_amount: item.calc_amount * 100 
    }));
    
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.post(RX_OPD_ENDPOINTS.HOSPITAL.OPD.CREATE_SERVICE_ORDER + `/${hos_id}/${values.paymentMode}`, {
        customer_name: `${values.label}`,
        country_code: `${phoneCode}`,
        phone: `${values.phone}`,
        email: `${values.email}`,
        gstin: `${values.gstin}`,
        address: `${values.address}`,
        line_items: linest,
      });

      if (res) {
        setToastMessage(res.data.message);
        setShowToast(true);
        setToastType("success");
        setOrderId(res.data.order_id);
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const url = `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.CUSTOMER_DETAILS}/${hos_id}`;

        const res = await rxOpdApi.get(url);

        if (res) {
          const optionscity = res.data.records.map((item) => ({ label: item.customer_name, value: item.customer_name, ...item }));
          setDropdowndetail(optionscity);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {}
    };
    getData();
  }, []);

  useEffect(() => {
    const get1Data = async () => {
      const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      try {
        rxOpdApi.setAuthHeaders(key, secret);

        const url = `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.FETCH_SERVICES}/${hos_id}`;
        const res = await rxOpdApi.get(url);

        if (res) {
          const optionscity1 = res.data.services.map((item) => ({ label: item, value: item }));
          setAvialableSevices(optionscity1);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {}
    };
    get1Data();
  }, []);

  const onSubmit = (values) => {
    postData(values);
  };

  const fieldsheading = [
    {key:1, val:"Item Details", col:[2.4, 2.4, 2.4, 1.9, 1.9]},
    {key:2, val:"Quantity", col:[1.8, 1.65, 1.65, 1.35, 1.35]},
    {key:3, val:"Rate (INR)", col:[1.7, 1.65, 1.65, 1.34, 1.34]},
    {key:4, val:"Discount", col:[1.69, 1.65, 1.65, 1.34, 1.34]},
    {key:5, val:"GST (%)", col:[1.75, 1.69, 1.7, 1.34, 1.34]},
    {key:6, val:"Amount", col:[1.75, 1.65, 1.65, 1.34, 1.34]},
  ];

  return (
    <>
      <button style={{ float: "right", width: "50px", backgroundColor: "white", border: "none", height: "20px" }} type="button" onClick={BackToMenu}>
        <span aria-hidden="true" style={{ color: "black", border: "none", fontSize:"40px" }}>&times;</span>
      </button>

      <Formik initialValues={popupdata || initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <h5 className="mediafontcontrol" style={{ width: "100%", textAlign: "center", color: "grey", marginBottom: "20px" }}>ORDER</h5>

            <div style={{ display: "flex", flexDirection: "column" }} className="form-controlname">
              <label htmlFor="customer_name"></label>
              <Field name="customer_name" id="customer_name" style={{ boxShadow: "5px 5px 5px lightgray", borderRadius: "3px", paddingLeft: "10px", minWidth: "250px", flexGrow: "1" }}>
                {({ field, form }) => (
                  <CreatableSelect
                    {...field}
                    isClearable
                    value={field.label}
                    className="mediafontcontrol"
                    placeholder="Type or Select customer"
                    options={dropdowndetail}
                    onChange={(option) => {
                      const { value, label, ...rest } = option;
                      if (!option.country_code) option.country_code = phoneCode;
                      if (!option.phone) option.phone = "";
                      if (option.email) option.email = "";
                      if (!option.gstin) option.gstin = "";
                      if (!option.address) option.address = "";
                      let merged = {
                        ...option,
                        ...{
                          customer_name: `${label}`,
                          paymentMode: "cash",
                          line_items: [{ rate: "", details: "", quantity: "1", discount: "", gst_percentage: "", calc_amount: "" }],
                        },
                      };
                      setPopupdata(merged);
                      setInitialValues(merged);
                    }}
                    styles={{
                      control: (controlStyles) => ({
                        ...controlStyles,
                        border: "2px solid #b3c6e7",
                        borderRadius: "5%",
                        maxWidth: "100%",
                        height: "30%",
                        padding: "0",
                        margin: "0",
                      }),
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="customer_name" component={TextError} />
            </div>

            <div className="Header2">
            <Select
                value={phoneCode}
                onChange={handlePhoneCodeChange}
                style={{ 
                  maxWidth: "100px",
                  height: "35px",
                  marginRight: "10px",
                  border: "2px solid lightblue",
                  backgroundColor: "lightgrey"
                }}
              >
                {countriesList
                  .slice()
                  .sort((a, b) => a.code.localeCompare(b.code))
                  .map((country, index) => (
                    <MenuItem
                      key={`${country.code}-${index}`}
                      value={country.code}
                      style={{ marginRight: "5rem" }}
                    >
                      {country.code}
                    </MenuItem>
                  ))}
              </Select>
              <label htmlFor="phone"></label>
              <Field style={{ width: "min(50%,250px)", paddingLeft: "5px", height:"35px", border:"2px solid lightblue"}} type="number" name="phone" id="phone" placeholder="Enter mobile number" />
              <ErrorMessage name="phone" component={TextError} />

              <label htmlFor="email"></label>
              <Field style={{ width: "min(50%,250px)", paddingLeft: "5px", height:"35px", border:"2px solid lightblue" }} type="email" name="email" id="email" placeholder="Email" />
              <ErrorMessage name="email" component={TextError} />
            </div>

            <div className="Header2">
              <label htmlFor="gstin"></label>
              <Field style={{ width: "min(20%,200px)", marginRight: "3px", paddingLeft: "5px", border:"2px solid lightblue" }} type="text" name="gstin" id="gstin" placeholder="GST(Optional)" />
              <ErrorMessage name="gstin" component={TextError} />

              <label htmlFor="address"></label>
              <Field style={{ width: "min(80%,470px)", paddingLeft: "5px", border:"2px solid lightblue"}} as="textarea" name="address" id="address" placeholder="Address(Optional)" />
              <ErrorMessage name="address" component={TextError} />
            </div>

            <h5 style={{ maxWidth: "100%", textAlign: "left", margin:"20px 30px 20px" }}>Order Details</h5>
          
            <Grid container style={{marginLeft: "1rem"}}>
              {fieldsheading.map((item, index) => (
                <Grid 
                  item
                  key={index}
                  xs={item.col[0]} 
                  sm={item.col[1]} 
                  md={item.col[2]} 
                  lg={item.col[3]} 
                  xl={item.col[4]} 
                  style={{
                    color: "grey",
                    padding: "1%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start"
                  }}
                >
                  <h5 className="mediafontcontrol" style={{ margin: 0 }}>
                    {item.val}
                  </h5>
                </Grid>
              ))}
            </Grid>
            <FieldArray name="line_items" id="line_items" style={{ padding: "0" }}>
  {(fieldArrayprops) => {
    const { push, remove, form } = fieldArrayprops;
    const { values } = form;
    const { line_items } = values;
    return (
      <div>
        {line_items?.map((item, index) => (
          <Grid container key={index} style={{marginLeft: "1rem"}}>
            <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.9} xl={1.9}>
              <Field name={`line_items[${index}].details`}>
                {({ field, form }) => (
                  <CreatableSelect
                    {...field}
                    placeholder="Add Services"
                    isClearable
                    options={avialableservices}
                    value={avialableservices.find(option => option.value === field.value) || { label: field.value, value: field.value }}
                    onChange={(option) => {
                      const value = option ? option.value : '';
                      form.setFieldValue(`line_items[${index}].details`, value);
                    }}
                    onCreateOption={(inputValue) => {
                      const newOption = { label: inputValue, value: inputValue };
                      form.setFieldValue(`line_items[${index}].details`, inputValue);
                      setAvialableSevices([...avialableservices, newOption]);
                    }}
                    styles={{
                      control: (controlStyles) => ({ 
                        ...controlStyles, 
                        border: "2px solid #b3c6e7", 
                        borderRadius: "5%", 
                        width: "100%", 
                        height: "60px" 
                      }),
                      container: (base) => ({
                        ...base,
                        width: "100%",
                        padding: "0px",
                        margin: "0",                                      
                      }),
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name={`line_items[${index}].details`} component={TextError} />
            </Grid>
            <Grid item xs={1.8} sm={1.65} md={1.65} lg={1.35} xl={1.35}>
              <Field style={{ width: "100%", height: "60px", textAlign: "center", border: "2px solid grey" }} type="number" name={`line_items[${index}].quantity`} />
              <ErrorMessage name={`line_items[${index}].quantity`} component={TextError} />
            </Grid>
            <Grid item xs={1.7} sm={1.65} md={1.65} lg={1.34} xl={1.34}>
              <Field style={{ width: "100%", height: "60px", border: "2px solid grey", textAlign: "center" }} type="number" name={`line_items[${index}].rate`} />
              <ErrorMessage name={`line_items[${index}].rate`} component={TextError} />
            </Grid>
            <Grid item xs={1.69} sm={1.65} md={1.65} lg={1.34} xl={1.34}>
              <Field type="number" name={`line_items[${index}].discount`} style={{ width: "100%", height: "60px", border: "2px solid grey", textAlign: "center" }} />
              <ErrorMessage name={`line_items[${index}].discount`} component={TextError} />
            </Grid>
            <Grid item xs={1.75} sm={1.69} md={1.7} lg={1.34} xl={1.34}>
              <Field 
                type="number" 
                placeholder="%" 
                name={`line_items[${index}].gst_percentage`}
                style={{ width: "100%", height: "60px", border: "2px solid grey", textAlign: "center" }}                           
                onBlur={() => {
                  totalCALC(index, values);
                }}
              />
              <ErrorMessage name={`line_items[${index}].gst_percentage`} component={TextError} />
            </Grid>
            <Grid item xs={1.75} sm={1.65} md={1.65} lg={1.34} xl={1.34}>
              <Field style={{ width: "100%", backgroundColor: "lightgrey", height: "60px", border: "2px solid grey", textAlign: "center" }} name={`line_items[${index}].calc_amount`} disabled />
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
              <IconButton 
                style={{ 
                  margin: "5px", 
                  padding: "5px",
                  color: "grey", 
                  width: "30px", 
                  height: "35px",
                  display: index === line_items.length - 1 ? "none" : ""
                }} 
                type="button"
                onClick={() => {
                  setDtrue(index);
                  RemoveLine(index, values);
                  remove(index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <button
          className="button1"
          style={{ 
            width: "100px", 
            borderRadius: "15px", 
            height: "40px", 
            margin: "10px 30px"
          }}
          type="button"
          onClick={() => {
            setDtrue(line_items.length);
            push({ 
              rate: "", 
              details: "", 
              quantity: "1", 
              discount: "", 
              gst_percentage: "", 
              calc_amount: "" 
            });
          }}
        >
          + Add line
        </button>
      </div>
    );
  }}
</FieldArray>

<div style={{ display: "flex", flexDirection: "row-reverse", maxWidth: "1020px" }}>
  <div style={{ float: "right", width: "210px", height: "100px", backgroundColor: "#efecec", paddingTop: "10px", textAlign: "left", borderRadius: "5px", marginRight: "10px" }}>
    <div style={{ marginLeft: "10px" }}>
      Sub Total <span style={{ float: "right", marginRight: "15px" }}>{total !== 0 ? (total - total_tax).toFixed(2) : ""}</span>
    </div>
    <div style={{ marginLeft: "10px" }}>
      Tax(Gst) <span style={{ float: "right", marginRight: "15px" }}>{total_tax !== 0 ? total_tax.toFixed(2) : ""}</span>
    </div>
    <div style={{ marginLeft: "10px" }}>
      <b>
        TOTAL(INR) <span style={{ float: "right", marginRight: "15px" }}>{total !== 0 ? total.toFixed(2) : ""}</span>
      </b>
    </div>
  </div>
</div>

<div style={{ margin: "10px 30px" }}>
  <b> Select Payment Mode :</b>
  {/* <Field style={{ margin: "10px" }} type="radio" name="paymentMode" id="payment" value="online" /> */}
  {/* <label htmlFor="onlinePayment" style={{ marginRight: "10px", width: "100px" }}>
    <b>Online </b>
  </label> */}

  <Field type="radio" name="paymentMode" id="payment" value="cash" style={{ marginRight: "10px", marginLeft: "1rem" }} />
  <label htmlFor="CashPayment" style={{ marginRight: "10px", width: "100px" }}>
    <b>Cash</b>
  </label>
</div>

<div style={{ display: "flex", justifyContent: "center" }}>
  <button className="button1" style={{ width: "180px", borderRadius: "15px", height: "40px" }} type="submit">
    Submit
  </button>
</div>
</Form>
)}
</Formik>
{showToast && (
<Toast
  type={toastType}
  show={showToast}
  handleToastClose={setShowToast}
  autohide={true}
  autohideDelay={3000}
>
  {toastMessage}
</Toast>
)}
{(!showToast && toastType === "success") && handleCloseButtonClick()}
</>
);
};

export default AddOrderForm;
