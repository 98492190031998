import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import Button from "../ui/Button";
import Spinner from "react-bootstrap/Spinner";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOneApi } from "../../utils/api/api";
import Toast from "../ui/Toast";

const Notification = ({ staffId, onHide }) => {
  const [whatsappacess, setWhatsappacess] = useState(false);
  const [smsaccess, setSmssccess] = useState(false);
  const [mailaccess, setMailaccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifidata, setNotifidata] = useState(null);
  const[notedata, setNotedata] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  useEffect(() => {
    const fecthmedia = async () => {
      try {
        const userToken = localStorage.getItem("usr_token");
        const res = await rxOneApi.get(
          RX_ONE_ENDPOINTS.SETTINGS.MEDIA_NOTIFICATION +
            "/" +
            userToken +
            "/" +
            staffId
        );
        setNotifidata(res.data);
        setWhatsappacess(res.data.wa_notification);
        setSmssccess(res.data.sms_notification);
        setMailaccess(res.data.email_notification);
      } catch (err) {
        console.error("Error fetching media:", err);
      }
    };
    fecthmedia();
  }, [staffId]);

  const handlemail = () => {
    setMailaccess((prevState) => !prevState);
  };

  const handlesms = () => {
    setSmssccess((prevState) => !prevState);
  };

  const handlewhatsup = () => {
    setWhatsappacess((prevState) => !prevState);
  };

  const handleAddEditUser = async () => {
    
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      const body = {
        wa_notification: whatsappacess,
        sms_notification: smsaccess,
        email_notification: mailaccess,
      };
      const res = await rxOneApi.put(
        RX_ONE_ENDPOINTS.SETTINGS.MEDIA_NOTIFICATION +
          "/" +
          userToken +
          "/" +
          staffId,
        body,
        { headers: { usr_secret: userSecret } }

      );
      setNotedata(res.data);
      setModalVisible(res.data.message)
      setModalVisible(true);

      // Handle response if needed
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };

  return (
    <>
      <p>Switch ON/OFF as per notification preferences</p>
      <div className="switch-icons">
        <div className="d-flex flex-column align-items-center">
          <Form.Check
            className="form-switch-lg mt-1"
            type="switch"
            checked={whatsappacess}
            onChange={handlewhatsup}
            style={{ transform: "scale(1.5)" }}
          />
          <label className="texts">
            <WhatsAppIcon />
          </label>
        </div>

        <div className="d-flex flex-column align-items-center">
          <Form.Check
            className="form-switch-lg mt-1"
            type="switch"
            checked={smsaccess}
            onChange={handlesms}
            style={{ transform: "scale(1.5)" }}
          />
          <label className="texts">
            <SmsIcon />
          </label>
        </div>

        <div className="d-flex flex-column align-items-center">
          <Form.Check
            className="form-switch-lg mt-1"
            type="switch"
            checked={mailaccess}
            onChange={handlemail}
            style={{ transform: "scale(1.5)" }}
          />
          <label className="texts">
            <EmailIcon />
          </label>
        </div>
      </div>

      <Col
        xs={6}
        className="d-flex justify-content-end"
        style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "60px" }}
      >
        <Button
          onClick={handleAddEditUser}
          disabled={isSubmitting}
          type="submit"
          onclose={onHide}
        >
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Save"
          )}
        </Button>
      </Col>
      {modalVisible && (
        <Toast
          type={"success"}
          show={true}
          handleToastClose={onHide}
        >
          {modalMessage}
        </Toast>
      )}
    </>
  );
};

export default Notification;
