import React from "react";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import PageContentRoutes from "../utils/app-content-routes";
import BookletTab from "../components/booklet/bookletTab";
// const facilitiesRoutes = [
//   { path: "book-let", component: <BookletTab /> },

// ];

const opdRoutes = [
  { path: "book-let", component: <BookletTab />, name:"Booklet" },
  // {
  //   path: "payments",
  //   component: <PaymentsTab />,
  // },
  // { path: "Orders", component: showOrderDetails?<OrdersModal sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/>:<OrdersTab sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/> },
  // { path: "invoices", component: <InvoicesTab /> },
  // { path: "Trends", component: <TrendsTab /> },
];

function Booklet() {
  return (
    <PageContentLayout>
    <PageNav routes={opdRoutes} />
    
    <PageContentRoutes routes={[...opdRoutes]} />
      
      {/* { path: "orders/details", component: <DisplayOrder/> }, { path: "orders/create", component: <AddOrderForm /> } */}

  
  </PageContentLayout>
  );
}

export default Booklet;