import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import InputEle from "../register-and-login/InputEle";
import Toast from "../ui/Toast";
import Button from "../ui/Button";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOneApi } from "../../utils/api/api";
import { countriesList } from "../Countrylist";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Typography,
  FormHelperText,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
function AddUserForm(props) {
  const { operation, onHide, user, setShallUpdate } = props;

  const [email, setEmail] = useState(user?.email ?? "");
  const [phone, setPhone] = useState(user?.phone.split("+91")[1] ?? "");
  const [firstName, setFirstName] = useState(user?.first_name ?? "");
  const [lastName, setLastName] = useState(user?.last_name ?? "");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91"); // Add state for phone code

  const handleChanges = (event) => {
    setPhoneCode(event.target.value);
  };
  useEffect(() => {
    if (submittedSuccessfully && !showToast) {
      setShallUpdate(true);
      onHide();
    }
  }, [submittedSuccessfully, showToast]);

  const handleAddEditUser = async (event) => {
    event.preventDefault(); // This prevents the page from refreshing
    setIsSubmitting(true);
    setSubmittedSuccessfully(false);

    let endPoint = RX_ONE_ENDPOINTS.SETTINGS.REGISTER_USER;
    let httpMethod = rxOneApi.axios.post;
    const userToken = localStorage.getItem("usr_token");

    if (operation !== "add") {
      endPoint = RX_ONE_ENDPOINTS.SETTINGS.USER_DETAILS;
      httpMethod = rxOneApi.axios.put;
    }

    try {
      const userSecret = localStorage.getItem("usr_secret");
      const res = await httpMethod(
        endPoint + "/" + userToken + (user ? `/${user.staff_id}` : ""),
        {
          email,
          phone: phoneCode + phone,
          first_name: firstName,
          last_name: lastName,
        },
        { headers: { usr_secret: userSecret } }
      );
      setShowToast(true);
      setToastType("success");
      setToastMessage(res.data.message);

      setSubmittedSuccessfully(true);
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <p>
        {operation === "add"
          ? "Once submitted, autogenerated password will be sent to the Email"
          : "If Email is updated, password will be re-generated for login"}
      </p>

      <form onSubmit={handleAddEditUser}>
        <InputEle
          name="user-email"
          value={email}
          setValue={setEmail}
          notRequired={false}
          type="email"
        />

        <Row className="align-items-center">
          <Col xs={3} className="pe-0">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={phoneCode}
              onChange={handleChanges}
              // disabled
              className="w-23"
            >
              {countriesList
                .slice() // create a copy to avoid mutating original array
                .sort((a, b) => a.code.localeCompare(b.code)) // sort alphabetically
                .map((country, index) => (
                  <MenuItem
                    key={`${country.code}-${index}`}
                    value={country.code}
                    style={{ marginRight: "5rem  !important" }}
                  >
                    {country.code}
                  </MenuItem>
                ))}
            </Select>
          </Col>

          <Col xs={9} className="ps-1">
            <InputEle
              type="tel"
              name="contact-number"
              value={phone}
              setValue={setPhone}
              notRequired={false}
              onInvalid={(F) =>
                F.target.setCustomValidity(
                  "Please enter the correct phone number"
                )
              }
              onInput={(F) => F.target.setCustomValidity("")}
            />
          </Col>
        </Row>

        <Row>
          <Col className="pe-1">
            <InputEle
              type="text"
              name="first-name"
              value={firstName}
              setValue={setFirstName}
              className="mt-0 mb-0"
              notRequired={false}
            />
          </Col>

          <Col className="ps-1">
            <InputEle
              type="text"
              name="last-name"
              value={lastName}
              setValue={setLastName}
              className="mt-0 mb-0"
              notRequired={false}
            />
          </Col>
        </Row>

        <Row className="justify-content-center my-3">
          <Col xs={6} className="d-flex justify-content-end pe-1">
            <Button
              style={{
                backgroundColor: "white",
                border: "1px solid primary",
                color: "black",
              }}
              onClick={() => onHide()}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Col>

          <Col xs={6} className="d-flex justify-content-start ps-1">
            <Button
              // onClick={handleAddEditUser}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </form>

      <Toast type={toastType} show={showToast} handleToastClose={setShowToast}>
        {toastMessage}
      </Toast>
    </>
  );
}

export default AddUserForm;
