import RegisterFacility from "../components/facilities/RegisterFacility";
// import OPD from "../components/facilities/FacilitiesTab";
// import UpdateTab from "../components/facilities/UpdateTab";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import PageContentRoutes from "../utils/app-content-routes";
// import Pharmacy from "../components/facilities/pharmacy/Pharmacy";
// import WebAppTab from "../components/facilities/WebAppTab";
import FacilitiesTab from "../components/facilities/FacilitiesTab/index";

const facilitiesRoutes = [
  // { path: "OPD/*", component: <OPD /> },
  { path: "list", component: <FacilitiesTab />, name: "Facilities" },
  {
    path: "register",
    component: <RegisterFacility />,
    isButton: true,
  },
  // { path: "Pharmacy", component: <Pharmacy /> },
  // { path: "Lab", component: <UpdateTab /> },
  // { path: "update", component: <UpdateTab /> },
  // { path: "web-app", component: <WebAppTab /> },
];
// .map(route => route.path)
function Facilities() {
  return (
    <PageContentLayout>
      <PageNav routes={facilitiesRoutes} />

      <PageContentRoutes routes={facilitiesRoutes} />
    </PageContentLayout>
  );
}

export default Facilities;
