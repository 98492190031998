import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import SelectHospital from "../hospitals/SelectHospital";
import DateRange from "../date-range/DateRange";
import Spinner from "react-bootstrap/Spinner";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import RefreshIcon from "@mui/icons-material/Refresh";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";

const endDateVal = new Date();
const startDateVal = new Date();
startDateVal.setDate(endDateVal.getDate() -2);
endDateVal.setDate(endDateVal.getDate() +1);

const getYyyyMmDdDate = (date) => {
  const month = date.getMonth() + 1;
  const d = date.getDate();

  const mm = month.toString().length === 1 ? `0${month}` : `${month}`;
  const dd = d.toString().length === 1 ? `0${d}` : `${d}`;

  return `${dd}-${mm}-${date.getFullYear()}`;
};

function InvoicesTab(props) {
  const navigate = useNavigate();

  const { mode } = useContext(ModeContext);
  const { currentHospital, isLoading } = useContext(HospitalsContext);
  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(startDateVal);
  const [endDate, setEndDate] = useState(endDateVal);
  const [invoices, setInvoices] = useState([]);
  const [areInvoicesLoading, setAreInvoicesLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [orderId, setorderId] = useState(null);
  const [category, setcategory] = useState();
  const [updateTime, setUpdateTime] = useState(new Date());

  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];
  const [refresh, setRefresh] = useState(false);

  // this conts are for modal
  const [pdfData, setPdfData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  
  // ******************************************
  useEffect(() => {
    const fetchInvoices = async () => {
      if (!currentHospital) {
        setInvoices([]);
        setAreInvoicesLoading(false);
        return;
      }

      setAreInvoicesLoading(true);

      const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          // RX_OPD_ENDPOINTS.HOSPITAL.OPD.LIST_INVOICES +
          RX_OPD_ENDPOINTS.HOSPITAL.OPD.NEWINVOICE_LIST +
            "/" +
            currentHospital.hos_id +
            "/" +
            `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate()}` +
            
            "/" +
            `${endDate.getFullYear()}-${
              endDate.getMonth() + 1
            }-${endDate.getDate()}`
          
        );

        if (res) {
          setInvoices(res.data.records);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
      } finally {
        setAreInvoicesLoading(false);
      }
    };

    fetchInvoices();
  }, [mode, currentHospital, startDate, endDate, refresh]);

  // for calling api for invoice pdf download
  const handleDownloadMedical = async (orderId) => {
    // Define the API URL
    const apiUrl =
      process.env.REACT_APP_RX_OPD +
      RX_OPD_ENDPOINTS.HOSPITAL.OPD.SHOW_INVOICE +
      "/" +
      currentHospital?.hos_id +
      "/" +
      category +
      "/" +
      orderId;

    // Fetch the PDF data
    fetch(apiUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Basic " + btoa(key + ":" + secret),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Convert the PDF blob to a URL and set it in state
        const blobUrl = URL.createObjectURL(blob);
        setPdfData(blobUrl);

        // Open the modal to display the PDF
        setOpenModal(true);
      });
  };

  const handleCloseModal = () => {
    // Close the modal and reset the PDF data
    setOpenModal(false);
    setPdfData(null);
  };

  // event handeler for row to pick id and category

  const handleRowClick = (orderId, category) => {
    setorderId(orderId);
    setcategory(category);
  };

  // Define the columns for the DataGrid
  const columns = [
    {
      field: "date",
      headerName: "DATE",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "invoice_number",
      headerName: "INVOICE",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "order_number",
      headerName: "ORDER",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "category",
      headerName: "CATEGORY",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "customer_name",
      headerName: "CUSTOMER NAME",
      width: 170,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "amount_paid",
      headerName: "AMOUNT",
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
  ];

  // Reorganize the data (rows) for the DataGrid
  const rows = invoices.map((invoice, index) => ({
    id: index,
    date: invoice.date,
    invoice_number: invoice.invoice_number,
    order_number: invoice.order_number,
    customer_name: invoice.customer_name,
    status: invoice.status,
    amount_paid: invoice.amount_paid,
    order_id: invoice.order_id, 
    category: invoice.category, 
  }));
  return (
    <>
      <div>
              <div
          className=" my-4 px-3 py-1"
          style={{
            backgroundColor: "#b3c6e7",
          }}
        >
          Click on Generate Invoice, to View or Print Invoice.
        </div>

        <div className="row justify-content-around align-items-center px-2 mt-4">
          <Col xs={12} lg={4}>
            <SelectHospital type="invoices" />
          </Col>

          <Col
            xs={12}
            lg={8}
            className="d-flex mt-3 mt-lg-0 align-items-center position-relative"
          >
            <DateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <RefreshIcon
              className="d-flex  "
              onClick={() => setRefresh(!refresh)}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-between">
          <div></div>

          <div> 

          {orderId==null?
          
            <button
              className="btn shadow m-2"  
              style={{backgroundColor:"	#D3D3D3"}}            
            >
              Generate
              
            </button>
            :
              <button
              className="btn  shadow m-2"
               disable={orderId === null}              
              onClick={() => handleDownloadMedical(orderId)}
              style={{background:"#009698",color:"white"}}
            >
              Generate
              
            </button>
            }
          </div>
        </div>

        {showToast && (
          <Toast
            type={toastType}
            show={showToast}
            handleToastClose={setShowToast}
          >
            {toastMessage}
          </Toast>
        )}
        {/* table to show invoices  */}

        {areInvoicesLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            onRowClick={(params) =>
              handleRowClick(params.row.order_id, params.row.category)
            }
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
          />
        )}
        {/* this code is for showing the invoice in modal  */}

        <Modal open={openModal} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginTop: "2px" }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            
            <div style={{ height: "500px", width: "100%" }}>
              <object
                data={pdfData}
                type="application/pdf"
                style={{ width: "800px", height: "500px" }}
              >
                An error occurred while fetching the file, please try again
                later
              </object>
            </div>
          </div>
        </Modal>
              </div>
    </>
  );
}

export default InvoicesTab;
