export const FACILITIES_TYPES = {
  HOSPITALS_CLINICS: {
    name: "Hospitals/Clinics",
    isDropdownEnable: true,
    url: "/app/facilities/register",
  },
  PHARMACIES: {
    name: "Pharmacies",
    isDropdownEnable: false,
    url: "",
  },
  PATHOLOGY_LABS: { name: "Pathology Labs", isDropdownEnable: false, url: "" },
};
