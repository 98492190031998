import { Typography } from "@mui/material";

export const Label = ({ children, variant = "subtitle1", ...otherProps }) => {
  return (
    <Typography variant={variant} style={{ color: "#263d50" }} {...otherProps}>
      {children}
    </Typography>
  );
};

export { default as CardRevised } from "../CardRevised";
export { default as Card } from "./card";
