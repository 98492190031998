import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

const PrintOptionsModal = ({ isOpen, onClose, setIsPrintOptionsModalOpen }) => {
    const [withBookletBg, setWithBookletBg] = useState("rgb(16 149 137)");
    const [withoutBookletBg, setWithoutBookletBg] = useState("#d1d5db");
  if (!isOpen) return null;

  return (
    <div style={{
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
    
        <div style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
                padding: "1.5rem",
                maxWidth: "25rem",
                width: "100%",
                marginTop: "9rem",
              }}>
                    <div style={{textAlign: "end", cursor: "pointer"}} onClick={() => setIsPrintOptionsModalOpen(false)}>
        <CloseIcon/>
        </div>
            <h2 style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  textAlign: "center",
                  color: "#1f2937",
                  marginTop: "-1rem",
                }}>
          Print Options
        </h2>
        <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}>
          <button
            onClick={() => onClose(true)}
            // className={`bg-teal-600 text-white font-semibold py-2 px-4 rounded transition-colors duration-300 ${
            //   isHoveredWithBooklet ? 'bg-teal-700' : ''
            // }`}
            style={{
                backgroundColor: withBookletBg,
                color: "white",
                fontWeight: "600",
                padding: "0.5rem 1rem",
                borderRadius: "0.25rem",
                transition: "background-color 0.3s",
              }}
              onMouseOver={() => setWithBookletBg("rgb(15 112 103)")}
              onMouseOut={() => setWithBookletBg("rgb(16 149 137)")}
          >
            With Booklet
          </button>
          <button
            onClick={() => onClose(false)}
            // className={`bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded transition-colors duration-300 ${
            //   isHoveredWithoutBooklet ? 'bg-gray-400' : ''
            // }`}
            style={{
                backgroundColor: withoutBookletBg,
                color: "#1f2937",
                fontWeight: "600",
                padding: "0.5rem 1rem",
                borderRadius: "0.25rem",
                transition: "background-color 0.3s",
              }}
              onMouseOver={() => setWithoutBookletBg("#9ca3af")}
              onMouseOut={() => setWithoutBookletBg("#d1d5db")}
          >
            Without Booklet
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintOptionsModal;