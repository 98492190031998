import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/auth-context";
import ModeContext from "../../../context/mode-context";
import { useNavigate } from "react-router-dom";

import { rxOneApi, rxOpdApi } from "../../../utils/api/api";
import {
  RX_ONE_ENDPOINTS,
  RX_OPD_ENDPOINTS,
} from "../../../utils/api/apiEndPoints";

import Toast from "../../ui/Toast";
import { Spinner } from "react-bootstrap";
import RefreshDataBtn from "../../refresh-data/RefreshDataBtn";
import { Button, Divider, Grid, Menu, MenuItem } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { FACILITIES_TYPES } from "../../../utils/facilities";
import { Label } from "../common";
import Hospitals from "./Hospitals";
import Pharmacies from "./Pharmacies";
import PathologyLabs from "./PathologyLabs";

const FacilitiesTab = () => {
  const { logout } = useContext(AuthContext);
  const { mode } = useContext(ModeContext);
  const navigate = useNavigate();
  const [isFacilitiesDataLoading, setIsFacilitiesDataLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingKyc, setLoadingKyc] = useState(false);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [disableAddFacility, setDisableAddFacility] = useState(
    facilitiesData.length === 0 && !isFacilitiesDataLoading
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (shouldLogout && !showToast) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLogout, showToast]);

  const fetchFacilities = async () => {
    const userKeys = localStorage.getItem("usr_keys");
    let facilities = [];
    if (userKeys) {
      setIsFacilitiesDataLoading(true);
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.FACILITIES.LIST_FACILITIES
        );

        if (
          res?.data &&
          typeof res.data === "object" &&
          !Array.isArray(res.data) &&
          res.data !== null
        ) {
          facilities = res.data;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      } finally {
        setIsFacilitiesDataLoading(false);
      }
    }
    return facilities;
  };

  const fetchKycStatus = async () => {
    setLoadingKyc(true);

    try {
      const userToken = localStorage.getItem("usr_token");

      rxOneApi.setUserSecretAuthHeaders(userToken);
      const kycRes = await rxOneApi.get(
        RX_ONE_ENDPOINTS.USER.KYC_STATUS + "/" + userToken
      );

      if (kycRes) {
        setDisableAddFacility(!kycRes.data.document_verified);
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {
      if (error?.status === 401) {
        if (!document.querySelector(".toast-modal")) {
          setShowToast(true);
          setToastType("error");
          setToastMessage("Invalid session. Please login again.");
          setShouldLogout(true);
        }
      } else {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error?.error?.message || error?.message);
      }
    } finally {
      setLoadingKyc(false);
    }
  };

  const updateFacilitiesData = async () => {
    fetchFacilities().then((facilities) => {
      setFacilitiesData(facilities);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchFacilities().then((facilities) => {
        setFacilitiesData(facilities);
      });
      fetchKycStatus();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = (val) => {
    if (val.url.length) {
      navigate(val.url);
    }
    handleClose();
  };

  const facilitiesJSXData = () => {
    const values = Object.values(FACILITIES_TYPES);
    const _facilities = [];
    if (facilitiesData[values[0].name]) {
      const key = values[0].name;
      const hospitalsData = facilitiesData[key];
      if (Array.isArray(hospitalsData) && hospitalsData.length) {
        const hospitals = (
          <div key={0} className="py-2">
            <Hospitals
              hospitals={hospitalsData}
              name={key}
              updateFacilitiesData={updateFacilitiesData}
            />
          </div>
        );
        _facilities.push(hospitals);
      }
    }

    if (facilitiesData[values[1].name]) {
      const key = values[1].name;
      const pharmacyData = facilitiesData[values[1].name];
      if (Array.isArray(pharmacyData) && pharmacyData.length) {
        const pharmacies = (
          <div key={1} className="py-2">
            <Pharmacies pharmacies={pharmacyData} name={key} />
          </div>
        );
        _facilities.push(pharmacies);
      }
    }

    if (facilitiesData[values[2].name]) {
      const key = values[2].name;
      const labsData = facilitiesData[key];
      if (Array.isArray(labsData) && labsData.length) {
        const labs = (
          <div key={2} className="py-2">
            <PathologyLabs labs={labsData} name={key} />
          </div>
        );
        _facilities.push(labs);
      }
    }

    return _facilities;
  };

  return loadingKyc || isFacilitiesDataLoading ? (
    <div
      className="my-5 py-5 d-flex align-items-center justify-content-center"
      style={{ height: "60vh" }}
    >
      <Spinner as="span" animation="border" role="status" aria-hidden="true" />
    </div>
  ) : (
    <>
      {disableAddFacility && mode === "live" && (
        <p className="mt-3 mx-4">
          You can add new Facility once your KYC is approved.
        </p>
      )}
      {errorMessage.length ? <p className="mt-3 mx-4">{errorMessage}</p> : null}
      <Grid container className="mt-4 mx-3">
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="contained"
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
            className="py-0"
            size="small"
          >
            <div
              className="py-2 d-flex align-items-center"
              style={{
                borderRight: "1px solid white",
                paddingRight: "10px",
              }}
            >
              <AddIcon />
              NEW
            </div>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {Object.values(FACILITIES_TYPES).map((val, index, facilities) => (
              <div key={index}>
                <MenuItem
                  onClick={() => handleMenuClick(val)}
                  disabled={!val.isDropdownEnable}
                >
                  <Label>{val.name}</Label>
                </MenuItem>
                {index !== facilities.length - 1 && <Divider />}
              </div>
            ))}
          </Menu>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <RefreshDataBtn
            type="facilities"
            setData={setFacilitiesData}
            tableDataLoading={isFacilitiesDataLoading}
            setTableDataLoading={setIsFacilitiesDataLoading}
            setErrorMessage={setErrorMessage}
          />
        </Grid>
      </Grid>
      {facilitiesJSXData()}
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
};

export default FacilitiesTab;
