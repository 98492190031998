import React, { useContext, useState } from "react";
import ModeContext from "../../context/mode-context";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tooltip, styled, tooltipClasses } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

function PatientUpload({ currentHospital }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [showInput, setShowInput] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const { mode } = useContext(ModeContext);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleFormSubmit = async (selectedFile) => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];

    try {
      const doctorRegistrationDocsFormData = new FormData();
      doctorRegistrationDocsFormData.append("bulk_file", selectedFile);
      rxOpdApi.setMultipartHeaders();
      rxOpdApi.setAuthHeaders(key, secret);
      console.log("selectedFile", selectedFile);
      const res = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.UPLOAD_PATIENTS}/${currentHospital}`,
        doctorRegistrationDocsFormData
      );
      console.log("API Response", res);
      if (res.data) {
        setData(res.data.response);
        setShowInput(false);
        setShowTable(true);
        return { success: true, message: res.data.message };
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  };
  const handleBackClick = () => {
    setShowInput(true);
    setShowTable(false);
    setSelectedFile(null);
    setData([]);
  };

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      headerAlign: "left",
      width: 100,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "phone",
      headerName: "PHONE",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <a href={`tel:${params.value}`} style={{ textDecoration: "none" }}>
          {params.value}
        </a>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <>
          {params.value === "Failure" ? (
            <p style={{ color: "red" }}>{params.value}</p>
          ) : (
            <p style={{ color: "black" }}>{params.value}</p>
          )}
        </>
      ),
    },
    {
      field: "message",
      headerName: "MESSAGE",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
  ];
  const handleDownloadSampleFile = () => {
    const sampleFileUrl = "/Bulk_Upload_Template.xlsx";

    const downloadLink = document.createElement("a");
    downloadLink.href = sampleFileUrl;
    downloadLink.target = "_blank";
    downloadLink.download = "Bulk_Upload_Template.xlsx";

    downloadLink.click();
  };
  return (
    <div>
      {showInput && (
        <>
          <p className="col-12 ">
            Please use the bulk upload template <a className="link" style={{cursor:"pointer"}}
              onClick={handleDownloadSampleFile}
            >(Bulk_Upload_Template.xls)</a> to add multiple Patients/Users in one go. Edit the template file
            with the user information, chose the file by selecting browse option
            and click Upload.
          </p>
          <div className="file-ip-div col-12 text-center mb-4 d-flex justify-content-center cursor-pointer">
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              className="w-100 mb-1 cursor-pointer"
            />

            <span className="upload-icon">
              <FileUploadOutlinedIcon fontSize="large" />
            </span>
          </div>
        </>
      )}
      {showInput && (
        <button
          className="btn btn-primary  w-100  mb-4"
          type="button"
          onClick={() => handleFormSubmit(selectedFile)}
          disabled={!selectedFile}
        >
          Upload
        </button>
      )}
      {showTable && (
        <div className="d-flex justify-content-end mb-1">
          <button
            className="btn btn-primary justify-content-end"
            type="button"
            onClick={handleBackClick}
          >
            Back
          </button>
        </div>
      )}
      {showTable && data.length > 0 && (
        <Box
          sx={{
            height: 430,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#fafafa",
              fontSize: "0.9rem",
              fontWeight: "bold",
              textAlign: "left",
            },
          }}
        >
          <DataGrid
            rows={data.map((row, index) => ({ ...row, id: index }))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            getRowId={(row) => row.id}
            rowSelection={false}
            //   loading={isLoadingPatients}
          />
        </Box>
      )}
    </div>
  );
}

export default PatientUpload;
