import DatePicker from "react-datepicker";

function DateRange(props) {
  const { startDate, setStartDate, endDate, setEndDate , label } = props;
  const defaultLabel = label || "Select Range:";


  return (
    <>
      <p className="w-auto h-100 my-0">{defaultLabel}</p>
      {/* <p className=" h-100 my-2">: </p> */}
      
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        selectsStart
        startDate={startDate}
        placeholderText="Start Date"
        endDate={endDate}
        maxDate={endDate}
        wrapperClassName="mx-2 w-auto"
        className="w-100"

        // calendarContainer="w-25"
      />

      <DatePicker
        selected={endDate}
        onChange={date => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        // maxDate={new Date()}
        placeholderText="End Date"
        wrapperClassName="mx-2 w-auto"
        className="w-100"
        // calendarContainer="w-25"
      />
    </>
  );
}

export default DateRange;
