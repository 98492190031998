import { Autocomplete, TextField, TextareaAutosize } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const InputTextElement = ({
  value,
  onChange,
  onBlur,
  name = "",
  placeholder,
  variant = "outlined",
  error,
  ...otherProps
}) => {
  return (
    <TextField
      placeholder={placeholder}
      variant={variant}
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className="w-100"
      error={!!error}
      helperText={error}
      sx={{ input: { color: "#263d50" } }}
      InputProps={{
        ...(!!error && {
          endAdornment: (
            <div style={{ marginLeft: "5px" }}>
              <ErrorOutlineIcon color="error" />
            </div>
          ),
        }),
      }}
      {...otherProps}
    />
  );
};

export const InputTextAreaElement = ({
  value,
  onChange,
  onBlur,
  name = "",
  placeholder,
  variant = "outlined",
  error,
  ...otherProps
}) => {
  return (
    <TextField
      placeholder={placeholder}
      variant={variant}
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className="w-100"
      error={!!error}
      helperText={error}
      sx={{ input: { color: "#263d50" } }}
      InputProps={{
        inputComponent: TextareaAutosize,
        ...(!!error && {
          endAdornment: (
            <div style={{ marginLeft: "5px" }}>
              <ErrorOutlineIcon color="error" />
            </div>
          ),
        }),
        inputProps: {
          style: {
            resize: "auto",
          },
          minRows: 3,
        },
      }}
      {...otherProps}
    />
  );
};

export const InputSelectElement = ({
  disabled,
  loading,
  loadingText = "Loading...",
  value,
  onChange,
  onBlur = null,
  name = "",
  options,
  placeholder,
  error,
  multiple = true,
  label = "",
  ...otherProps
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      loading={loading}
      loadingText={loadingText}
      fullWidth
      multiple={multiple}
      limitTags={2}
      disablePortal
      clearOnEscape
      autoHighlight
      autoComplete
      size="small"
      name={name}
      value={value}
      onChange={(e, options) => onChange(options)}
      onBlur={onBlur}
      options={options}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${label} (${options.length})`}
          required={true}
          error={!!error}
          helperText={!!error ? error : ""}
          name={name}
        />
      )}
    />
  );
};
