const accreditationBodyOptions = [
  { label: "National Medical Council (NMC)", value: "NMC" },
  { label: "Andhra Pradesh Medical Council (APMC)", value: "APMC" },
  { label: "Arunachal Pradesh Medical Council (ARUNAPMC)", value: "ARUNAPMC" },
  { label: "Assam Medical Council (ASSAMC)", value: "ASSAMC" },
  { label: "Bihar Medical Council (BMC)", value: "BMC" },
  { label: "Chattisgarh Medical Council (CGMC)", value: "CGMC" },
  { label: "Delhi Medical Council (DMC)", value: "DMC" },
  { label: "Goa Medical Council (GOAMC)", value: "GOAMC" },
  { label: "Gujarat Medical Council (GMC)", value: "GMC" },
  { label: "Haryana Medical Council (HMC)", value: "HMC" },
  { label: "Himanchal Pradesh Medical Council (HPSMC)", value: "HPSMC" },
  { label: "Jammu & Kashmir Medical Council (JKMC)", value: "JKMC" },
  { label: "Jharkhand Medical Council (JMC)", value: "JMC" },
  { label: "Karnataka Medical Council (KMC)", value: "KMC" },
  { label: "Madhya Pradesh Medical Council (MPMC)", value: "MPMC" },
  { label: "Maharashtra Medical Council (MAMC)", value: "MAMC" },
  { label: "Manipur Medical Council (MMC)", value: "MMC" },
  { label: "Mizoram Medical Council (MSMC)", value: "MSMC" },
  { label: "Nagaland Medical Council (NAGAMC)", value: "NAGAMC" },
  { label: "Orissa Council of Medical Registration (OCMR)", value: "OCMR" },
  { label: "Punjab Medical Council (PMC)", value: "PMC" },
  { label: "Rajasthan Medical Council (RMC)", value: "RMC" },
  { label: "Sikkim Medical Council (SMC)", value: "SMC" },
  { label: "Tamil Nadu Medical Council (TNMC)", value: "TNMC" },
  { label: "Telangana State Medical Council (TSMC)", value: "TSMC" },
  {
    label: "Travancore Cochin Medical Council, Trivandrum (TCMC)",
    value: "TCMC",
  },
  { label: "Tripura State Medical Council (TSMC)", value: "TSMC" },
  { label: "Uttarakhand Medical Council (UKMC)", value: "UKMC" },
  { label: "Uttar Pradesh Medical Council (UPMC)", value: "UPMC" },
  { label: "West Bengal Medical Council (WBMC)", value: "WBMC" },
];

export default accreditationBodyOptions;
