import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Label } from "../../common";

import rxOneLogo from "../../../../assets/images/static/rxone-logo.png";

import styles from "./WebAppPreview.module.css";

const initialValues = {
  facility_name: "",
  brand_url: "",
  address: "",
  ticker_texts: [],
  welcome_title: "",
  welcome_message: "",
  facility_social_links: {
    twitter_link: "",
    youtube_link: "",
    facebook_link: "",
    linkedin_link: "",
  },
  services_title: "",
  facility_images_urls: [],
  offered_services: [],
};

const WebAppPreview = ({ previewData }) => {
  const [previewValues, setPreviewValues] = useState(initialValues);

  useEffect(() => {
    if (
      previewData &&
      typeof previewData === "object" &&
      !Array.isArray(previewData) &&
      previewData !== null
    ) {
      setPreviewValues(previewData);
    }
  }, [previewData]);

  return (
    <Grid item lg={6} md={12} xs={12} className="p-3">
      <Grid
        container
        className="shadow p-3"
        style={{ borderRadius: "1.3rem", backgroundColor: "grey" }}
      >
        <Grid
          item
          style={{ backgroundColor: "#FFF", borderRadius: "1.3rem" }}
          lg={12}
          md={12}
          xs={12}
          className={`${styles.rootContainer} shadow`}
        >
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Grid
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                className="py-2"
              >
                <Grid item lg={10} md={10} xs={9}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      lg={2}
                      md={2}
                      xs={3}
                      justifyContent="center"
                      display="flex"
                    >
                      {previewValues.brand_url ? (
                        <img
                          src={
                            previewValues.brand_url +
                            "?v=" +
                            Math.random() * Math.random()
                          }
                          alt="Facility Logo"
                          style={{
                            width: "60px",
                            height: "100%",
                          }}
                        />
                      ) : null}
                    </Grid>
                    <Grid item lg={9} md={9} xs={7}>
                      <Grid container alignItems="center">
                        <Grid item lg={12} md={12} xs={12}>
                          <Label className={styles.facilityName}>
                            {previewValues.facility_name}
                          </Label>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} display="flex">
                          <AddLocationIcon
                            sx={{
                              color: "#e62625",
                            }}
                          />
                          <Label variant="subtitle2" className={styles.address}>
                            {previewValues.address}
                          </Label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} xs={3}>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      justifyContent="center"
                      display="flex"
                    >
                      <PersonOutlineIcon />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      justifyContent="center"
                      display="flex"
                    >
                      <Label>LOGIN</Label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <div className={styles.tickerTextWrapper}>
                    <div className={styles.tickerText}>
                      {previewValues.ticker_texts.join(" | ")}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className="px-4 pb-0 pt-5"
                justifyContent="center"
                display="flex"
              >
                <Grid item lg={12} md={12} xs={12}>
                  <Label className={styles.welcomeTitle}>
                    {previewValues.welcome_title}
                  </Label>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Label className={styles.welcomeMessage}>
                    {previewValues.welcome_message.length > 100 ? (
                      <div>
                        <span>
                          {previewValues.welcome_message.slice(0, 100)}...
                        </span>
                        <span
                          style={{
                            color: "#10828e",
                          }}
                        >
                          Read More
                        </span>
                      </div>
                    ) : (
                      previewValues.welcome_message
                    )}
                  </Label>
                </Grid>
                <Grid item lg={12} md={12} xs={12} overflow="hidden">
                  {previewValues.facility_images_urls &&
                  Array.isArray(previewValues.facility_images_urls) &&
                  previewValues.facility_images_urls.length ? (
                    <OwlCarousel
                      className="owl-theme my-3"
                      autoplay={true}
                      autoplayTimeout="1200"
                      smartSpeed="800"
                      loop={true}
                      margin={10}
                      dots={false}
                    >
                      {previewValues.facility_images_urls.map(
                        (imageUrl, index) => (
                          <div key={index}>
                            <div className={styles.slide}>
                              <img
                                src={
                                  imageUrl +
                                  "?v=" +
                                  Math.random() * Math.random()
                                }
                                alt={`Slide ${index}`}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </OwlCarousel>
                  ) : null}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Label className={styles.welcomeTitle}>
                    {previewValues.services_title}
                  </Label>
                </Grid>
              </Grid>
              <Grid
                container
                className="px-3 py-3"
                justifyContent="center"
                display="flex"
                spacing={2}
                sx={{ minHeight: "200px" }}
              >
                {previewValues?.offered_services &&
                Array.isArray(previewValues?.offered_services) &&
                previewValues?.offered_services.length ? (
                  previewValues.offered_services.map((service, index) => (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      key={index}
                      className={styles.offeredServices}
                    >
                      <Button variant="outlined" disabled>
                        <img src={service.icon_url} alt={service.title} />
                        {service.title}
                      </Button>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Label variant="body2" className="py-5">
                      No services found.
                    </Label>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              justifyContent="center"
              display="flex"
              className="pb-3 pt-2"
            >
              {previewValues.facility_social_links?.facebook_link ? (
                <FacebookIcon
                  sx={{
                    color: "#4267B2",
                    fontSize: "24px",
                    marginRight: "0.4rem",
                  }}
                />
              ) : null}
              {previewValues.facility_social_links?.twitter_link ? (
                <TwitterIcon
                  sx={{
                    color: "#00acee",
                    fontSize: "24px",
                    marginRight: "0.4rem",
                  }}
                />
              ) : null}
              {previewValues.facility_social_links?.youtube_link ? (
                <YouTubeIcon
                  sx={{
                    color: "#CD201F",
                    fontSize: "24px",
                    marginRight: "0.4rem",
                  }}
                />
              ) : null}
              {previewValues.facility_social_links?.linkedin_link ? (
                <LinkedInIcon
                  sx={{
                    color: "#0072b1",
                    fontSize: "24px",
                  }}
                />
              ) : null}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              justifyContent="center"
              display="flex"
            >
              <Grid
                container
                justifyContent="space-between"
                className={styles.footerTerms}
              >
                <Grid
                  item
                  lg={5.75}
                  md={5.75}
                  xs={5.75}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Label className={styles.footerTerms}>Privacy Policy</Label>
                </Grid>
                <Grid
                  item
                  lg={5.75}
                  md={5.75}
                  xs={5.75}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <Label className={styles.footerTerms}>Terms of Usage</Label>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              justifyContent="center"
              display="flex"
              className="py-3"
            >
              <Label className="mx-2" style={{ fontSize: "12px" }}>
                Powered By
              </Label>
              <img
                alt="powered by"
                className={styles.footerLogo}
                src={rxOneLogo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// {
//   "web_app_url_abs": "https://www.dev.care.rxone.app/?mode=test&hosp_id=f1fb3143-d0ec-4aaf-a7e6-f4ff3213",
//   "facility_name": "NABARAJ MULTI-SPECILAITY CLINIC",
//   "brand_url": "https://rxone-static.s3.ap-south-1.amazonaws.com/logo/web_app/test_f1fb3143-d0ec-4aaf-a7e6-f4ff3213.png",
//   "address": "New Street, Some Colony, Gurugram",
//   "google_maps_url": "https://www.google.com/maps/search/?api=1&query=NABARAJ+MULTI-SPECILAITY+CLINICNew+Street,+Some+Colony,+Gurugram&query_place_id=ChIJafzvg35DfDkRxmx2tXh8MIQ",
//   "ticker_texts": [
//     "Emergency services available.",
//     "MON-SAT 10:00 AM to 10:00 PM, SUN (11:00 AM to 06:00 PM)"
//   ],
//   "welcome_title": "Welcome to NABARAJ MULTI-SPECILAITY CLINIC!",
//   "welcome_message": "We're delighted to have you visit our page. At NABARAJ CLINIC, your health and well-being are our top priorities. Whether you're seeking expert medical care, preventative services, or looking for information on our dedicated team, you've come to the right places.",
//   "facility_social_links": {
//     "twitter_link": "https://twitter.com/RxOneCare",
//     "youtube_link": "",
//     "facebook_link": "https://www.facebook.com/rxonecare",
//     "linkedin_link": "https://www.linkedin.com/company/rx-one"
//   },
//   "facility_images_urls": [
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic1.jpg",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic17.jpg",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic2.jpg",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic4.jpeg",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic5.jpg",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic6.png",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic7.png",
//     "https://rxone-static.s3.ap-south-1.amazonaws.com/facility_images/f1fb3143-d0ec-4aaf-a7e6-f4ff3213/landing_page/test_pic8.png"
//   ],
//   "services_title": "Click to start with the services offered by NABARAJ MULTI-SPECILAITY CLINIC:",
//   "offered_services": [
//     {
//       "title": "DOCTOR'S CONSULTATION",
//       "icon_url": "https://rxone-static.s3.ap-south-1.amazonaws.com/icons/doctor_consultation.png"
//     },
//     {
//       "title": "BUY MEDICINE",
//       "icon_url": "https://rxone-static.s3.ap-south-1.amazonaws.com/icons/buy_medicine.png"
//     },
//     {
//       "title": "ORDER TESTS",
//       "icon_url": "https://rxone-static.s3.ap-south-1.amazonaws.com/icons/order_test.jpg"
//     }
//   ]
// }

export default WebAppPreview;
