import React from "react";
import "../../App.css";

const TermsNconditions = () => {
  return (
    <div
      className="custom-scrollbar"
      style={{
        scrollBehavior: "auto",
        overflowX: "hidden",
        textAlign: "justify",
        paddingInline: "50px",
      }}
      responsive
    >
      <p>Last Updated, Mar 12, 2024</p>
      <h5 style={{ color: "blue" }}>RxOne Customer Agreement</h5>
      <br />
      <p>
        This RxOne Customer Agreement (this “Agreement”) contains the terms and
        conditions that govern your access to and use of the Services (as
        defined below) and is an agreement between Rx One Care Private Limited
        (also referred to as “RXONE,” “we,” “us,” or “our”) and you or the
        entity you represent (“you” or “your”). This Agreement takes effect when
        you click an “I Accept” button or check box presented with these terms
        or, if earlier, when you use any of the Services (the “Effective Date”).
        You represent to us that you are lawfully able to enter into contracts
        (e.g., you are not a minor). If you are entering into this Agreement for
        an entity, such as the company you work for, you represent to us that
        you have legal authority to bind that entity. Please see Section 12 for
        definitions of certain capitalized terms used in this Agreement.
      </p>
      <br />
      <p>
        <h5 style={{ color: "blue" }}>1. RXONE Responsibilities</h5> <br />
        <p>
          {" "}
          <span style={{ fontWeight: "bold" }}>1.1 General.</span> You may
          access and use the Services in accordance with this Agreement. Service
          Level Agreements and Service Terms apply to certain Services.
        </p>
        <p>
          {" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            1.2 Third-Party Content.
          </span>{" "}
          Third-Party Content may be used by you at your election. Third-Party
          Content is governed by this Agreement and, if applicable, separate
          terms and conditions accompanying such Third-Party Content, which
          terms and conditions may include separate fees and charges. 1.3 RXONE
          Security. Without limiting Section 8 or your obligations under Section
          2.2, we will implement reasonable and appropriate measures designed to
          help you secure Your Content against accidental or unlawful loss,
          access or disclosure.
        </p>
        <p>
          {" "}
          <span style={{ fontWeight: "bold" }}>1.4 Data Privacy.</span> We will
          not access or use Your Content except as necessary to maintain or
          provide the Services, or as necessary to comply with the law or a
          binding order of a governmental body. We will not (a) disclose Your
          Content to any government or third party or (b) move Your Content from
          the RXONE; except in each case as necessary to comply with the law or
          a binding order of a governmental body. Unless it would violate the
          law or a binding order of a governmental body, we will give you notice
          of any legal requirement or order referred to in this Section 1.4. We
          will only use your Account Information in accordance with the Privacy
          Notice, and you consent to such usage. The Privacy Notice does not
          apply to Your Content.
        </p>
        <p>
          {" "}
          <span style={{ fontWeight: "bold" }}>
            1.5 Notice of Changes to the Services.
          </span>{" "}
          We may change or discontinue any of the Services from time to time. We
          will provide you at least 6 months’ prior notice before discontinuing
          a material functionality of a Service that you are using, or
          materially altering a customer-facing API that you are using in a
          backwards-incompatible fashion, except that this notice will not be
          required if the 6 month notice period (a) would pose a security or
          intellectual property issue to us or the Services, (b) is economically
          or technically burdensome, or (c) would cause us to violate legal
          requirements.
        </p>
        <p>
          {" "}
          <span style={{ fontWeight: "bold" }}>
            1.6 Notice of Changes to the Service Level Agreements.
          </span>{" "}
          We may change, discontinue or add Service Level Agreements, provided,
          however, that we will provide at least 90 days’ advance notice for
          adverse changes to any Service Level Agreement.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>2. Your Responsibilities.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>2.1 Your Accounts. </span>You
          will comply with the terms of this Agreement and all RXONE, rules and
          regulations applicable to your use of the Services. To access the
          Services, you must have an RXONE account associated with a valid email
          address and an approved KYC including the account details where you
          shall receive the collected amount as per defined terms. Unless
          explicitly permitted by the Service Terms, you will only create one
          account per email address. Except to the extent caused by our breach
          of this Agreement, (a) you are responsible for all activities that
          occur under your account, regardless of whether the activities are
          authorized by you or undertaken by you, your employees or a third
          party (including your contractors, agents or End Users), and (b) we
          and our affiliates are not responsible for unauthorized access to your
          account.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>2.2 Your Content.</span> You are
          responsible for Your Content. You will ensure that Your Content and
          your and End Users’ use of Your Content or the Services will not
          violate any of the Policies or any applicable law.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            2.3 Your Security and Backup.
          </span>{" "}
          You are responsible for properly configuring and using the Services
          and otherwise taking appropriate action to secure, protect and backup
          your accounts and Your Content in a manner that will provide
          appropriate security and protection, which might include use of
          encryption to protect Your Content from unauthorized access and
          routinely archiving Your Content.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            2.4 Log-In Credentials and Account Keys.
          </span>{" "}
          RXONE log-in credentials and private keys generated by the Services
          are for your internal use only and you will not sell, transfer or
          sublicense them to any other entity or person, except that you may
          disclose your private key to your agents and subcontractors performing
          work on your behalf.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>2.5 End Users.</span> You will be
          deemed to have taken any action that you permit, assist or facilitate
          any person or entity to take related to this Agreement, Your Content
          or use of the Services. You are responsible for End Users’ use of Your
          Content and the Services, and for their compliance with your
          obligations under this Agreement. If you become aware of any violation
          of your obligations under this Agreement caused by an End User, you
          will immediately suspend access to Your Content and the Services by
          such End User. We do not provide any support or services to End Users
          unless we have a separate agreement with you or an End User obligating
          us to provide such support or services.
        </p>
      </p>

      <br />
      <p>
        <h5 style={{ color: "blue" }}>3. Fees and Payment.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}> 3.1 Service Fees.</span>
          We provide the services basis on pre-paid subscription. For any
          additional services, we calculate and bill fees and charges monthly.
          We may bill you more frequently for fees accrued if we suspect that
          your account is fraudulent or at risk of non-payment. You will pay us
          the applicable fees and charges for use of the Services as subscribed
          using the account details mentioned in your invoice using one of the
          payment methods we support. All amounts payable by you under this
          Agreement will be paid to us without setoff or counterclaim, and
          without any deduction or withholding. Fees and charges for any new
          Service or new feature of a Service will be effective when we post
          updated fees and charges on the RXONE Site, unless we expressly state
          otherwise in a notice. We may increase or add new fees and charges for
          any existing Services you are using by giving you at least 30 days’
          prior notice. We may elect to charge you interest at the rate of 1.5%
          per month (or the highest rate permitted by law, if less) on all late
          payments.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.2 Taxes. </span>
          (a) Each party will be responsible, as required under applicable law,
          for identifying and paying all taxes and other governmental fees and
          charges (and any penalties, interest, and other additions thereto)
          that are imposed on that party upon or with respect to the
          transactions and payments under this Agreement. All fees payable by
          you are exclusive of Indirect Taxes, except where applicable law
          requires otherwise. We may charge and you will pay applicable Indirect
          Taxes that we are legally obligated or authorized to collect from you.
          You will provide such information to us as reasonably required to
          determine whether we are obligated to collect Indirect Taxes from you.
          We will not collect, and you will not pay, any Indirect Tax for which
          you furnish us a properly completed exemption certificate or a direct
          payment permit certificate for which we can claim an available
          exemption from such Indirect Tax. All payments made by you to us under
          this Agreement will be made free and clear of any deduction or
          withholding, as required by law. If any such deduction or withholding
          (including cross-border withholding taxes) is required on any payment,
          you will pay such additional amounts as are necessary so that the net
          amount received by us is equal to the amount then due and payable
          under this Agreement. We will provide you with such tax forms as are
          reasonably requested in order to reduce or eliminate the amount of any
          withholding or deduction for taxes in respect of payments made under
          this Agreement. We will invoice you from our registered office at the
          address of your establishment (as registered with the tax authorities,
          if applicable) receiving the Services in accordance with the
          applicable tax laws. All fees and charges payable under this Agreement
          will be exclusive of applicable national, state or local indirect
          taxes ("Taxes") that RXONE India is legally obligated to charge under
          Indian tax laws. For the purpose of this clause, local indirect taxes
          include Goods and Services Tax (“GST”), which includes the Central
          Goods and Services Tax ("Central Tax"), the State Goods and Services
          Tax ("State Tax"), the Union Territory Goods and Services Tax ("UT
          Tax"), the Integrated Goods and Services Tax ("Integrated Tax") as may
          be applicable. The Taxes charged by RXONE CARE PVT LTD, India will be
          stated in the invoice pursuant to applicable laws. RXONE India may
          charge and you will pay any applicable Taxes, which are stated
          separately on the invoice. As per the statutory requirement under GST,
          you will provide all necessary information such as the correct GST
          registered address, legal name and GSTIN ("GST Information") in order
          for RXONE India to issue correct GST invoices as per the applicable
          legal requirements. In the event, the GST invoice is incorrect, you
          will inform us in a timely manner, to enable RXONE India to correct
          the GST tax invoice. RXONE India will determine the place of supply
          for the Services based on the GST Information provided by you and
          accordingly, charge GST (CGST and SGST/UTGST or IGST) on its invoice.
          Any withholding taxes that may be applicable to the fees and charges
          payable to us are for our account. You will pay the fees and charges
          in our invoice in full (gross) without applying any withholding taxes.
          If you separately deposit applicable withholding taxes on such fees
          and charges to the applicable government treasury and issue us a
          withholding tax certificate evidencing such deposit, following receipt
          of the withholding tax certificate in original form, we will reimburse
          to you an amount equal to the taxes that are evidenced as deposited.
        </p>
      </p>
      <br />
      <p>
        <h5 style={{ color: "blue" }}>4. Temporary Suspension.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>4.1 Generally.</span> We may
          suspend your or any End User’s right to access or use any portion or
          all of the Services immediately upon notice to you if we determine:
          (a) your or an End User’s use of the Services (i) poses a security
          risk to the Services or any third party, (ii) could adversely impact
          our systems, the Services or the systems or Content of any other RXONE
          customer, (iii) could subject us, our affiliates, or any third party
          to liability, or (iv) could be fraudulent; (b) you are, or any End
          User is, in breach of this Agreement; (c) you are in breach of your
          payment obligations under Section 3; or (d) you have ceased to operate
          in the ordinary course, made an assignment for the benefit of
          creditors or similar disposition of your assets, or become the subject
          of any bankruptcy, reorganization, liquidation, dissolution or similar
          proceeding.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>4.2 Effect of Suspension.</span>{" "}
          If we suspend your right to access or use any portion or all of the
          Services: (a) you remain responsible for all fees and charges you
          incur during the period of suspension; and (b) you will not be
          entitled to any service credits under the Service Level Agreements for
          any period of suspension.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>5. Term; Termination.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}> 5.1 Term.</span> The term of
          this Agreement will commence on the Effective Date and will remain in
          effect until terminated under this Section 5. Any notice of
          termination of this Agreement by either party to the other must
          include a Termination Date that complies with the notice periods in
          Section 5.2.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>5.2 Termination.</span>
          (a) Termination for Convenience. You may terminate this Agreement for
          any reason by providing us notice and closing your account for all
          Services for which we provide an account closing mechanism. We may
          terminate this Agreement for any reason by providing you at least 30
          days’ advance notice. (b) Termination for Cause. (i) By Either Party.
          Either party may terminate this Agreement for cause if the other party
          is in material breach of this Agreement and the material breach
          remains uncured for a period of 30 days from receipt of notice by the
          other party. No later than the Termination Date, you will close your
          account. (ii) By Us. We may also terminate this Agreement immediately
          upon notice to you (A) for cause if we have the right to suspend under
          Section 4, (B) if our relationship with a third-party partner who
          provides software or other technology we use to provide the Services
          expires, terminates or requires us to change the way we provide the
          software or other technology as part of the Services, or (C) in order
          to comply with the law or requests of governmental entities.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>5.3 Effect of Termination.</span>
          (a) Generally. Upon the Termination Date: (i) except as provided in
          Sections 5.3(a)(iv) and 5.3(b), all your rights under this Agreement
          immediately terminate; (ii) you remain responsible for all fees and
          charges you have incurred through the Termination Date and are
          responsible for any fees and charges you incur during the
          post-termination period described in Section 5.3(b); (iii) you will
          immediately return or, if instructed by us, destroy all RXONE Content
          in your possession; and (iv) Sections 2.1, 3, 5.3, 6 (except Section
          6.3), 7, 8, 9, 11 and 12 will continue to apply in accordance with
          their terms. (b) Post-Termination. Unless we terminate your use of the
          Services pursuant to Section 5.2(b), during the 30 days following the
          Termination Date: (i) we will not take action to remove from the RXONE
          systems any of Your Content as a result of the termination; and (ii)
          we will allow you to retrieve Your Content from the Services only if
          you have paid all amounts due under this Agreement. For any use of the
          Services after the Termination Date, the terms of this Agreement will
          apply and you will pay the applicable fees at the rates under Section
          3.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>6. Proprietary Rights.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}> 6.1 Your Content.</span> Except
          as provided in this Section 6, we obtain no rights under this
          Agreement from you (or your licensors) to Your Content. You consent to
          our use of Your Content to provide the Services to you and any End
          Users.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>6.2 Adequate Rights.</span> You
          represent and warrant to us that: (a) you or your licensors own all
          right, title, and interest in and to Your Content and Suggestions; (b)
          you have all rights in Your Content and Suggestions necessary to grant
          the rights contemplated by this Agreement; and (c) none of Your
          Content or End Users’ use of Your Content or the Services will violate
          the Acceptable Use Policy.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            6.3 Intellectual Property License.
          </span>{" "}
          The Intellectual Property License applies to your use of RXONE Content
          and the Services.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>6.4 Restrictions.</span> Neither
          you nor any End User will use the RXONE Content or Services in any
          manner or for any purpose other than as expressly permitted by this
          Agreement. Neither you nor any End User will, or will attempt to (a)
          reverse engineer, disassemble, or decompile the Services or RXONE
          Content or apply any other process or procedure to derive the source
          code of any software included in the Services or RXONE Content (except
          to the extent applicable law doesn’t allow this restriction), (b)
          access or use the Services or RXONE Content in a way intended to avoid
          incurring fees or exceeding usage limits or quotas, or (c) resell the
          Services or RXONE Content. The RXONE Trademark Guidelines apply to
          your use of the RXONE Marks. You will not misrepresent or embellish
          the relationship between us and you (including by expressing or
          implying that we support, sponsor, endorse, or contribute to you or
          your business endeavors). You will not imply any relationship or
          affiliation between us and you except as expressly permitted by this
          Agreement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>6.5 Suggestions.</span> If you
          provide any Suggestions to us or our affiliates, we and our affiliates
          will be entitled to use the Suggestions without restriction. You
          hereby irrevocably assign to us all right, title, and interest in and
          to the Suggestions and agree to provide us any assistance we require
          to document, perfect, and maintain our rights in the Suggestions.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>7. Indemnification.</h5>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}> 7.1 General.</span> You will
          defend, indemnify, and hold harmless us, our affiliates and licensors,
          and each of their respective employees, officers, directors, and
          representatives from and against any Losses arising out of or relating
          to any third-party claim concerning: (a) your or any End Users’ use of
          the Services (including any activities under your RXONE account and
          use by your employees and personnel); (b) breach of this Agreement or
          violation of applicable law by you, End Users or Your Content; or (c)
          a dispute between you and any End User. You will reimburse us for
          reasonable attorneys’ fees, as well as our employees’ and contractors’
          time and materials spent responding to any third-party subpoena or
          other compulsory legal order or process associated with third party
          claims described in (a) through (c) above at our then-current hourly
          rates.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>7.2 Intellectual Property.</span>
          (a) Subject to the limitations in this Section 7, RXONE will defend
          you and your employees, officers, and directors against any
          third-party claim alleging that the Services infringe or
          misappropriate that third party’s intellectual property rights, and
          will pay the amount of any adverse final judgment or settlement. (b)
          Subject to the limitations in this Section 7, you will defend RXONE,
          its affiliates, and their respective employees, officers, and
          directors against any third-party claim alleging that any of Your
          Content infringes or misappropriates that third party’s intellectual
          property rights, and will pay the amount of any adverse final judgment
          or settlement. (c) Neither party will have obligations or liability
          under this Section 7.2 arising from infringement by combinations of
          the Services or Your Content, as applicable, with any other product,
          service, software, data, content or method. In addition, RXONE will
          have no obligations or liability arising from your or any End User’s
          use of the Services after RXONE has notified you to discontinue such
          use. The remedies provided in this Section 7.2 are the sole and
          exclusive remedies for any third-party claims of infringement or
          misappropriation of intellectual property rights by the Services or by
          Your Content. (d) For any claim covered by Section 7.2(a), RXONE will,
          at its election, either: (i) procure the rights to use that portion of
          the Services alleged to be infringing; (ii) replace the alleged
          infringing portion of the Services with a non-infringing alternative;
          (iii) modify the alleged infringing portion of the Services to make it
          non-infringing; or (iv) terminate the allegedly infringing portion of
          the Services or this Agreement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>7.3 Process.</span> The
          obligations under this Section 7 will apply only if the party seeking
          defense or indemnity: (a) gives the other party prompt written notice
          of the claim; (b) permits the other party to control the defense and
          settlement of the claim; and (c) reasonably cooperates with the other
          party (at the other party’s expense) in the defense and settlement of
          the claim. In no event will a party agree to any settlement of any
          claim that involves any commitment, other than the payment of money,
          without the written consent of the other party.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>8. Disclaimers.</h5>
        <br />
        THE SERVICES AND RXONE CONTENT ARE PROVIDED “AS IS.” EXCEPT TO THE
        EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY STATUTORY RIGHTS APPLY
        THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND
        LICENSORS (A) MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER
        EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE SERVICES OR RXONE
        CONTENT OR THE THIRD-PARTY CONTENT, AND (B) DISCLAIM ALL WARRANTIES,
        INCLUDING ANY IMPLIED OR EXPRESS WARRANTIES (I) OF MERCHANTABILITY,
        SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
        NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF
        DEALING OR USAGE OF TRADE, (III) THAT THE SERVICES OR RXONE CONTENT OR
        THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL
        COMPONENTS, AND (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE
        LOST OR ALTERED.
      </p>
      <p>
        <h5 style={{ color: "blue" }}>9. Limitations of Liability</h5>
        <br />
        WE AND OUR AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES
        (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES, CUSTOMERS,
        OPPORTUNITIES, GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR
        AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY COMPENSATION,
        REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (A) YOUR INABILITY
        TO USE THE SERVICES, INCLUDING AS A RESULT OF ANY (I) TERMINATION OR
        SUSPENSION OF THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE SERVICES OR
        RXONE CONTENT, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE SERVICES OR
        RXONE CONTENT, OR, (III) WITHOUT LIMITING ANY OBLIGATIONS UNDER THE
        SERVICE LEVEL AGREEMENTS, ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF
        ALL OR A PORTION OF THE SERVICES FOR ANY REASON; (B) THE COST OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (C) ANY INVESTMENTS,
        EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION WITH THIS AGREEMENT OR
        YOUR USE OF OR ACCESS TO THE SERVICES OR RXONE CONTENT; OR (D) ANY
        UNAUTHORIZED ACCESS TO, ALTERATION OF, OR THE DELETION, DESTRUCTION,
        DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA. IN
        ANY CASE, EXCEPT FOR PAYMENT OBLIGATIONS UNDER SECTION 7.2, OUR AND OUR
        AFFILIATES’ AND LICENSORS’ AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL
        NOT EXCEED THE AMOUNT YOU ACTUALLY PAY US UNDER THIS AGREEMENT FOR THE
        SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE
        LIABILITY AROSE. THE LIMITATIONS IN THIS SECTION 9 APPLY ONLY TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
      </p>
      <p>
        <h5 style={{ color: "blue" }}>10. Modifications to the Agreement.</h5>
        <br />
        <p>
          We may modify this Agreement (including any Policies) at any time by
          posting a revised version on the RXONE Site or by otherwise notifying
          you in accordance with Section 11.10. The modified terms will become
          effective upon posting or, if we notify you by email, as stated in the
          email message. By continuing to use the Services or RXONE Content
          after the effective date of any modifications to this Agreement, you
          agree to be bound by the modified terms. It is your responsibility to
          check the RXONE Site regularly for modifications to this Agreement. We
          last modified this Agreement on the date listed at the beginning of
          this Agreement.
        </p>
      </p>
      <p>
        <h5 style={{ color: "blue" }}>11. Miscellaneous.</h5>
        <br />
        <p>
          <spna style={{ fontWeight: "bold" }}> 11.1 Assignment.</spna> You will
          not assign or otherwise transfer this Agreement or any of your rights
          and obligations under this Agreement, without our prior written
          consent. Any assignment or transfer in violation of this Section 11.1
          will be void. We may assign this Agreement without your consent (a) in
          connection with a merger, acquisition or sale of all or substantially
          all of our assets, or (b) to any affiliate or as part of a corporate
          reorganization; and effective upon such assignment, the assignee is
          deemed substituted for RXONE as a party to this Agreement and RXONE is
          fully released from all of its obligations and duties to perform under
          this Agreement. Subject to the foregoing, this Agreement will be
          binding upon, and inure to the benefit of the parties and their
          respective permitted successors and assigns.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.2 Entire Agreement.</span>{" "}
          This Agreement incorporates the Policies by reference and is the
          entire agreement between you and us regarding the subject matter of
          this Agreement. This Agreement supersedes all prior or contemporaneous
          representations, understandings, agreements, or communications between
          you and us, whether written or verbal, regarding the subject matter of
          this Agreement (but does not supersede prior commitments to purchase
          Services such as Amazon EC2 Reserved Instances). We will not be bound
          by, and specifically object to, any term, condition or other provision
          that is different from or in addition to the provisions of this
          Agreement (whether or not it would materially alter this Agreement)
          including for example, any term, condition or other provision (a)
          submitted by you in any order, receipt, acceptance, confirmation,
          correspondence or other document, (b) related to any online
          registration, response to any Request for Bid, Request for Proposal,
          Request for Information, or other questionnaire, or (c) related to any
          invoicing process that you submit or require us to complete. If the
          terms of this document are inconsistent with the terms contained in
          any Policy, the terms contained in this document will control, except
          that the Service Terms will control over this document.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.3 Force Majeure.</span> We and
          our affiliates will not be liable for any delay or failure to perform
          any obligation under this Agreement where the delay or failure results
          from any cause beyond our reasonable control, including acts of God,
          labor disputes or other industrial disturbances, electrical or power
          outages, utilities or other telecommunications failures, earthquake,
          storms or other elements of nature, blockages, embargoes, riots, acts
          or orders of government, acts of terrorism, or war.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.4 Governing Law.</span> The
          Governing LRXONE, without reference to conflict of law rules, govern
          this Agreement and any dispute of any sort that might arise between
          you and us. The United Nations Convention for the International Sale
          of Goods does not apply to this Agreement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.5 Disputes.</span> This
          Agreement shall be governed by and construed in accordance with the
          laws of India, to the exclusion of the other federal and state courts
          located in Gurugram, Haryana, India.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            11.6 Confidentiality and Publicity.
          </span>{" "}
          You may use RXONE Confidential Information only in connection with
          your use of the Services or RXONE Content as permitted under this
          Agreement. You will not disclose RXONE Confidential Information during
          the Term or at any time during the 5-year period following the end of
          the Term. You will take all reasonable measures to avoid disclosure,
          dissemination or unauthorized use of RXONE Confidential Information,
          including, at a minimum, those measures you take to protect your own
          confidential information of a similar nature. You will not issue any
          press release or make any other public communication with respect to
          this Agreement or your use of the Services or RXONE Content.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.7 Notice.</span>
          (a) To You. We may provide any notice to you under this Agreement by:
          (i) posting a notice on the RXONE Site; or (ii) sending a message to
          the email address then associated with your account. Notices we
          provide by posting on the RXONE Site will be effective upon posting
          and notices we provide by email will be effective when we send the
          email. It is your responsibility to keep your email address current.
          You will be deemed to have received any email sent to the email
          address then associated with your account when we send the email,
          whether or not you actually receive the email. (b) To Us. To give us
          notice under this Agreement, you must contact RXONE by facsimile
          transmission or personal delivery, overnight courier or registered or
          certified mail to the facsimile number or mailing address, as
          applicable, listed for the applicable RXONE Contracting Party in
          Section 12 below. We may update the facsimile number or address for
          notices to us by posting a notice on the RXONE Site. Notices provided
          by personal delivery will be effective immediately. Notices provided
          by facsimile transmission or overnight courier will be effective one
          business day after they are sent. Notices provided registered or
          certified mail will be effective three business days after they are
          sent.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            11.8 No Third-Party Beneficiaries.
          </span>{" "}
          Except as set forth in Section 7, this Agreement does not create any
          third-party beneficiary rights in any individual or entity that is not
          a party to this Agreement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>11.9 Severability.</span> If any
          portion of this Agreement is held to be invalid or unenforceable, the
          remaining portions of this Agreement will remain in full force and
          effect. Any invalid or unenforceable portions will be interpreted to
          effect and intent of the original portion. If such construction is not
          possible, the invalid or unenforceable portion will be severed from
          this Agreement but the rest of the Agreement will remain in full force
          and effe
        </p>
      </p>
    </div>
  );
};

export default TermsNconditions;
