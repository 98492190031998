import Toast from "../ui/Toast";
import React, { useEffect } from "react";
import { Modal, MenuItem, FormControl } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import ModeContext from "../../context/mode-context";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { useContext } from "react";
import { rxOpdApi } from "../../utils/api/api";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  Select,
  TableCell,
  TableContainer,
  Paper,
  tableCellClasses,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material";

const Cashpaymentlink = (props) => {
  const { amount, orderid, category, hos_id, appointmentid } = props;
  const [open, setOpen] = useState(true);
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const [errors, setErrors] = useState({
    paymentMethod: "",
    amountPaid: "",
    apiError: "",
  });
  const [selected, setSelected] = useState("CASH");
  const [showToast, setShowToast] = useState("false");
  const [toastType, setToastType] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [serviceData, setServiceData] = useState([]);
  const { mode } = useContext(ModeContext);
  const navigate = useNavigate();
  const [amountPaid, setAmountPaid] = useState(amount || "");
  const [note, setNote] = useState("");
  const [aredataLoading, setAredataLoading] = useState(false);

  // Open table modal automatically if category is APPOINTMENT
  useEffect(() => {
    if (category === "APPOINTMENT") {
      setTableModalOpen(true);
      setAmountPaid(amount);
    }
  }, [category, amount]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const validateInputs = () => {
    let isValid = true;
    const newErrors = {
      paymentMethod: "",
      amountPaid: "",
    };

    if (!selected) {
      newErrors.paymentMethod = "Please select a payment method";
      isValid = false;
    }

    if (!amountPaid) {
      newErrors.amountPaid = "Please enter the amount";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleClose = () => {
    if (validateInputs()) {
      if (category === "APPOINTMENT") {
        postData1();
      } else {
        postData();
      }
    }
  };

  const handlepostclose = () => {
    navigate("/app/billing/Orders");
  };

  const fecthorder = async () => {
    try {
      const orderdata = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.HOSPITAL.OPD.FETCH_ORDER + `/${hos_id}/${orderid}`
      );
      if (orderdata) {
        setServiceData(orderdata?.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (category !== "APPOINTMENT") {
      fecthorder();
    }
  }, [category]);

  const postData = async () => {
    setAredataLoading(true);
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.put(
        RX_OPD_ENDPOINTS.HOSPITAL.OPD.PAYMENT_ORDER + `/${hos_id}/${orderid}`,
        {
          payment_mode: `${selected}` || "CASH",
          currency: "INR",
          transaction_amount: `${amountPaid}`,
          transaction_notes: `${note}`,
        }
      );

      if (res) {
        setAredataLoading(false);

        setShowToast("true");
        setToastMessage(res.data.message);
        setToastType("success");
        setTableModalOpen(false);
        fecthorder();
      }
    } catch (error) {
      // Only show balance due error for non-appointment categories
      if (category !== "APPOINTMENT") {
        setErrors({
          ...errors,
          apiError:
            error.response?.data?.message ||
            "Amount can not be greater than balance due",
        });
      } else {
        setErrors({
          ...errors,
          apiError: error.response?.data?.message || "An error occurred",
        });
      }
    }
  };

  const postData1 = async () => {
    setAredataLoading(true);
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      const integerAmount = parseInt(amountPaid.toString().replace(".00", ""));
      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.post(
        RX_OPD_ENDPOINTS.HOSPITAL.OPD.CONFIRM_CASH_PAYMENT +
          `/${appointmentid}`,
        {
          payment_mode: `${selected}` || "CASH",
          currency: "INR",
          amount_paid: integerAmount,
          // transaction_notes: `${note}`,
        }
      );

      if (res) {
        setAredataLoading(false);
        setToastMessage(res.data.message);
        setToastType("success");
        setShowToast("true");
        setTableModalOpen(false);
        navigate("/app/billing/Orders");
      }
    } catch (error) {
      setErrors({
        ...errors,
        apiError: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
    setErrors({ ...errors, paymentMethod: "" });
  };

  const handleAmountChange = (e) => {
    setAmountPaid(e.target.value);
    setErrors({ ...errors, amountPaid: "", apiError: "" });
  };

  const rupee = "\u20B9";

  const handleTableModalClose = () => {
    setTableModalOpen(false);
    if (category === 'APPOINTMENT') {
      navigate("/app/billing/Orders");
    }
  };


  return (
    <>
      {category !== "APPOINTMENT" && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="table-modal-title"
          aria-describedby="table-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "566px",
              bgcolor: "background.paper",
              boxShadow: 24,
              background: "white",
              height: "488px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <button
                style={{
                  width: "50px",
                  backgroundColor: "white",
                  border: "none",
                  height: "23px",
                }}
                type="button"
                onClick={handlepostclose}
              >
                <span
                  aria-hidden="true"
                  style={{ color: "black", border: "none", fontSize: "40px" }}
                >
                  &times;
                </span>
              </button>
            </div>

            {serviceData?.show_payment_button && category !== "APPOINTMENT" && (
              <button
                style={{
                  width: "170px",
                  backgroundColor: "#009698",
                  color: "white",
                }}
                className="btn shadow m-4"
                onClick={() => setTableModalOpen(true)}
              >
                Payment+{" "}
              </button>
            )}

            <span
              style={{
                fontWeight: "bold",
                color:
                  parseFloat(serviceData?.balance_due) <= 0.001
                    ? "green"
                    : "red",
                float: "inline-end",
                marginTop: "3rem",
                marginRight: ".5rem",
              }}
            >
              Balance Due: {rupee}
              {serviceData?.balance_due}
            </span>

            <span
              style={{
                display: "flex",
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: "2rem",
                marginTop: "-1rem",
              }}
            >
              Click to start capturing payment.
            </span>

            <TableContainer component={Paper} style={{ padding: "13px" }}>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Transaction ID
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Currency
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Amount
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Mode
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Balance
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceData?.transcations &&
                    Array.isArray(serviceData?.transcations) &&
                    !!serviceData?.transcations?.length &&
                    serviceData?.transcations.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.transaction_number || ""}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ textAlign: "center" }}
                        >
                          {row?.date || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.currency || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.amount || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.mode || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.balance_due || ""}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal>
      )}

      {aredataLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Modal open={tableModalOpen} onClose={handleTableModalClose}>
        <Typography>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                marginTop: 200,
                marginLeft: 100,
                backgroundColor: "white",
                borderRadius: "2px",
                width: 400,
                height: "400px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button
                  style={{
                    width: "50px",
                    backgroundColor: "white",
                    border: "none",
                    height: "20px",
                  }}
                  type="button"
                  onClick={handleTableModalClose}
                >
                  <span
                    aria-hidden="true"
                    style={{ color: "black", border: "none", fontSize: "40px" }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <h5 style={{ padding: 7, margin: 10, textAlign: "center" }}>
                Confirm Cash Payment{" "}
              </h5>

              <div style={{ marginLeft: 100, width: 200 }}>
                <Select
                  displayEmpty
                  value={selected}
                  onChange={selectionChangeHandler}
                  fullWidth
                  error={!!errors.paymentMethod}
                >
                  <MenuItem value="">
                    <h6 style={{ fontSize: "80%", marginLeft: "20px" }}>
                      Select Payment Method
                    </h6>
                  </MenuItem>
                  <MenuItem style={{ marginLeft: 20 }} value={"UPI"}>
                    UPI
                  </MenuItem>
                  <MenuItem style={{ marginLeft: 20 }} value={"Credit Card"}>
                    Credit Card
                  </MenuItem>
                  <MenuItem style={{ marginLeft: 20 }} value={"Debit Card"}>
                    Debit Card
                  </MenuItem>
                  <MenuItem style={{ marginLeft: 20 }} value={"CASH"}>
                    CASH
                  </MenuItem>
                  <MenuItem style={{ marginLeft: 20 }} value={"WALLET"}>
                    WALLET
                  </MenuItem>
                </Select>
                {errors.paymentMethod && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.paymentMethod}
                  </div>
                )}
              </div>

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <span
                  className="mr-2 font-semibold"
                  style={{
                    marginLeft: "-2rem",
                    border: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    padding: "1rem 0.5rem",
                    marginRight: ".5rem",
                  }}
                >
                  INR
                </span>
                <input
                  id="amountPaid"
                  type="number"
                  value={amountPaid}
                  onChange={handleAmountChange}
                  placeholder="Enter amount paid"
                  required
                  className="mt-1"
                  style={{
                    border: `1px solid ${
                      errors.amountPaid || errors.apiError
                        ? "red"
                        : "rgb(231, 231, 231)"
                    }`,
                    borderRadius: "8px",
                    padding: "1rem 0.5rem",
                    appearance: "none",
                  }}
                />
                {(errors.amountPaid || errors.apiError) && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.amountPaid || errors.apiError}
                  </div>
                )}
              </div>
              {category !== "APPOINTMENT" && (
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <input
                    id="note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Add a note"
                    className="mt-1"
                    style={{
                      border: "1px solid rgb(231, 231, 231)",
                      borderRadius: "8px",
                      padding: "1rem 0.5rem",
                      appearance: "none",
                    }}
                  />
                </div>
              )}
              <button
                style={{
                  width: "120px",
                  marginLeft: "150px",
                  borderRadius: "5px",
                  marginTop: "50px",
                  backgroundColor: "#009698",
                  color: "white",
                  border: "1px solid white",
                }}
                onClick={handleClose}
              >
                Confirm{" "}
              </button>
            </FormControl>
          </div>
        </Typography>
      </Modal>

      {showToast && !open && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
          autohide={true}
          autohideDelay={3000}
        >
          {toastMessage}
        </Toast>
      )}
      {!showToast && toastType === "success" && handlepostclose()}
    </>
  );
};

export default Cashpaymentlink;
