import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

const TickerText = ({ text, handleRemove }) => {
  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "0.25rem",
        padding: "4px 2px",
        backgroundColor: "rgba(0, 0, 0, 0.07)",
        display: "inline-block",
        marginRight: "10px",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{ padding: "0px 10px 0px 10px", color: "rgba(0, 0, 0, 0.8)" }}
        >
          {text}
        </div>
        <IconButton size="small" onClick={handleRemove}>
          <ClearIcon fontSize="12px" />
        </IconButton>
      </div>
    </div>
  );
};

const TickerTextsBox = ({ ticker_texts, handleRemoveTickerText }) => {
  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "0.5rem",
        padding: "15px 7.5px",
      }}
    >
      {ticker_texts && Array.isArray(ticker_texts) && ticker_texts.length
        ? ticker_texts.map((text, index) => (
            <TickerText
              text={text}
              key={index}
              handleRemove={() => handleRemoveTickerText(index)}
            />
          ))
        : null}
    </div>
  );
};

export default TickerTextsBox;
