import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import Select from "react-select";
import { Spinner } from "react-bootstrap";

import ModeContext from "../../context/mode-context";
import FormControl from "../form/FormControl";
import RmpDataModal from "./RmpDataModal";
import Button from "../ui/Button";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import FieldInfo from "../form/FieldInfo";
import InputErrorMessage from "../kyc/InputErrorMessage";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const discountOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

function DoctorDetails(props) {
  const { isMainDataSaved, areDocsSubmitted, setDirty, fieldDisabled } = props;
  const formikProps = useFormikContext();
  // console.log(
  //   "🚀 ~ file: DoctorDetails.js:25 ~ DoctorDetails ~ formikProps:",
  //   formikProps
  // );
  const { mode } = useContext(ModeContext);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isRmpLoading, setIsRmpLoading] = useState(false);
  const [showRmpDataModal, setShowRmpDataModal] = useState(false);
  const [rmpDetails, setRmpDetails] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [showInMarketplace, setShowInMarketplace] = useState(true);
  const [showInMarketplaceFormik, setShowInMarketplaceFormik] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
  sessionStorage.setItem("selectedCountry", selectedCountryCode);
  // console.log(212333, selectedCountryCode)

  const handleRmpSearch = async () => {
    const firstName = formikProps.values.doctorDetails.firstName;
    const lastName = formikProps.values.doctorDetails.lastName;

    try {
      if (firstName === "" || lastName === "") {
        throw new Error(
          "Please enter First Name and Last Name for RMP Search."
        );
      }

      setIsRmpLoading(true);

      const userKeys = localStorage.getItem("usr_keys");

      if (userKeys) {
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.RMP_SEARCH + `/${firstName}/${lastName}`
        );

        if (res) {
          setRmpDetails(res.data["RMP Details"]);
          setShowRmpDataModal(true);
        } else {
          throw new Error("Something went wrong. Please try later!.");
        }
      } else {
        throw new Error("User not logged in. Please login to proceed.");
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setIsRmpLoading(false);
    }
  };
  /* gender */
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];
  /* handle country change */

  const [specialization, setSpecialization] = useState([]);
  const specializationObj = specialization?.map((state) => ({
    label: state,
    value: state,
  }));
  const handlespec = async () => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-opd-dev-rv573.ondigitalocean.app/v2/doctor/list/specializations`
      );
      setSpecialization(res.data.records);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  useEffect(() => {
    handlespec();
    setDirty(formikProps.dirty);
  }, [formikProps.dirty]);

  // this for title
  const titleOptions = [
    { label: "Dr.", value: "Dr." },
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
  ];

  // this code is for switch button for market place
  useEffect(() => {
    formikProps.setFieldValue(
      "doctorDetails.showinmarketplace",
      showInMarketplaceFormik
    );
    console.log("Both values are now the same:", showInMarketplaceFormik);
  }, [showInMarketplaceFormik]);

  const switchbutton = (event) => {
    setShowInMarketplaceFormik(event.target.checked);
    setShowInMarketplace(event.target.checked);
  };

  const handleCountryCodeChange = (newCode) => {
    setSelectedCountryCode(newCode);
  };

  return (
    <>
      <div className="row" style={{ position: "relative" }}>
        <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
          Title
        </label>

        <Select
          name="doctorDetails.title"
          className="col-12 col-md-8 px-0 h-50"
          options={titleOptions}
          isDisabled={isMainDataSaved}
          onChange={(selectedOption) => {
            formikProps.setFieldValue(
              "doctorDetails.title",
              selectedOption,
              true
            );
          }}
          styles={{
            control: (controlStyles) => ({
              ...controlStyles,
              padding: "0px",
              border: "2px solid #b3c6e7",
              borderRadius: "0",
            }),
          }}
        />
        <ErrorMessage
          component={InputErrorMessage}
          name="doctorDetails.title"
        />
      </div>

      <FormControl
        info="Enter Doctor's First Name"
        label="First Name"
        type="text"
        name="doctorDetails.firstName"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor's Last Name"
        label="Last Name"
        type="text"
        name="doctorDetails.lastName"
        disabled={isMainDataSaved}
      />
      <div className="row">
        <div className="col-12 col-md-4"></div>

        <Button
          disabled={isRmpLoading || isMainDataSaved}
          className="col-12 col-md-8 w-auto"
          onClick={handleRmpSearch}
        >
          {isRmpLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mx-3"
            />
          ) : (
            "RMP Search"
          )}
        </Button>
      </div>
      <div className="row" style={{ position: "relative" }}>
        <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
          Gender
        </label>

        <FieldInfo
          info="In case of Individual Doctor Clinic, provide the Accreditation Authority name which issued Doctor’s Registration"
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        />

        <Field name="doctorDetails.gender" className="col-12 col-md-8">
          {({ field, form }) => (
            <Select
              {...field}
              isDisabled={isMainDataSaved}
              name="doctorDetails.gender"
              className="col-12 col-md-8 px-0 h-50"
              onChange={(option) => {
                form.setFieldValue("doctorDetails.gender", option, true);
              }}
              options={genderOptions}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>
        <ErrorMessage
          component={InputErrorMessage}
          name="doctorDetails.gender"
        />
        <FieldInfo
          info="Country where Facility is located."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        />
      </div>
      <FormControl
        info="Enter Email id to be used for Doctor portal login"
        label="Email"
        type="email"
        name="doctorDetails.email"
        disabled={isMainDataSaved || fieldDisabled}
      />

      <FormControl
        info="Enter Phone No. to be used for Doctor portal login"
        label="Phone No."
        type="tel"
        name="doctorDetails.phoneNo"
        countryCode={selectedCountryCode}
        onCountryCodeChange={handleCountryCodeChange}  
        disabled={isMainDataSaved || fieldDisabled}
      />

      

      <div className="row" style={{ position: "relative" }}>
        <label
          className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center"
          style={{ textAlign: "center" }}
        >
          Primary Specialization
        </label>

        <FieldInfo
          info="Primary Specialization"
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        />

        <Field
          name="doctorDetails.primary_specialization"
          className="col-12 col-md-8"
        >
          {({ field, form }) => (
            <Select
              {...field}
              isDisabled={isMainDataSaved}
              name="doctorDetails.primary_specialization"
              className="col-12 col-md-8 px-0 h-50"
              // defaultInputValue={{ label: "male", value: "male" }}
              onChange={(option) => {
                form.setFieldValue(
                  "doctorDetails.primary_specialization",
                  option,
                  true
                );
              }}
              options={specializationObj}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>
        <ErrorMessage
          component={InputErrorMessage}
          name="doctorDetails.primary_specialization"
        />
        <FieldInfo
          info="Primary Specialization is required."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        />
      </div>
      <FormControl
        info="Enter Accreditation By"
        label="Accreditation By"
        type="text"
        name="doctorDetails.accreditationby"
        disabled={isMainDataSaved || fieldDisabled}
      />
      <FormControl
        info="Enter Registered Medical Professional (RMP) No."
        label="Registration No."
        type="text"
        name="doctorDetails.rmpNo"
        disabled={isMainDataSaved || fieldDisabled}
      />

      <FormControl
        info="Enter Registration Year"
        label="Reg. Year"
        type="text"
        name="doctorDetails.regYear"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor’s Birth Year"
        label="Birth Year"
        type="text"
        name="doctorDetails.birthYear"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor’s latest qualification/degree Year"
        label="Degree Year"
        type="text"
        name="doctorDetails.degreeYear"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor’s Qualifications. Separated by Comma “,”"
        label="Qualification"
        type="text"
        name="doctorDetails.qualification"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor’s Specializations. Separated by Comma “,”"
        label="Specializations"
        type="text"
        name="doctorDetails.speciality"
        disabled={isMainDataSaved}
      />

      <FormControl
        info="Enter Doctor’s Consultation Charges (Online or In-Person)"
        label="Consultation Fees (INR)"
        type="text"
        name="doctorDetails.consultCharges"
        disabled={isMainDataSaved}
      />

      <div
        className="my-1 row align-items-center"
        style={{ position: "relative" }}
      >
        <label className=" px-0 pe-md-3 col-11 col-md-4 d-flex text-end justify-content-start justify-content-md-end align-items-center">
          {/* {`${
            businessType === "individual"
              ? "Owner ID Card Type"
              : "Signatory ID Card"
          }`} */}
          Advance Booking Discount(Up to 20%)
        </label>

        <FieldInfo
          info="We recommend 5-20% Online Payment discount, to make paying in advance attractive."
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        />
        <Field name="doctorDetails.onlineDiscount" className="col-12 col-md-8">
          {({ field, form }) => (
            <Select
              {...field}
              isDisabled={isMainDataSaved}
              options={discountOptions.map((op) => ({
                label: op.toString(),
                value: op.toString(),
              }))}
              name="doctorDetails.onlineDiscount"
              className="col-12 col-md-8 px-0 h-50"
              onChange={(option) => {
                form.setFieldValue(
                  "doctorDetails.onlineDiscount",
                  option,
                  true
                );
              }}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                  padding: "0",
                  margin: "0",
                }),
                menuList: (menustyles) => ({
                  ...menustyles,
                  height: "100px",
                }),
              }}
            />
          )}
        </Field>

        <FieldInfo
          info="We recommend 5-20% Online Payment discount, to make paying in advance attractive."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        />
      </div>
      {/* this for switch  */}
      <div
        style={{ marginLeft: "50px" }}
        className=" px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center content-none"
      >
        <text style={{marginRight:"-10px"}}>Show in RxOne Marketplace</text>

        <FormControlLabel
          name="doctorDetails.showinmarketplace"
          labelPlacement="start"
          disabled={isMainDataSaved || fieldDisabled}
          control={
            <Switch
              defaultChecked={showInMarketplace}
              onChange={switchbutton}
            />
          }
        />
      </div>
      {showRmpDataModal && (
        <RmpDataModal
          rmpDetails={rmpDetails}
          show={showRmpDataModal}
          onHide={() => setShowRmpDataModal(false)}
        />
      )}

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
          autohide={true}
          autohideDelay={3000}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default DoctorDetails;
