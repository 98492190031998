import React, { useContext, useState, useEffect } from "react";
import { rxOpdApi } from "../utils/api/api";
import ModeContext from "./mode-context";

const StateListContext = React.createContext({
  stateList: [],
  fetchStates: () => {},
});

function StateListProvider(props) {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const fetchStates = async (country) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/states/${country}`
      );

      if (res) {
        setStateList(res.data.states);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchCity = async (country) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/cities/${country}`
      );

      if (res) {
        setCityList(res.data.cities);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  return (
    <StateListContext.Provider
      value={{
        stateList,
        fetchStates,
        fetchCity,
        cityList,
      }}
    >
      {props.children}
    </StateListContext.Provider>
  );
}

export default StateListContext;
export { StateListProvider };
