import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";

import HospitalsContext from "../../../context/hospitals-context";
import ModeContext from "../../../context/mode-context";
import AppointmentsFormContext from "../../../context/appointments-form";
import FormControl from "../../form/FormControl";
import InputErrorMessage from "../../kyc/InputErrorMessage";
import Button from "../../ui/Button";
import Toast from "../../ui/Toast";
import { rxOpdApi } from "../../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../../utils/api/apiEndPoints";
import { max } from "date-fns";
import StateListContext from "../../../context/cityNstate-context";
import Creatable from "react-select/creatable";

function calculateAge(birthDate) {
  if (!(birthDate instanceof Date) || isNaN(birthDate)) {
    return ""; // Return empty string for invalid date
  }

  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  if (
    today.getMonth() < birthDateObj.getMonth() ||
    (today.getMonth() === birthDateObj.getMonth() &&
      today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
}

function calculateBirthDate(age) {
  const today = new Date();
  const birthDate = new Date(
    today.getFullYear() - age,
    today.getMonth(),
    today.getDate()
  );
  return birthDate;
}

const profileTypes = [
  { label: "Self", value: "self" },
  { label: "Family", value: "family" },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

// const bloodGroupOptions=[
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
//   {label:"",value:""},
// ]

function PatientProfile(props) {
  const {
    addToExistingProfile,
    contactNo,
    onHide,
    setChangeStep,
    setJumpToStep,
    type,
  } = props;
  /* handle country change */
  const formikprops = useFormikContext();
  const countryOptions = [{ value: "IN", label: "India" }];
  const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]); // Default selected country is India
  const phonecode = sessionStorage.getItem("selectedCountryCod2");
  // Function to handle country selection change
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    fetchStates(selectedOption.value);
    fetchCity(selectedOption.value);
    formikprops.setFieldValue("patientProfile.country", selectedOption);
  };
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const fetchStates = async (country) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/states/${country}`
      );

      if (res) {
        setStateList(res.data.states);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchCity = async (country, state) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/cities/${country}${state}`
      );

      if (res) {
        setCityList(res.data.cities);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleState = (selectedCountry, selectedState) => {
    fetchCity(selectedCountry.value, selectedState);
  };

  const options = stateList.map((state) => ({ label: state, value: state }));
  const optionscity = cityList.map((city) => ({ label: city, value: city }));

  const { mode } = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const { setNewProfileAdded } = useContext(AppointmentsFormContext);

  const [isRegistering, setIsRegistering] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [profileCreated, setProfileCreated] = useState(false);

  useEffect(() => {
    if (!showToast && profileCreated) {
      onHide();
      setChangeStep(true);
      setJumpToStep(2);
    }
  }, [showToast, profileCreated, onHide]);
  //min date can be 120 year ago and max date is todays date
  const maxDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 120);
  const fields = [
    "profileType",
    "name",
    "gender",
    "email",
    "dob",
    "address",
    "city",
    "state",
    "country",
    "pinCode",
  ];
  let errors = [];

  const formikProps = useFormikContext();
  const checkValidation = async (field) => {
    errors = [];
    const error = await formikProps.setFieldTouched(`patientProfile.${field}`);

    if (Object.keys(error).length > 0) errors.push(error);
  };

  const handleRegister = async () => {
    setIsRegistering(true);
    // console.log("cvbnm");
    try {
      for (const field of fields) {
        await checkValidation(field);
      }

      // console.log("cvbnm", errors);
      if (errors.length > 0) return;

      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      // console.log("cvbnggggm");
      const values = formikProps.values.patientProfile;
      const birth_date =
        values.dob.getFullYear() +
        "-" +
        (+values.dob.getMonth() + 1) +
        "-" +
        values.dob.getDate();

      // console.log("cvbnmtttt");
      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.post(
        RX_OPD_ENDPOINTS.HOSPITAL.OPD.CREATE_PATIENT_PROFILE +
          "/" +
          currentHospital.hos_id +
          `/${phonecode}` +
          (type ? values.mobile : contactNo),
        {
          email: values.email,
          name: values.name,
          gender: values.gender.value,
          birth_date: birth_date,
          address: values.address ? values.address : "",
          city: values.city.value ? values.city.value : "",
          state: values.state.value ? values.state.value : "",
          pincode: values.pinCode ? values.pinCode : "",
          country: values.country.label ? values.country.label : "",
        }
      );

      if (res) {
        setShowToast(true);
        setToastType("success");
        setToastMessage(res.data.message);
        setProfileCreated(true);

        // if (addToExistingProfile) {
        setNewProfileAdded(true);
        // }
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setIsRegistering(false);
    }
  };

  // ***********************for age
  const handleDateOfBirthChange = (date) => {
    // Calculate age based on the selected date of birth
    const age = calculateAge(date);

    // Set the age value in the form
    formikProps.setFieldValue("age", age);

    // Set the date of birth value in the form
    formikProps.setFieldValue("patientProfile.dob", date);
  };

  // ... (existing code)

  const handleAgeChange = (age) => {
    // Calculate birth date based on age
    const birthDate = calculateBirthDate(age);

    // Set the birth date value in the form
    formikProps.setFieldValue("patientProfile.dob", birthDate);

    // Set the age value in the form
    formikProps.setFieldValue("age", age);
    // console.log(age)
  };

  return (
    <>
      <div className="my-1 row align-items-center w-100 mx-0">
        <div className="patient-profile-ele px-0 w-100">
          <label className="accreditationBodyLabel px-0 pe-md-3 col-11 col-md-12 w-100">
            {/* d-flex text-end justify-content-start justify-content-md-end align-items-center"> */}
            {/* {`${
            businessType === "individual"
              ? "Owner ID Card Type"
              : "Signatory ID Card"
          }`} */}
            Profile Type
          </label>

          {/* <input className="w-100" /> */}

          <Field name="patientProfile.profileType" className="col-12 col-md-12">
            {({ field, form }) => (
              <Select
                {...field}
                // isDisabled={isMainDataSaved || areDocsSubmitted}
                options={profileTypes}
                name="patientProfile.profileType"
                className="col-12 col-md-12 px-0"
                onChange={(option) => {
                  form.setFieldValue(
                    "patientProfile.profileType",
                    option,
                    true
                  );
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                    padding: "0",
                    margin: "0",
                  }),
                  // menuList: menustyles => ({
                  //   ...menustyles,
                  //   height: "100px",
                  // }),
                }}
              />
            )}
          </Field>
        </div>
      </div>

      <ErrorMessage
        component={InputErrorMessage}
        name="patientProfile.profileType"
      />

      <FormControl
        label="Name"
        type="text"
        name="patientProfile.name"
        labelColClass="col-12 justify-content-start mb-0"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />
      <FormControl
        label="Email"
        type="email"
        name="patientProfile.email"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        required={false}
      />
      {type ? (
        <FormControl
          label="Contact Number"
          type="mobile"
          name="patientProfile.mobile"
          labelColClass="col-12 justify-content-start mb-0"
          fieldColClass="col-12 w-100"
          className="mx-0"
          // disabled={isMainDataSaved || areDocsSubmitted}
        />
      ) : null}
      <div className="my-1 row align-items-center w-100 mx-0">
        <div className="px-0">
          <label className="accreditationBodyLabel px-0 pe-md-3 col-11 col-md-12 w-100">
            {/* d-flex text-end justify-content-start justify-content-md-end align-items-center"> */}
            {/* {`${
            businessType === "individual"
              ? "Owner ID Card Type"
              : "Signatory ID Card"
          }`} */}
            Gender
          </label>

          {/* <input className="w-100" /> */}

          <Field name="patientProfile.gender" className="col-12 col-md-12">
            {({ field, form }) => (
              <Select
                {...field}
                // isDisabled={isMainDataSaved || areDocsSubmitted}
                options={genderOptions}
                name="patientProfile.gender"
                className="col-12 col-md-12 px-0"
                onChange={(option) => {
                  form.setFieldValue("patientProfile.gender", option, true);
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                    padding: "0",
                    margin: "0",
                  }),
                  // menuList: menustyles => ({
                  //   ...menustyles,
                  //   height: "100px",
                  // }),
                }}
              />
            )}
          </Field>
        </div>
      </div>

      <ErrorMessage
        component={InputErrorMessage}
        name="patientProfile.gender"
      />

      {/* **************************************************************************************************** */}

      {/* <label className="ms--12 mt-1 mx-0">Date of Birth</label>

      <Field name="patientProfile.dob">
        {({ field, form }) => (
          <DatePicker
            {...field}
            id="dob"
            wrapperClassName="col-12 px-0 py-2 py-sm-0 mb-1 mx-0"
            className="mx-0"
            placeholderText="YYYY-MM-DD"
            selected={field.value}
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={minDate}
            maxDate={maxDate}
            onChange={(val) => form.setFieldValue("patientProfile.dob", val)}
          />
        )}
      </Field>

      <ErrorMessage component={InputErrorMessage} name="patientProfile.dob" /> */}

      <div className="d-flex ">
        <div>
          {/* Age */}
          <label className="ms--12 mt-1 mx-0">Age</label>
          <Field name="age" type="text">
            {({ field }) => (
              <input
                {...field}
                type="text"
                className="form-control"
                onChange={(e) => handleAgeChange(e.target.value)}
                onKeyDown={(e) => {
                  // Allow only numeric keys and certain control keys
                  const allowedKeys = [
                    "Backspace",
                    "Tab",
                    "Enter",
                    "Delete",
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                  ];
                  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter age"
              />
            )}
          </Field>
        </div>
        <span style={{ marginTop: "30px" }} className="mx-5">
          Or
        </span>
        <div>
          {/* // Date of Birth */}
          <label className="ms--12 mt-1 mx-0">Date of Birth</label>
          <Field name="patientProfile.dob">
            {({ field, form }) => (
              <DatePicker
                {...field}
                id="dob"
                name="patientProfile.dob"
                wrapperClassName="col-12 px-0 py-2 py-sm-0 mb-1 mx-0"
                className="mx-0"
                selected={form.values.patientProfile.dob}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={maxDate}
                minDate={minDate}
                onChange={(val) => handleDateOfBirthChange(val)}
                dateFormat="yyyy-MM-dd"
              />
            )}
          </Field>
        </div>
      </div>

      {/* 88************************************************************************************************* */}

      <FormControl
        label="Address"
        type="text"
        name="patientProfile.address"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />
      <span className="ms--12 mt-1 mx-0">Country</span>
      <Select
        name="patientProfile.country"
        defaultValue={selectedCountry[0]}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
      />
      <span className="ms--12 mt-1 mx-0">State</span>
      <Field name="patientProfile.state" className="col-12 col-md-12">
        {({ field, form }) => (
          <Creatable
            {...field}
            isClearable
            isSearchable
            options={options}
            name="patientProfile.state"
            className="col-12 col-md-12 px-0"
            onChange={(option) => {
              form.setFieldValue("patientProfile.state", option, true);
              handleState(selectedCountry, `?state=${option.value}`);
            }}
            styles={{
              control: (controlStyles) => ({
                ...controlStyles,
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                padding: "0",
                margin: "0",
              }),
            }}
          />
        )}
      </Field>
      <span className="ms--12 mt-1 mx-0">City</span>
      <Field name="patientProfile.city" className="col-12 col-md-12">
        {({ field, form }) => (
          <Creatable
            {...field}
            options={optionscity}
            name="patientProfile.city"
            className="col-12 col-md-12 px-0"
            onChange={(option) => {
              form.setFieldValue("patientProfile.city", option, true);
            }}
            styles={{
              control: (controlStyles) => ({
                ...controlStyles,
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                padding: "0",
                margin: "0",
              }),
            }}
          />
        )}
      </Field>

      <FormControl
        label="Pin Code"
        type="text"
        name="patientProfile.pinCode"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />

      <Button
        style={{ margin: "12px 0", width: "100%" }}
        onClick={handleRegister}
        disabled={isRegistering}
      >
        {isRegistering ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mx-3"
          />
        ) : (
          "Register"
        )}
      </Button>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default PatientProfile;
