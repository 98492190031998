

import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { DataGrid, Table,TableBody, TableCell, TableHead, TableRow  } from '@mui/x-data-grid';
import { useState,useEffect,useContext } from 'react';
import AuthContext from "../../context/auth-context";
import ModeContext from "../../context/mode-context";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { type } from '@testing-library/user-event/dist/type';
import SendPaymentLink from './SendPaymentLink';
import Creatable from 'react-select/creatable';
import HospitalsContext from '../../context/hospitals-context';


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  countryCode: Yup.string().required('Country Code is required'),
  mobile: Yup.string().required('Mobile Number is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  gst: Yup.string(),
  address: Yup.string().required('Address is required'),
});

const initialValues = {
  name: '',
  countryCode: '',
  mobile: '',
  email: '',
  gst: '',
  address: '',
};
const columns = [
    {
        field: "details",
        headerName: "ITEM DETAILS",width: 140
      },
      {
        field: "quantity",
        headerName: "QUANTITY",
      },
    { field: "rate", headerName: "RATE",  },
    {
        field: "discount",
        headerName: "DISCOUNT"
      },
      {
        field: "gst_percentage",
        headerName: "GST"
      },
      {
        field: "calc_amount",
        headerName: "AMOUNT",
       
     
      },
  ];

  function OrdersModal ({openOrderDetails}) {
    const [selectedMode, setSelectedMode] = useState(''); // State to track the selected payment mode

    const[modal, setModal] = useState(false)   
    const { mode } = useContext(ModeContext);

     const [data, setData] = useState([]);
    const [rowData,setrowData]=useState([]);
    const [OrderData,setOrderData]=useState([]);
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [nameValue, setNameValue] = useState('');
    const [codeValue, setcodeValue] = useState('');
    const [phoneValue, setphoneValue] = useState('');
    const [emailValue, setemailValue] = useState('');
    const [gstValue, setgstValue] = useState('');
    const [addValue, setaddValue] = useState('');
    const [selectedDocId, setSelectedDocId] = useState({
        label: "All Doctors",
        value: "all",
      });
      const [registeredDoctors, setRegisteredDoctors] = useState([]);
      console.log("🚀 ~ file: OrdersModal.js:82 ~ OrdersModal ~ registeredDoctors:", registeredDoctors)


    // this is for making button which is increasing a line in table 

const [isOpen, setIsOpen] = useState(false);
console.log("matajhbjhjh", data.gstin);
const toggleDropdown = () => {
  setIsOpen(!isOpen);
}
const chooseCust = (e) => {
    console.log("🚀 ~ file: OrdersModal.js:155 ~ chooseCust ~ e:", e)    
}
const {
    currentHospital,
  } = useContext(HospitalsContext);
useEffect(() => {
    
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys) {
      const fetchRegisteredDoctors = async () => {
        

        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.LIST_ALL_DOCTORS +
                "/" +
                currentHospital?.hos_id
          );

          if (res) {
            console.log("🚀 ~ file: OrdersModal.js:109 ~ fetchRegisteredDoctors ~ res:", res)
            setRegisteredDoctors(res)
          } else {
            throw new Error("Something went wrong. Please try again.");
          }
        } catch (error) {
          
        } finally {
          
        }
      };

      fetchRegisteredDoctors();
    }
  }, [mode, currentHospital]);
useEffect(() => {
    const OrderData = async () => {
        const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        console.log("tryg", rxOpdApi);
        rxOpdApi.setAuthHeaders(key, secret);
        const url =
          RX_OPD_ENDPOINTS.HOSPITAL.OPD.ORDERS_DETAILS +
          "/" +
          "f1fb3143-d0ec-4aaf-a7e6-f4ff3213" +
          "/" +
          "APPOINTMENT"
           +
          "/" +
          "6fb0d1d6-01f7-4e14-a14e-01bfe360j ";

        const res = await rxOpdApi.get(url);
        if (res) {
            // res.data.gstin=5
            // res.data.line_items[0].gst_percentage = 5
          setData(res.data)
      console.log("response",res)

        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {}

    };
    OrderData();
  }, [ ]);

    const columns = [
        {
            field: "details",
            headerName: "ITEM DETAILS",width: 140
          },
          {
            field: "quantity",
            headerName: "QUANTITY",
          },
        { field: "rate", headerName: "RATE",  },
        {
            field: "discount",
            headerName: "DISCOUNT"
          },
          {
            field: "gst_percentage",
            headerName: "GST"
          },
          {
            field: "calc_amount",
            headerName: "AMOUNT",
           
         
          },
      ];



const handleCloseButtonClick = (params) => {
    openOrderDetails(false)
  };

//   this code is for calculation part 
const dataa = {
    key1: 'value1',
    key2: 'value2',
    key3: 'value3',
  };
// this code is for payment options 

const handleModeChange = (event) => {
    (data.show_cash_payment_button?setSelectedMode(event.target.value):setSelectedMode(event.target.value))
    setSelectedMode(event.target.value);
  };
  console.log("modal",modal)
  console.log("nam dekho",nameValue)
  console.log("email  dekho",emailValue)

  console.log("phone dekho",phoneValue)

  console.log("code dekho",codeValue)

  return (
    <>
    <div style={{backgroundColor: "#E3E3E3"}}>
    <div className='d-flex justify-content-between'>
    <h4 style={{textAlign:'center'}}>Orders</h4>

    <button type="button" class="close"  onClick={handleCloseButtonClick}>
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log('Form values:', values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <form >
          <div className='p-2'>
          <div className="col col-12">
              <Creatable
                options={[ { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' }]}
                onChange={({ value, label }) => {
                    chooseCust(value)
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                  }),
                }}
              />
            </div>
            {touched.name && errors.name && <div className="error">{errors.name}</div>}
          </div>
            <div className='d-flex p-1'>
          <div className=' p-1'>
            <Field
              type="text"
              name="countryCode"
              onChange={(e) => {
                handleChange(e);
                setcodeValue(e.target.value); // Update the local state
              }}               
               onBlur={handleBlur}
              disabled={isEditModeOn?true:false}

              value={data.country_code||codeValue}
              placeholder="Enter your country code"
              style={{border: "3px solid #b3c6e7",
              borderRadius: "0",
              maxWidth:"50px"

            }}
            />
            {touched.countryCode && errors.countryCode && <div className="error">{errors.countryCode}</div>}
          </div>

          <div className=' p-1'>
            <Field
              type="text"
              name="mobile"
              disabled={isEditModeOn?true:false}
              onChange={(e) => {
                handleChange(e);
                setphoneValue(e.target.value); // Update the local state
              }}                
              onBlur={handleBlur}
              value={data.phone||phoneValue}
              placeholder="Enter your mobile number"
              style={{border: "3px solid #b3c6e7",
              borderRadius: "0",
              minWidth:"250px"

            }}
            />
            {touched.mobile && errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>

          <div className=' p-1'>
            <Field
              type="text"
              name="email"
              disabled={isEditModeOn?true:false}
              onChange={(e) => {
                handleChange(e);
                setemailValue(e.target.value); // Update the local state
              }}    
              onBlur={handleBlur}
              value={data.email||emailValue}
              placeholder="Enter your email"
              style={{border: "3px solid #b3c6e7",
              borderRadius: "0",
              minWidth:"285px"

            }}
            />
            {touched.email && errors.email && <div className="error">{errors.email}</div>}
          </div>
          </div>
          <div className='d-flex p-1'>
          <div className=' p-1'>
            <Field
              type="text"
              name="gst"
              disabled={isEditModeOn?true:false}
              onChange={(e) => {
                handleChange(e);
                setgstValue(e.target.value); // Update the local state
              }}    
              onBlur={handleBlur}
              value={data.gstin||gstValue}
              placeholder="customer GST (Optional)"
              style={{border: "3px solid #b3c6e7",
              borderRadius: "0",}}
            />
            {touched.gst && errors.gst && <div className="error">{errors.gst}</div>}
          </div>

          <div className=' p-1'>
            <Field
              type="text"
              name="address"
              disabled={isEditModeOn?true:false}
              onChange={(e) => {
                handleChange(e);
                setaddValue(e.target.value); // Update the local state
              }}
              onBlur={handleBlur}
              value={data.address||addValue}
              placeholder="Enter your address"
              style={{border: "3px solid #b3c6e7",
              borderRadius: "0",
              minWidth:"407px"

            }}
            />
            
            {touched.address && errors.address && <div className="error">{errors.address}</div>}
          </div>
          </div>
        </form>
      )}
    </Formik>
    <div>
        <h6 className='p-4  px-2'>Order Details</h6>
    </div>
{/* this code is to show order details in table  */}

    <div style={{ width: "100%" }}>
          <DataGrid
            // rows={rows}
            rows={data.line_items||[]}
            getRowId={(row) => (data.line_items||[]).indexOf(row)}
            // getRowId={(row) => row.order_id}
            columns={columns}
          />
        </div>  


{/* this code is for button to add another line in a table  and calculation area      */}
<div className="container-fluid">
  <div className="row">
    <div className="col-12 col-md-6">
      <button className="btn btn-primary">Add another line</button>
    </div>
    <div className="col-md-6 d-none mt-5 d-md-block" style={{maxWidth:"300px"}}>
      <div className="d-flex justify-content-between" >
        <div>
            <h6>Sub total</h6>
            <h6>Tax (GST) </h6>
            <h5>Total(INR)</h5>
        </div>
        <div>   
        <h6>₹{data.sub_total}</h6>        
        <h6>₹{data.total_tax?data.total_tax:"0%"}</h6>
        <h5>₹{data.total}</h5>

        </div>
      </div>
        
    </div>
    {/* this is for payment options  */}
    <div style={{ display: 'flex', alignItems: 'center' ,margin:'20px'}}>
      <div>
        <h4 style={{ marginRight: '10px' }}>Select Payment Mode:</h4>
      </div>
      {data.type==="ONLINE" &&

      <div>
        <input
          type="radio"
          id="onlinePayment"
          name="paymentMode"
          value="Online"
          checked={data.type==="ONLINE"?true:false}
          onChange={handleModeChange}
          

        
        />
        <label htmlFor="onlinePayment" style={{ marginRight: '10px' }}>Online  </label>
      </div>}
      {data.type==="CASH" &&

      <div>
        <input
          type="radio"
          id="cashPayment"
          name="paymentMode"
          value="Cash"
          checked={data.type==="CASH"?true:false}
          onChange={handleModeChange}
           
        />
        
        <label htmlFor="cashPayment">Cash </label>
      </div>

            }
    </div>
    {/* this is for buttons  */}
    <div  className="d-flex justify-content-center ">
    {data.type==="CASH" ?  <button className='btn btn-primary shadow m-4'>Cash Payment </button>: 
    <button  className='btn btn-primary shadow m-4' onClick={()=>{ setModal(true)}
      
    } >Send Payment Link</button>}
      
            <button  className='btn btn-danger shadow m-4'>Cancel Order</button>
           </div>
  </div>
</div>
</div>
{modal && <SendPaymentLink customerName = {data.customer_name} email={data.email} mobile={data.phone}/>}
        
    </>
  );
};

export default OrdersModal;
