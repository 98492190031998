import { useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Label } from "../../common";
import Acceptpayementswitch from "../../Acceptpayemntswitch";

import doctorIcon from "../../../../assets/images/icons/doctor.svg";

const HospitalCard = ({ hospital, handleUpdateFacility }) => {
  const navigate = useNavigate();

  const handleregistration = (hospitalId) => {
    navigate("/app/facilities/register?edit=true&hosp_id=" + hospitalId, {
      replace: true,
    });
  };

  const handleEdit = (hospitalId) => {
    handleUpdateFacility(hospitalId);
  };

  const handleDoctors = (hospitalId) => {
    navigate("/app/doctors/doctors");
  };

  return (
    <div
      class="row shadow p-2 justify-content-center "
      style={{
        borderRadius: "20px",
        margin: "10px",
        minHeight: "150px",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div class="col-sm-12 col-md">
        <img
          src={hospital.hos_logo_url + "?v=" + Math.random() * Math.random()}
          alt={hospital.hosp_name}
          className="rounded-circle border border-5 border-primary"
          style={{
            maxHeight: "100px",
            minHeight: "100px",
            minWidth: "100px",
            maxWidth: "100px",
          }}
        />
      </div>
      <div class="col-sm-12 col-md-3 ">
        <h5 class="card-title text-primary">{hospital.hosp_name}</h5>
        <div>(Reg No:{hospital.hosp_registration_no})</div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="card-title d-flex text-wrap fs-6 ">
          <LocationOnOutlinedIcon style={{ color: "red" }} />
          <div
            style={{
              textAlign: "center",
              // alignItems: "center",
              width: "100%",
            }}
          >
            {hospital.address}
            {hospital.country ? `, ${hospital.country}` : null}
          </div>
        </div>
        <div class="card-title d-flex text-wrap fs-6 ">
          <EmailIcon style={{ color: "grey" }} />
          <a
            href={`mailto:${hospital?.email}`}
            style={{
              textDecoration: "none",
              color: "black",
              overflow: "hidden",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {hospital?.email}
          </a>
        </div>
        <p class="card-text d-flex">
          <CallIcon style={{ color: "grey" }} />
          <a
            href={`tel:${hospital?.phone1}`}
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {hospital?.phone1}
          </a>
        </p>
      </div>
      
      <div class="col-sm-12 col-md-1 d-flex flex-column justify-content-center">
              <div style={{ color: "gray", fontWeight: "bold", marginTop: "10px",}}>
              Allow Payement
            </div>
            <Acceptpayementswitch
              hospitalId={hospital?.hos_id}
              available={hospital.accept_online_payment}
            />

</div>
      <div class="col-sm-12 col-md d-flex justify-content-center">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleregistration(hospital.hos_id)}
        >
          {hospital.registration_approved === false ? (
            <>
              <Label style={{ fontSize: "0.85rem", color: "#ff8000" }}>
                Continue Registration
              </Label>
            </>
          ) : (
            <Label style={{ fontSize: "0.85rem", color: "#2eb82e" }}>
              Registration Approved
            </Label>
          )}
        </div>
      </div>
      <div class="col-sm-12 col-md">
        {hospital.doctors_registered &&
        parseInt(hospital.doctors_registered) ? (
          <div
            className="d-flex justify-content-center"
            style={{ cursor: "pointer", color: "#2eb82e" }}
            onClick={handleDoctors}
          >
            <img
              src={doctorIcon}
              className="rounded me-2"
              alt="doctor icon"
              style={{ width: "24px", height: "24px" }}
            />
            <div>{hospital.doctors_registered}</div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            onClick={handleDoctors}
          >
            <img
              src={doctorIcon}
              className="rounded me-2"
              alt="doctor icon"
              style={{ width: "24px", height: "24px", color: "#2eb82e" }}
            />
            <div>0</div>
          </div>
        )}
      </div>
      <div class="col-sm-12 col-md">
        <div>
          <BorderColorIcon
            onClick={() => handleEdit(hospital.hos_id)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default HospitalCard;
