import React, { useContext, useState } from "react";
import { Label } from "../../common";
import HospitalCard from "./HospitalCard";
import UpdateModal from "../../UpdateModal";
import ModeContext from "../../../../context/mode-context";

const Hospitals = ({ hospitals, name, updateFacilitiesData }) => {
  const { mode } = useContext(ModeContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateHospitalId, setUpdateHospitalId] = useState(null);

  const handleUpdateFacility = async (hospitalId) => {
    setShowUpdateModal(true);
    setUpdateHospitalId(hospitalId);
  };

  return (
    <>
      <div>
        <Label fontWeight="bold" className="p-3 pb-0" variant="h5">
          {name}
        </Label>
        {hospitals.map((hospital, index) => (
          <HospitalCard
            key={index}
            hospital={hospital}
            handleUpdateFacility={handleUpdateFacility}
          />
        ))}
      </div>
      {showUpdateModal && (
        <UpdateModal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          hospitalId={updateHospitalId}
          mode={mode}
          updateFacilitiesData={updateFacilitiesData}
        />
      )}
    </>
  );
};

export default Hospitals;
