import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import PatientForm from "../opd/appointments-form/PatientForm";
import EditPatientprofile from "../opd/appointments-form/EditPatientprofile";
import ModeContext from "../../context/mode-context";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import HouseIcon from "@mui/icons-material/House";
import ArticleIcon from "@mui/icons-material/Article";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { rxOneApi, rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import Toast from "../ui/Toast";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { Buffer } from "buffer";
import Rxlogo from "../../assets/images/static/rxlogo.svg";
import RxlogoRed from "../../assets/images/static/rxlogo-red.jpg";
import Spinner from "react-bootstrap/Spinner";

function EditPatientModal({
  show,
  onHide,
  appointmentList,
  selectedProfileData,
  editid,
  prescriptionList,
  selectedPatient,
  setDateRange,
  dateRange,
  onClose,
  loading,
}) {
  const [currentActive, setCurrentActive] = useState(1);
  const [createPatientForm, setCreatePatientForm] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedPres, setSelectedpres] = useState(null);
  const [showPres, setShowPres] = useState(false);
  const { currentHospital } = useContext(HospitalsContext);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [pdfUrl, setPdfurl] = useState();
  const [presPdfUrl, setPresPdfUrl] = useState();
 
  const tabs = [
    { id: 1, name: "Update Details" },
    { id: 2, name: "Appointments" },
    // { id: 3, name: "Case Files" },
    // { id: 4, name: "ABHA" },
  ];
  const { mode } = useContext(ModeContext);
  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];
  /* fetch file of doctor appoinment records */
  const handleDownloadDoctorPrescrition = async (selectedPatient) => {
    const url =
      process.env.REACT_APP_RX_OPD +
      RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.DOCTOR_PRESCRIPTION +
      "/" +
      currentHospital?.hos_id +
      "/" +
      selectedPatient;

    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/pdf",
        Authorization:
          "Basic " + Buffer.from(key + ":" + secret).toString("base64"),
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let blob_url = URL.createObjectURL(blob);
        setPresPdfUrl(blob_url);
      });
  };
  /* fetch file of user medical records */
  const handleDownloadMedical = async (fileName) => {
    setSelectedpres(fileName);
    const url =
    process.env.REACT_APP_RX_OPD +
      RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.DOWNLOAD_MEDICAL_RECORDS +
      "/" +
      currentHospital?.hos_id +
      "/" +
      selectedPatient +
      "/" +
      fileName;

    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/pdf",
        Authorization:
          "Basic " + Buffer.from(key + ":" + secret).toString("base64"),
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let blob_url = URL.createObjectURL(blob);
        setPdfurl(blob_url);
      });
  };
  /* Patient notes */

  const [appointmentData, setAppointmentData] = useState({});
  const keyMapping = {
    patient_name: "Patient Name",
    patient_age: "Patient Age",
    patient_gender: "Patient Gender",
    doc_name: "Doctor's Name",
    reported_problems: "Reported Problems",
    diagnosis: "Diagnosis",
    recommended_tests: "Recommended Tests",
    recommended_medications: "Recommended Medications",
    specific_clinical_findings: "Specific Clinical Findings",
    provisional_diagnosis: "Provisional Diagnosis",
    investigation_reports: "Investigation Reports",
    prescription_notes: "Prescription Notes",
  };
  const newDataObject = {};
  for (const key in appointmentData) {
    if (appointmentData.hasOwnProperty(key)) {
      const displayKey = keyMapping[key] || key;
      newDataObject[displayKey] = appointmentData[key];
    }
  }
  const handleAppointmentClick = async (appointment) => {
    setSelectedAppointment(appointment);
    if (userKeys) {
      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.PATIENT_APPOINTMENT_NOTES +
            "/" +
            currentHospital?.hos_id +
            "/" +
            appointment
        );

        if (res) {
          setAppointmentData(res.data);
        } else {
          throw new Error("Something went wrong. Please try again.");
        }
      } catch (error) {
        setShowToast(error);
        setToastType("error");
        setToastMessage(error?.error?.message || error?.message);
      } finally {
        console.log();
      }
    }
  };

  const handleBackButtonClick = () => {
    if (showPres) {
      setShowPres(false);
      URL.revokeObjectURL(presPdfUrl);
      setPresPdfUrl("");
    } else {
      setSelectedAppointment(null);
    }
  };
  const handleBackPresClick = () => {
    setSelectedpres(null);
    URL.revokeObjectURL(pdfUrl);
    setPdfurl("");
  };
  /* Filter by date  */
  const handleDateChange = (date, key) => {
    setDateRange((prevRange) => ({
      ...prevRange,
      [key]: date,
    }));
  };
  return (
    <div>
      <Modal centered show={show} onHide={onHide} size="lg">
        <Modal.Header
          className="p-2 text-white border-0"
          closeButton
          onClick={() => {
            onClose();
          }}
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>
            {selectedAppointment ? (
              <>
              
                <span
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    alignContent: "center",
                    width: "100%",  
                    textTransform:"uppercase"
                  }}
                >
                  {appointmentData?.Name} ({appointmentData?.Gender}
                  {appointmentData?.Age})
                </span>
              </>
            ) : (
              <>
              
              {loading ===2 ? (<>
                <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
        </>) :(
              <>
              <span  style={{textTransform:"uppercase"}}>
                {selectedProfileData?.name} ({selectedProfileData?.gender},
                {selectedProfileData?.age})
                </span>
              </>
              )}
              </>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="m-0 p-0"
          
        >
          <div className="px-0 m-0 w-100 container-fluid">
            <Row className="mx-0">
              <Col xs={12} md={2} className="p-0  d-flex flex-md-column">
                {tabs.map((item) => (
                  <div
                    key={item.id}
                    className="p-3 m-0 update-user-modal text-center text-md-start"
                    style={{
                      backgroundColor:
                        currentActive === item.id ? "#D3D3D3" : "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentActive(item.id)}
                  >
                    {item.name}
                  </div>
                ))}
              </Col>

              <Col>
                {loading !== 0 ? (
                  <>
                    <Spinner style={{position: "fixed",top: "50%",left: "50%",}}animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </>
                ) : (
                  <div style={{ minHeight: "500px", maxHeight: "500px", overflowY: "auto" }}>
                    {currentActive === 1 ? (
                      <>
                        <EditPatientprofile
                          addToExistingProfile={true}
                          show={createPatientForm}
                          onHide={() => setCreatePatientForm(false)}
                          type="patient"
                          data={selectedProfileData}
                          editid={editid}
                          heading={false}
                        />
                      </>
                    ) : currentActive === 2 ? (
                      <>
                        {selectedAppointment ? (
                          <div className="mt-2" style={{ height: "240%"}}>
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                type="button"
                                class="btn btn-link"
                                onClick={() => {
                                  handleDownloadDoctorPrescrition(
                                    selectedAppointment
                                  );
                                  setShowPres(true);
                                }}
                              >
                                Prescription
                              </button>
                              <button
                                type="button"
                                className="btn btn-light mb-2"
                                onClick={handleBackButtonClick}
                              >
                                {"<"} Back
                              </button>
                            </div>
                            {showPres ? (
                              <div style={{ height: "500px" }}>
                                <object
                                  data={presPdfUrl}
                                  type="application/pdf"
                                  style={{ height: "500px", width: "100%" }}
                                >
                                  {" "}
                                  An error occurred while fetching the file, please try again later
                                </object>
                              </div>
                            ) : (
                              <>
                                {Object.entries(newDataObject).map(
                                  ([key, value]) => (
                                    <>
                                      {value &&
                                        key !== "Name" &&
                                        key !== "Age" &&
                                        key !== "Gender" &&
                                        key !== "Doctor" && (
                                          <React.Fragment key={key}>
                                            <div className="card mb-3">
                                              <div className="card-header">
                                                {key}
                                              </div>
                                              <div className="card-body">
                                                <p className="card-text">{value}</p>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <div
                                  className="row justify-content-md-center"
                                  style={{ width: "100%" }}
                                >
                                  <div className="col">
                                    <DatePicker
                                      label="From"
                                      value={dateRange.from}
                                      onChange={(date) =>
                                        handleDateChange(date, "from")
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ width: "150px" }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col">
                                    <DatePicker
                                      label="To"
                                      value={dateRange.to}
                                      onChange={(date) =>
                                        handleDateChange(date, "to")
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ width: "150px", height: "20px" }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </DemoContainer>
                            </LocalizationProvider>
                            {appointmentList?.length > 0 ? (
                              appointmentList.map((appointment) => (
                                <div
                                  className="row shadow p-2 justify-content-center "
                                  style={{
                                    borderRadius: "20px",
                                    margin: "8px",
                                    minHeight: "100px",
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="col-sm-12 col-md-5">
                                    <p> {appointment.appointment_date}</p>
                                    <p>{appointment.time_alloted}</p>
                                  </div>

                                  <div className="col-sm-12 col-md-2 ">
                                    {appointment.is_inperson ? (
                                      <HouseIcon />
                                    ) : (
                                      <VideoCameraFrontIcon />
                                    )}

                                    <p>{appointment.appointment_type}</p>
                                  </div>
                                  <div className="col-sm-12 col-md-4">
                                    <div className="card-title">
                                      {appointment.doc_name}
                                    </div>

                                    <p>
                                      {appointment.prescription_submitted ? (<>
                                        <img src={Rxlogo} alt="RxOne Logo" height="30px" width="30px"/>
                                        <span style={{color:"green"}}>Completed</span>
                                        </>
                                      ) : (<>
                                        <img src={RxlogoRed} alt="RxOne Logo" height="30px" width="30px" /><span style={{color:"#FB5432"}}>Pending</span></>
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-1">
                                    {appointment.prescription_submitted ? (
                                      <ArticleIcon
                                        sx={{ fontSize: "40px", cursor: "pointer" }}
                                        onClick={() =>
                                          handleAppointmentClick(
                                            appointment.appointment_id
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No Data Found</p>
                            )}
                          </div>
                        )}
                      </>
                    ) : currentActive === 3 ? (
                      <>
                        {selectedPres ? (
                          <div style={{ minHeight: "400px" }}>
                            <div className="d-flex justify-content-end align-items-center">
                              <button
                                type="button"
                                className="btn btn-light mb-2"
                                onClick={handleBackPresClick}
                              >
                                {"<"} Back
                              </button>
                            </div>
                            <div style={{ height: "500px", width: "100%" }}>
                              <object
                                data={pdfUrl}
                                type="application/pdf"
                                style={{ height: "500px", width: "100%" }}
                              >
                                An error occurred while fetching the file, please try again later
                              </object>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {prescriptionList?.length > 0 ? (
                              prescriptionList.map((appointment) => {
                                const [date, time] =
                                  appointment.uploaded_on.split(" ");
                                return (
                                  <>
                                    <div
                                      className="row shadow p-2 justify-content-center "
                                      style={{
                                        borderRadius: "20px",
                                        margin: "8px",
                                        minHeight: "100px",
                                        textAlign: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="col-sm-12 col-md-4">
                                        <p>{date}</p>
                                        <p>{time}</p>
                                      </div>

                                      <div className="col-sm-12 col-md-6 ">
                                        <h6>{appointment.category}</h6>
                                        {appointment.file_name}
                                      </div>

                                      <div className="col-sm-12 col-md-2">
                                        <ArticleIcon
                                          sx={{
                                            fontSize: "40px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleDownloadMedical(
                                              appointment.file_name
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <p>No Data Found</p>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </div>
  );
}

export default EditPatientModal;
