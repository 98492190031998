import React from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { IconButton } from "@mui/material";

const QRModal = ({ show = false, onHide, facilityQRCodeSvg, qrLink = "" }) => {
  const handleQrPrint = () => {
    const qrPrintTitleDiv = document.getElementById("qr-print-title");
    const qrPrintCodeDiv = document.getElementById("qr-print-code");

    qrPrintTitleDiv.innerHTML = `<h1 class="display-4 text-center text-capitalize mt-1">Care Portal QR</h1>`;
    qrPrintCodeDiv.innerHTML = facilityQRCodeSvg;

    window.print();

    qrPrintCodeDiv.innerHTML = "";
  };

  const handleQrShare = () => {
    navigator.share({
      title: "Care Portal QR",
      url: qrLink,
    });
  };

  return (
    <>
      <Modal centered size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>QR Links</Modal.Title>
        </Modal.Header>

        <Modal.Body className="w-100 text-center p-0">
          <Container
            className="p-0"
            // style={{ display: isLoading ? "none" : "block" }}
          >
            <Row className="justify-content-center">
              <Col xs={12} md={5}>
                <h1 className="h3 m-0 text-capitalize">Care Portal</h1>

                <a href={qrLink}>{qrLink}</a>
                <Image
                  className="m-0 p-0"
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    facilityQRCodeSvg
                  )}`}
                  alt="Facility QR"
                  style={{ height: "70%" }}
                />
                <div id="qr-col"></div>

                <div className="d-flex justify-content-evenly align-items-center w-50 mx-auto">
                  <IconButton onClick={handleQrPrint}>
                    <PrintOutlinedIcon style={{ fontSize: "4rem" }} />
                  </IconButton>

                  <IconButton onClick={handleQrShare}>
                    <ShareOutlinedIcon style={{ fontSize: "3rem" }} />
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="invoice-print">
        <div className="d-flex justify-content-center w-100 m-0 p-0">
          <Image
            className="m-0 p-0"
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              facilityQRCodeSvg
            )}`}
            alt="Facility QR"
            style={{ height: "80px" }}
          />
        </div>

        <div id="qr-print-title"></div>

        <div id="qr-print-code"></div>
      </div>
    </>
  );
};

export default QRModal;
