import React from 'react';
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

function DataTable(props) {
  const { bodyRows, noDataMessage, areRowsClickable, isLoading } = props;

  // Generate table headers dynamically from the first item in bodyRows
  const generateHeaders = () => {
    if (bodyRows && bodyRows.length > 0) {
      return Object.keys(bodyRows[0]).filter(key => key !== "key" && key !== "handleRowClick");
    }
    return [];
  };

  const headers = generateHeaders();

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        ) : bodyRows && bodyRows.length > 0 ? (
          bodyRows.map((row, rowIndex) => (
            <tr
              key={row.key || rowIndex}
              onClick={areRowsClickable && row.handleRowClick ? () => row.handleRowClick(row) : undefined}
              style={areRowsClickable ? { cursor: 'pointer' } : {}}
            >
              {headers.map((header, cellIndex) => (
                <td key={cellIndex}>{row[header]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={headers.length} className="text-center">
              {noDataMessage || "No data found!"}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default DataTable;