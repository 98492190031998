import { ErrorMessage, Field, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { Container, Row, Spinner } from "react-bootstrap";
import ScheduleModalLabel from "./ScheduleModalLabel";
import ScheduleModalInput from "./ScheduleModalInput";
import InputErrorMessage from "../kyc/InputErrorMessage";
import Button from "../ui/Button";
import FieldInfo from "../form/FieldInfo";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import {
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { Switch, TextField } from "@mui/material";
import dayjs from "dayjs";

const weekdaysCheckboxInputs = [
  { label: "Monday", value: 0 },
  { label: "Tuesday", value: 1 },
  { label: "Wednesday", value: 2 },
  { label: "Thursday", value: 3 },
  { label: "Friday", value: 4 },
  { label: "Saturday", value: 5 },
  { label: "Sunday", value: 6 },
];

function DoctorSchedule(props) {
  const { type, isItDaySche, setSelectedDate, selectDate } = props;
  const formikprops = useFormikContext();
  const [isAvailable, setIsAvailable] = useState(true);
  const [checked, setChecked] = useState({
    morning: false,
    afternoon: false,
    evening: false,
  });

  const timeobj = {
    morning: {
      title: "Morning",
      icon: <WbTwilightIcon style={{ height: "30px", width: "30px" }} />,
      time: [{ start_time: "" }, { end_time: "" }],
      minmax: [0, 12],
    },
    afternoon: {
      title: "Afternoon",
      icon: <WbSunnyIcon style={{ height: "30px", width: "30px" }} />,
      time: [{ start_time: "" }, { end_time: "" }],
      minmax: [12, 17], // Changed the end time to 17 (5:00 PM)
    },
    evening: {
      title: "Evening",
      icon: <SolarPowerIcon style={{ height: "30px", width: "30px" }} />,
      time: [{ start_time: "" }, { end_time: "" }],
      minmax: [17, 23, 59], // Changed to include minutes
    },
  };
  useEffect(() => {
    const initialValues = formikprops.initialValues[type];
    setIsAvailable(initialValues.is_available);
    setChecked({
      morning:
        initialValues?.morning_start_time ||
        initialValues?.morning_end_time ||
        false,
      afternoon:
        initialValues?.afternoon_start_time ||
        initialValues?.afternoon_end_time ||
        false,
      evening:
        initialValues?.evening_start_time ||
        initialValues?.evening_end_time ||
        false,
    });
  }, [formikprops.initialValues, type]);

  const handleTimeSlotChange = (timeSlot) => (event) => {
    const { checked } = event.target;
    setChecked((prevState) => ({
      ...prevState,
      [timeSlot]: checked,
    }));
    const timeSlotObj = timeobj[timeSlot];
    timeSlotObj.time.forEach((index) => {
      formikprops.setFieldValue(
        `${type}.${timeSlot}_${index === 0 ? "start" : "end"}_time`,
        checked ? undefined : ""
      );
    });
  };

  const handleTimeChange = (time, key) => {
    const hours = new Date(time).getHours().toString().padStart(2, "0");
    const minutes = new Date(time).getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    formikprops.setFieldValue(`${type}.${key}`, formattedTime);
  };

  return (
    <Container>
      {!isItDaySche && (
        <Row className="py-2" style={{ position: "relative" }}>
          <ScheduleModalLabel>Weekdays:</ScheduleModalLabel>

          <FieldInfo
            info="Select days of the week for which you would like to create schedule"
            classes="d-inline d-md-none align-items-center m-0 p-0 me-3 ms-md-4 w-auto"
            styles={{
              position: "absolute",
              right: "-35px",
              width: "auto",
              // backgroundColor: "#d0deef",
            }}
          />

          <div className="col-12 col-sm-7 px-0">
            <Field name={`${type}.weekdays`}>
              {({ field, meta }) => {
                return (
                  <ScheduleModalInput
                    type="checkbox"
                    name={`${type}.weekdays`}
                    field={field}
                    meta={meta}
                    inputs={weekdaysCheckboxInputs}
                  />
                );
              }}
            </Field>

            <ErrorMessage
              component={InputErrorMessage}
              name={`${type}.weekdays`}
            />
          </div>

          <FieldInfo
            info="Select days of the week for which you would like to create schedule"
            classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
            styles={{ position: "absolute", right: "-30px", width: "auto" }}
          />
        </Row>
      )}

      {isItDaySche && (
        <>
          <Row className="py-2">
            <ScheduleModalLabel>Select Date:</ScheduleModalLabel>

            <Field name="update.schedule_date">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  id="schedule_date"
                  wrapperClassName="col-12 col-sm-7 px-0 px-sm-2 py-2 py-sm-0"
                  placeholderText="Select Date"
                  dateFormat="yyyy-MM-dd"
                  selected={selectDate}
                  onChange={(date) => {
                    form.setFieldValue("update.schedule_date", date);
                    setSelectedDate(date);
                  }}
                />
              )}
            </Field>

            <ErrorMessage
              component={InputErrorMessage}
              name="update.schedule_date"
            />

            <FieldInfo
              classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
              // style={{ position: "absolute", right: "-35px", width: "auto" }}
            />
          </Row>

          <Row>
            <ScheduleModalLabel>Is Available:</ScheduleModalLabel>

            <div className="col-12 col-sm-7 px-0 m-auto">
              <Field name={`${type}.is_available`}>
                {({ field, form }) => {
                  return (
                    <Switch
                      id="is_available"
                      checked={isAvailable}
                      onChange={() => {
                        const newIsAvailable = !isAvailable;
                        setIsAvailable(newIsAvailable);
                        formikprops.setFieldValue(
                          `${type}.is_available`,
                          newIsAvailable
                        );
                      }}
                    />
                  );
                }}
              </Field>

              <ErrorMessage
                component={InputErrorMessage}
                name="update.declareOff"
              />
            </div>

            <FieldInfo
              classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
              // style={{ position: "absolute", right: "-35px", width: "auto" }}
            />
          </Row>
        </>
      )}

      <Row className=" py-2" style={{ position: "relative" }}>
        <label className="col-6 col-sm-5 d-flex align-items-center justify-content-start justify-content-sm-end  fw-bold py-1 schedule-modal-odd-row">
          Slot Duration (in Mins):
        </label>

        <div className="col-6 col-sm-7 px-2 d-flex align-items-center">
          <Field
            as="select"
            name={`${type}.slot_duration`}
            className="w-auto border-1 px-1 border-0"
          >
            <option value="10">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </Field>

          {/* d-none d-md-flex */}
          <FieldInfo
            info="Select Slot duration (Consultation time)"
            classes="d-flex align-items-center m-0 p-0 ms-3 ms-md-4 h-75"
            // style={{ position: "absolute", right: "-35px", width: "auto" }}
          />
        </div>

        <ErrorMessage
          component={InputErrorMessage}
          name={`${type}.slot_duration`}
        />
      </Row>

      <Row>
        <div
          className="col-12 col-sm-5 align-items-center justify-content-start justify-content-sm-end fw-bold schedule-modal-odd-row"
          style={{ alignSelf: "flex-start" }}
        >
          <label>Schedule:</label>
        </div>
        <div className="col-sm-14 col-md-10 col-lg-12 col-xl-7">
        {Object.entries(timeobj).map(([key, item]) => (
            <>
              <div>
                <div>
                  <div className="mobileTimerPicker__label d-flex flex-row-reverse">
                    {key}{" "}
                  </div>
                </div>
                <div
                  key={key}
                  className="d-flex border bw-5 justify-content-between"
                  style={{
                    boxShadow: "10px",
                    borderRadius: "20px",
                    marginBottom: "20px",
                    paddingBottom: "15px",
                    paddingInlineEnd: "10px",
                  }}
                  // label={title}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center col-2">
                    <Switch
                      color="warning"
                      size="small"
                      onChange={handleTimeSlotChange(key)}
                      checked={checked[key]}
                    />
                    {item.icon}
                  </div>

                  {/* <div className="controls bootstrap-timepicker d-flex "> */}

                  {item.time.map((timeItem, index) => (
                    <div
                      className="d-flex justify-content-between col-4"
                      key={index}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <DemoItem>
                            <div>
                              <Field
                                key={`${type}.${key}_${
                                  index === 0 ? "start" : "end"
                                }_time`}
                                name={`${type}.${key}_${
                                  index === 0 ? "start" : "end"
                                }_time`}
                              >
                                {({ field }) => {
                                  const [hour, minute] = field.value
                                    ? field.value.split(":")
                                    : [null, null];

                                  const defaultTime =
                                    hour !== null && minute !== null
                                      ? dayjs()
                                          .set("hour", parseInt(hour, 10))
                                          .set("minute", parseInt(minute, 10))
                                      : null;
                                  const fieldName = `${type}.${key}_${
                                    index === 0 ? "start" : "end"
                                  }_time`;
                                  return (
                                    <TimePicker
                                      field={fieldName}
                                      id={fieldName}
                                      name={`${key}_${
                                        index === 0 ? "start" : "end"
                                      }_time`}
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                      }}
                                      value={defaultTime}
                                      minTime={dayjs()
                                        .set("hour", item.minmax[0])
                                        .set("minute", 0)}
                                      maxTime={dayjs()
                                        .set("hour", item.minmax[1])
                                        .set("minute", 0)} // Changed to 0 minutes
                                      disabled={!checked[key]}
                                      slotProps={{
                                        textField: { size: "small" },
                                      }}
                                      onChange={(time) => {
                                        handleTimeChange(
                                          time,
                                          `${key}_${
                                            index === 0 ? "start" : "end"
                                          }_time`
                                        );
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                            </div>
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ))}
        </div>

        <ErrorMessage
          component={InputErrorMessage}
          name={`${type}.startTime`}
        />
      </Row>

      {!isItDaySche && (
        <Row
          className="py-2 align-items-md-center"
          style={{ position: "relative" }}
        >
          <ScheduleModalLabel>Schedule Till (End Date):</ScheduleModalLabel>

          <FieldInfo
            info="Input the date for till when you would like to create the recurring schedule"
            classes="d-inline d-md-none align-items-center m-0 p-0 me-3 ms-md-4 w-auto"
            styles={{
              position: "absolute",
              right: "-35px",
              width: "auto",
            }}
          />

          <Field name={`${type}.schedule_end_date`}>
            {({ field, form }) => {
              return (
                <DatePicker
                  {...field}
                  id="schedule_end_date"
                  wrapperClassName="col-12 col-sm-6 px-0 px-sm-2 py-2 py-sm-0"
                  placeholderText="Schedule End Date"
                  selected={field.value}
                  onChange={(val) =>
                    form.setFieldValue(`${type}.schedule_end_date`, val)
                  }
                />
              );
            }}
          </Field>

          <FieldInfo
            info="Input the date for till when you would like to create the recurring schedule"
            classes="d-none d-md-flex align-items-center m-0 p-0 ms-0 ms-lg-4 h-75 w-auto"
          />

          <ErrorMessage
            component={InputErrorMessage}
            name={`${type}.schedule_end_date`}
          />
        </Row>
      )}
    </Container>
  );
}

export default DoctorSchedule;
