import { useRef, useState, useEffect, useContext } from "react";
import FormControl from "../form/FormControl";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { Label } from "./common";
import styles from "./web-app/WebAppCurrent/WebAppCurrent.module.css";
import RemoveIcon from "@mui/icons-material/Remove";
import "../facilities/RegistrationDocs.css";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import ModeContext from "../../context/mode-context";
import { upload } from "@testing-library/user-event/dist/upload";
import Toast from "../ui/Toast";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../components/ui/Button";
import Form from "react-bootstrap/Form";
import {
  useLocation
} from "react-router-dom";

function RegistrationDocs() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hospitalid = params.get("hosp_id");
  const facilityImageRef = useRef(null);
  const logoImageRef = useRef(null);
  const { mode } = useContext(ModeContext);
  const [showModal, setShowModal] = useState(false);
  const [registrationImage, setRegistrationImage] = useState([]);
  const [docImg, setDocImg] = useState("");

  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);

  const [facImages, setFacImages] = useState([]);
  const [facilityImageDeleteName, setFacilityImageDeleteName] = useState("");
  const [showFacilityImageDeleteModal, setShowFacilityImageDeleteModal] =
    useState(false);
  const [isFacilityImageBeingSaved, setIsFacilityImageBeingSaved] =
    useState(false);

  const [imgStyles, setImgStyles] = useState({
    maxWidth: "78px",
    maxHeight: "150px",
    marginTop: "-60px",
    float: "right",
    marginRight: "190px",
  });
  const [previewImageSrc, setPreviewImageSrc] = useState('');
  const[previewImg, setPreviewImg] = useState('');


  const handleImgClick = () => {
    if (logoImageRef?.current?.click) {
      logoImageRef.current.click();
    }
  };

  // useEffect(() => {
  const gettingimg = async () => {
    try {
      let getImg = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.HOSPITAL.LIST_FACILITY_IMAGES_STEP4 +
          "/" +
          hospitalid
      );
      if (getImg && getImg.data && Array.isArray(getImg.data.records)) {
        setFacImages(getImg.data.records);
      }
    } catch (err) {
      console.error("Error fetching images:", err);
    }
  };


  useEffect(() => {
    gettingimg(hospitalid);

  }, []);
  

  const handleFacilityImgClick = () => {
    if (facilityImageRef.current) {
      facilityImageRef.current.click();
    }
  };

  const handleFacilityImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("pic", file);

        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();

        await rxOpdApi.post(
          RX_OPD_ENDPOINTS.HOSPITAL.UPLOAD_FACILITY_PIC_STEP4 +
            "/" +
            hospitalid,
          formData
        );

        // Call gettingimg after the POST request
        await gettingimg(hospitalid);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleShowFacilityImageDeleteModal = async (name) => {
    setFacilityImageDeleteName(name);
    setShowFacilityImageDeleteModal(true);
  };

  const handleFacilityImageDelete = async () => {
    setIsFacilityImageBeingSaved(true);
    await deletepic(facilityImageDeleteName);
    handleCloseFacilityImageDeleteModal();
  };

  const deletepic = async (facilityImageDeleteName) => {
    const userKeys = localStorage.getItem("usr_keys");
    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);

        await rxOpdApi.delete(
          RX_OPD_ENDPOINTS.HOSPITAL.REMOVE_FACILITY_PIC_STEP4 +
            "/" +
            hospitalid +
            "/" +
            facilityImageDeleteName
        );

        // Call gettingimg after the DELETE request
        await gettingimg(hospitalid);
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };

  const handleCloseFacilityImageDeleteModal = () => {
    setFacilityImageDeleteName("");
    setShowFacilityImageDeleteModal(false);
    setIsFacilityImageBeingSaved(false);
  };

  const handleregis = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("registration_certificate", file);

        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();
        await rxOpdApi.put(
          RX_OPD_ENDPOINTS.HOSPITAL.UPLOAD_REGISTRATION_CERTIFICATE_STEP4 +
            "/" +
            hospitalid,
          formData
        );
        const previewImage = document.getElementById("previewImage");
        setPreviewImageSrc(URL.createObjectURL(file));
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  };

  const Handleuploaddoc = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("facility_logo", file);

        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();
        await rxOpdApi.put(
          RX_OPD_ENDPOINTS.HOSPITAL.UPLOAD_BRAND_LOGO_STEP4 +
            "/" +
            hospitalid,
          formData
        );
        const previewImage = document.getElementById("previewImages");
        setPreviewImg(URL.createObjectURL(file));
      } catch (err) {}
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 964px)").matches) {
        // Apply different styles for smaller screens
        setImgStyles({
          maxWidth: "81px",
          maxHeight: "100px",
          marginTop: "-55px",
          float: "right",
          marginRight: "49px",
        });
      } else {
        // Apply default styles for larger screens
        setImgStyles({
          maxWidth: "81px",
          maxHeight: "150px",
          marginTop: "-60px",
          float: "right",
          marginRight: "105px",
        });
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <label style={{float: "inline-start", marginLeft: "5px", marginTop: "70px", marginBottom: "-20px" }}>Registration Certificate</label>
     <Form.Group className="mb-3" style={{ width: "50%" }}>
  <Form.Control
    placeholder="Registration Certificate"
    type="file"
    // name="uploadDocs.registrationCertificate"
    onChange={handleregis}
    style={{ marginLeft: "180px", marginTop: 0 }} // Set marginTop to 0 for the Form.Control
  />

  
</Form.Group>

        <Form.Label style={{float: "inline-start", marginLeft: "65px",marginTop: "10px", marginBottom: "-20px"}}>Hospital Logo</Form.Label>
      <Form.Group className="mb-3" style={{ width: "50%" }}>
        <Form.Control
          placeholder="Hospital Logo"
          type="file"
          // name="uploadDocs.hospitalLogo"
          onChange={Handleuploaddoc}
          style={{marginLeft: "180px", marginTop: 0 }}

        />
        <div style={{ textAlign: "center" }}>
        {previewImg ? (

          <img
            id="previewImages"
            src={previewImg}
            alt="Selected Logo"
            style={{
              maxWidth: "54px",
              maxHeight: "45px", // Set max height same as width to ensure a circle
              borderRadius: "50%", // Apply border-radius to make it a circle
              marginTop: "-40px",
              float: "right",
              marginRight: "-120px",
            }}
          />
          ) : (
            <p></p>
            )}
        
        </div>
      </Form.Group>

      <div style={{ marginTop: "40px", marginLeft: "179px" }}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={0.5}>
            <Grid item lg={12} md={12} xs={12}>
              <Label fontWeight="bold">
                Upload at least 4 Facility Photos (to be used for facility
                profile)
              </Label>
            </Grid>
            <Grid item lg={10} md={10} xs={10}>
              <div
                className={styles.facilityImagesWrapper}
                // style={{
                //   width: document.documentElement.clientWidth || "100%",
                // }}
              >
                {facImages && Array.isArray(facImages) && facImages.length ? (
                  <div className={styles.facilityImages}>
                    {facImages
                      .slice(0, facImages.length - 1)
                      .sort(function (a, b) {
                        return a.pic_name
                          .toLowerCase()
                          .localeCompare(b.pic_name.toLowerCase());
                      })
                      .map((image, index) =>
                        image.url ? (
                          <span
                            className={styles.facilityImageContainer}
                            key={index}
                          >
                            <img
                              src={
                                image.url +
                                "?v=" +
                                Math.random() * Math.random()
                              }
                              alt="Facility images"
                              className={`border border-4 border-primary ${styles.facilityImage}`}
                              style={{
                                width: "90px",
                                height: "90px",
                                borderRadius: "0.5rem",
                                marginBottom: "1px",
                                marginTop: "10px",
                              }}
                              key={index}
                            />
                            <div className={styles.removeFacilityImage}>
                              <IconButton
                                onClick={() =>
                                  handleShowFacilityImageDeleteModal(
                                    image.pic_name
                                  )
                                }
                              >
                                <RemoveIcon
                                  fontSize="large"
                                  sx={{ color: "red" }}
                                />
                              </IconButton>
                            </div>
                          </span>
                        ) : image.isLoading ? (
                          <div
                            style={{
                              minWidth: "90px",
                              height: "90px",
                              border: "1px solid red",
                              borderRadius: "0.5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "15px",
                              marginBottom: "1px",
                              marginTop: "10px",
                            }}
                            onClick={handleImgClick}
                          >
                            <CircularProgress
                              size="20px"
                              sx={{ color: "#00b0f0" }}
                            />
                          </div>
                        ) : null
                      )}
                    {facImages[facImages.length - 1]?.url ? (
                      <img
                        src={
                          facImages[facImages.length - 1].url +
                          "?v=" +
                          Math.random() * Math.random()
                        }
                        alt="Facility images"
                        className="border border-4 border-primary"
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "0.5rem",
                          marginRight: "15px",
                          marginBottom: "1px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handleFacilityImgClick}
                      />
                    ) : null}
                    <input
                      type="file"
                      onChange={handleFacilityImageUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={facilityImageRef}
                      // name="uploadDocs.pic" // Set the name attribute to "uploadDocs"

                    />
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* </Form> */}
      <Modal
        show={showFacilityImageDeleteModal}
        size="md"
        aria-labelledby="custom-modal"
        centered
        contentClassName="border-0"
        onHide={handleCloseFacilityImageDeleteModal}
      >
        <Modal.Header
          closeButton
          className="border-0 fs-5 text-white"
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-3">
          Do you want to delete this image?
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFacilityImageBeingSaved}
            onClick={handleFacilityImageDelete}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            {isFacilityImageBeingSaved ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mx-3"
              />
            ) : (
              "Yes"
            )}
          </Button>
          <Button
            disabled={isFacilityImageBeingSaved}
            onClick={handleCloseFacilityImageDeleteModal}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default RegistrationDocs;
