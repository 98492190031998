import { Box, Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { InputSelectElement } from "../common";
import { Label } from "../../common";

const OfferedServices = ({
  currentValues,
  fetchFacilityDoctors,
  fetchFacilityPharmacies,
  fetchFacilityPathLabs,
  handleOnChange,
  errorDetails,
  touched,
  handleBlur,
}) => {
  const [consultationDropdownData, setConsultationDropdownData] = useState([]);
  const [selectedConsultationOptions, setSelectedConsultationOptions] =
    useState([]);
  const [isConsultationLoading, setIsConsultationLoading] = useState(false);

  const [pharmacyDropdownData, setPharmacyDropdownData] = useState([]);
  const [selectedPharmacyOptions, setSelectedPharmacyOptions] = useState([]);
  const [isPharmacyLoading, setIsPharmacyLoading] = useState(false);

  const [labDropdownData, setLabDropdownData] = useState([]);
  const [selectedLabOptions, setSelectedLabOptions] = useState([]);
  const [isLabLoading, setIsLabLoading] = useState(false);

  useEffect(() => {
    if (
      (currentValues.enable_consultation || currentValues.doc_id_list.length) &&
      !consultationDropdownData.length
    ) {
      const fetchData = async () => {
        setIsConsultationLoading(true);
        const data = await fetchFacilityDoctors();

        const listedDocsMapping = {};
        for (const doc of currentValues?.doc_id_list) {
          listedDocsMapping[doc] = doc;
        }

        const tempSelectedOptions = [];
        for (const d of data) {
          if (d.value === listedDocsMapping[d.value]) {
            tempSelectedOptions.push(d);
          }
        }
        setSelectedConsultationOptions(tempSelectedOptions);
        setConsultationDropdownData(data);

        setIsConsultationLoading(false);
      };
      fetchData();
    }

    if (
      (currentValues.enable_pharmacy || currentValues.ph_id_list.length) &&
      !pharmacyDropdownData.length
    ) {
      const fetchData = async () => {
        setIsPharmacyLoading(true);
        const data = await fetchFacilityPharmacies();

        const listedDocsMapping = {};
        for (const doc of currentValues?.ph_id_list) {
          listedDocsMapping[doc] = doc;
        }

        const tempSelectedOptions = [];
        for (const d of data) {
          if (d.value === listedDocsMapping[d.value]) {
            tempSelectedOptions.push(d);
          }
        }
        setSelectedPharmacyOptions(tempSelectedOptions);
        setPharmacyDropdownData(data);

        setIsPharmacyLoading(false);
      };
      fetchData();
    }

    if (
      (currentValues.enable_lab || currentValues.lab_id_list.length) &&
      !labDropdownData.length
    ) {
      const fetchData = async () => {
        setIsLabLoading(true);
        const data = await fetchFacilityPathLabs();

        const listedDocsMapping = {};
        for (const doc of currentValues?.lab_id_list) {
          listedDocsMapping[doc] = doc;
        }

        const tempSelectedOptions = [];
        for (const d of data) {
          if (d.value === listedDocsMapping[d.value]) {
            tempSelectedOptions.push(d);
          }
        }
        setSelectedLabOptions(tempSelectedOptions);
        setLabDropdownData(data);

        setIsLabLoading(false);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const handleToggle = (name) => {
    const e = {
      target: {
        name,
        value: !currentValues[name],
      },
    };
    handleOnChange(e);
  };

  const handleOnSelectedChange = (options, name, setSelectedOptions) => {
    if (Array.isArray(options)) {
      const dropdownVal = options.map((opt) => opt.value);
      const e = {
        target: {
          name,
          value: dropdownVal,
        },
      };
      handleOnChange(e);
      setSelectedOptions(options);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.5}
        >
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={currentValues.enable_consultation}
                onChange={() => handleToggle("enable_consultation")}
              />
              <Label variant="subtitle2">CONSULTATION</Label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputSelectElement
              disabled={!currentValues.enable_consultation}
              loading={isConsultationLoading}
              value={selectedConsultationOptions}
              name="doc_id_list"
              onChange={(options) =>
                handleOnSelectedChange(
                  options,
                  "doc_id_list",
                  setSelectedConsultationOptions
                )
              }
              options={consultationDropdownData}
              onBlur={handleBlur}
              error={touched.doc_id_list ? errorDetails.doc_id_list : false}
              label="Doctors"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0.5}
        >
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={currentValues.enable_pharmacy}
                onChange={() => handleToggle("enable_pharmacy")}
              />
              <Label variant="subtitle2">PHARMACY</Label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputSelectElement
              disabled={!currentValues.enable_pharmacy}
              loading={isPharmacyLoading}
              value={selectedPharmacyOptions}
              name="ph_id_list"
              onChange={(options) =>
                handleOnSelectedChange(
                  options,
                  "ph_id_list",
                  setSelectedPharmacyOptions
                )
              }
              options={pharmacyDropdownData}
              onBlur={handleBlur}
              error={touched.ph_id_list ? errorDetails.ph_id_list : false}
              label="Pharmacies"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0.5}
        >
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={currentValues.enable_lab}
                onChange={() => handleToggle("enable_lab")}
              />
              <Label variant="subtitle2">PATH LABS</Label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputSelectElement
              disabled={!currentValues.enable_lab}
              loading={isLabLoading}
              value={selectedLabOptions}
              name="lab_id_list"
              onChange={(options) =>
                handleOnSelectedChange(
                  options,
                  "lab_id_list",
                  setSelectedLabOptions
                )
              }
              options={labDropdownData}
              onBlur={handleBlur}
              error={touched.lab_id_list ? errorDetails.lab_id_list : false}
              label="Path Labs"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferedServices;
