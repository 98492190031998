import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import AuthContext from "../../../context/auth-context";
import ModeContext from "../../../context/mode-context";
import HospitalContext from "../../../context/hospitals-context";
import DashboardCard from "../../../components/home/DashboardCard";
import SelectHospital from "../../../components/hospitals/SelectHospital";
import Filters from "../../../components/home/Filters";
import Visualizations from "../../../components/home/Visualizations";
import Notification from "../../../components/home/notification/Notification";
import NotificationModal from "../../../components/home/notification/NotificationModal";
import Toast from "../../../components/ui/Toast";
import { rxOneApi, rxOpdApi } from "../../../utils/api/api";
import { RX_ONE_ENDPOINTS, RX_OPD_ENDPOINTS } from "../../../utils/api/apiEndPoints";

import referralImg from "../../../assets/images/static/referral.jpg";

const startDateVal = new Date();
const endDateVal = new Date();

startDateVal.setDate(startDateVal.getDate() - 3);

const getDateString = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

function TrendsTab(props) {
  const { logout } = useContext(AuthContext);
  const { mode } = useContext(ModeContext);
  const { isLoading, hospitals, currentHospital } = useContext(HospitalContext);

  const [startDate, setStartDate] = useState(startDateVal);
  const [endDate, setEndDate] = useState(endDateVal);
  const [collectionChartData, setCollectionChartData] = useState([]);
  const [todayOnlineCollectionData, setTodayOnlineCollectionData] = useState(0);
  const [todayCashCollectionData, setTodayCashCollectionData] = useState(0);
  const [appointmentChartData, setAppointmentChartData] = useState([]);
  const [todayOnlineAppointments, setTodayOnlineAppointments] = useState(0);
  const [todayInPersonAppointments, setTodayInPersonAppointments] = useState(0);
  const [isHomeDataLoading, setIsHomeDataLoading] = useState(true);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModalData, setNotificationModalData] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);

  const navigate = useNavigate();

  


  useEffect(() => {
    if (shouldLogout && !showToast) {
      logout();
    }
  }, [shouldLogout, showToast]);

  useEffect(() => {
    const fetchHomeData = async () => {
      setIsHomeDataLoading(true);

      if (!isLoading && hospitals.length === 0) {
        setNoDataMessage(
          "No facilities found. Please create a facility first."
        );
        setIsHomeDataLoading(false);
        return;
      }

      if (isLoading || !currentHospital) {
        return;
      }

      setIsHomeDataLoading(true);

      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const collectionRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_REPORT_COLLECTION +
            "/" +
            currentHospital.hos_id +
            "/" +
            getDateString(startDate) +
            "/" +
            getDateString(endDate)
        );

        const todayCollectionRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_REPORT_COLLECTION +
            "/" +
            currentHospital.hos_id +
            "/" +
            getDateString(new Date()) +
            "/" +
            getDateString(new Date())
        );

        const appointmentRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_REPORT_APPOINTMENTS +
            "/" +
            currentHospital.hos_id +
            "/" +
            getDateString(startDate) +
            "/" +
            getDateString(endDate)
        );

        const todayAppointmentRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_REPORT_APPOINTMENTS +
            "/" +
            currentHospital.hos_id +
            "/" +
            getDateString(new Date()) +
            "/" +
            getDateString(new Date())
        );

        if (todayCollectionRes.data.appointments_collection.length > 0) {
          let todayOnlineCollection = 0;
          let todayCashCollection = 0;
          for (const collection of todayCollectionRes.data
            .appointments_collection) {
            if (collection.payment_mode === "Online") {
              todayOnlineCollection += +collection.collection_amount;
            } else {
              todayCashCollection += +collection.collection_amount;
            }
          }

          setTodayOnlineCollectionData(todayOnlineCollection);
          setTodayCashCollectionData(todayCashCollection);
        } else {
          setTodayOnlineCollectionData(0);
          setTodayCashCollectionData(0);
        }
        // todayAppointmentRes.data.appointments_count

        if (todayAppointmentRes.data.appointments_count.length > 0) {
          let todayOnlineAppointmentsData = 0;
          let todayInPersonAppointmentsData = 0;

          for (const appointment of todayAppointmentRes.data
            .appointments_count) {
            if (appointment.appointment_type === "Online") {
              todayOnlineAppointmentsData += +appointment.total_appointments;
            } else {
              todayInPersonAppointmentsData += +appointment.total_appointments;
            }
          }

          setTodayOnlineAppointments(todayOnlineAppointmentsData);
          setTodayInPersonAppointments(todayInPersonAppointmentsData);
        } else {
          setTodayOnlineAppointments(0);
          setTodayInPersonAppointments(0);
        }

        if (
          collectionRes.data.appointments_collection.length > 0 ||
          appointmentRes.data.appointments_count.length > 0
        ) {
          setCollectionChartData(collectionRes.data.appointments_collection);
          setAppointmentChartData(appointmentRes.data.appointments_count);
          setNoDataMessage(null);
        } else {
          setNoDataMessage(
            "No data to display for current date range and hospital."
          );
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(
          error.message || "Invalid session. Please login again."
        );
        setNoDataMessage(error.message);
        setIsHomeDataLoading(false);
      } finally {
        setIsHomeDataLoading(false);
      }
    };

    fetchHomeData();
  }, [isLoading, mode, currentHospital, startDate, endDate, hospitals.length]);


  

  return (
    <>
      

      <div className="shadow mt-3 mx-4 p-3">
        {/* <Col xs={12} md={6} className="my-3 px-2"> */}
        <Row>
          <Col>
        <SelectHospital type="home"  className=""/>
        </Col>
        <Col>
        <Filters
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              </Col>
              </Row>
        {/* </Col> */}
      </div>

      {!isHomeDataLoading ? (
        <>
          <Container as="main" className="w-100">
            <Row className="my-3 justify-content-between">
              <Col as="section" xs={12} md={6}>
                <DashboardCard
                  title="Total Appointments Today:"
                  onlineValue={todayOnlineAppointments}
                  rightLabel="(In-Person)"
                  rightValue={todayInPersonAppointments}
                />
              </Col>

              <Col as="section" xs={12} md={6} className="">
                <DashboardCard
                  title="Total Collection Today:"
                  onlineValue={`₹${todayOnlineCollectionData}`}
                  rightLabel="(Cash)"
                  rightValue={`₹${todayCashCollectionData}`}
                />
              </Col>
            </Row>
            
            <Row className="shadow p-2  align-self-center h-100">
              
              {!noDataMessage ? (
                <Visualizations
                  collectionChartData={collectionChartData}
                  appointmentChartData={appointmentChartData}
                />
              ) : (
                <p className="h-100 d-flex align-items-center justify-content-center fs-2 fw-bold py-5 my-5">
                  {noDataMessage}
                </p>
              )}
            </Row>
          </Container>
        </>
      ) : (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner
            as="span"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}

      {showNotificationModal && (
        <NotificationModal
          show={showNotificationModal}
          onHide={() => setShowNotificationModal(false)}
          type={notificationModalData.type}
          noteId={notificationModalData.noteId}
          title={notificationModalData.title}
        />
      )}
     
            
    </>
  );
}

export default TrendsTab;
