import BorderColorIcon from "@mui/icons-material/BorderColor";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { Label } from "../../common";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons";

const PathologyLab = ({ lab }) => {
  return (
    <div
      class="row shadow p-2 justify-content-center"
      style={{
        borderRadius: "20px",
        margin: "10px",
        minHeight: "150px",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div class="col-sm-12 col-md">
        {lab.lab_logo_url ? (
          <img
            src={lab.lab_logo_url + "?v=" + Math.random() * Math.random()}
            alt={lab.lab_name}
            className="rounded-circle border border-5 border-primary"
            style={{
              maxHeight: "100px",
              minHeight: "100px",
              minWidth: "100px",
              maxWidth: "100px",
            }}
          />
        ) : (
          <div
            className="rounded-circle border border-5 border-primary"
            style={{
              maxHeight: "100px",
              minHeight: "100px",
              minWidth: "100px",
              maxWidth: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(to right bottom,#4048bf,#409fbf)",
            }}
          >
            <Label style={{ color: "white" }}>
              {(() => {
                const splitedName = lab.lab_name.split(" ");
                if (splitedName.length > 1) {
                  return (
                    splitedName[0][0] + splitedName[splitedName.length - 1][0]
                  );
                }
                return splitedName[0][0] + splitedName[0][1];
              })()}
            </Label>
          </div>
        )}
      </div>
      <div class="col-sm-12 col-md-3 ">
        <h5 class="card-title text-primary">{lab.lab_name}</h5>

        <div>(Reg No:{lab.lab_registration_no})</div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="card-title d-flex text-wrap fs-6 ">
          <LocationOnOutlinedIcon style={{ color: "red" }} />
          <div
            style={{
              textAlign: "center",
              // alignItems: "center",
              width: "100%",
            }}
          >
            {lab.address} {lab.country ? `, ${lab.country}` : null}
          </div>
        </div>
        <div class="card-title d-flex text-wrap fs-6 ">
          <EmailIcon style={{ color: "grey" }} />
          <a
            href={`mailto:${lab?.email}`}
            style={{
              textDecoration: "none",
              color: "black",
              overflow: "hidden",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {lab?.email}
          </a>
        </div>
        <p class="card-text d-flex">
          <CallIcon style={{ color: "grey" }} />
          <a
            href={`tel:${lab?.phone1}`}
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {lab?.phone1}
          </a>
        </p>
      </div>
      <div class="col-sm-12 col-md d-flex justify-content-center">
        <div style={{ cursor: "pointer" }}>
          {lab.registration_approved === false ? (
            <>
              <Label style={{ fontSize: "0.85rem", color: "#ff8000" }}>
                Continue Registration
              </Label>
            </>
          ) : (
            <Label style={{ fontSize: "0.85rem", color: "#2eb82e" }}>
              Registration Approved
            </Label>
          )}
        </div>
      </div>
      <Col className="align-items-center" xs={12} md={2}>
        <Row className="justify-content-center">
          <Col xs={1} md={2}>
            <FontAwesomeIcon icon={faBell} />
          </Col>
          <Col xs={11} md={5}>
            <Badge variant="primary">{lab.start_time}</Badge>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={1} md={2}>
            <FontAwesomeIcon icon={faBellSlash} />
          </Col>
          <Col xs={11} md={5}>
            <Badge variant="secondary">{lab.end_time}</Badge>
          </Col>
        </Row>
        <div className="d-flex flex-column align-items-center">
          <label className="text-success">Accepting Orders</label>
          <Form.Check
            className="form-switch-lg mt-1"
            // disabled={updatingAccess}
            type="switch"
            // checked={accessAllowed}
            // onChange={handleAvailabilityToogle}
            value={lab.accepting_orders}
            style={{ transform: "scale(1.5)" }}
          />
        </div>
      </Col>
      <div class="col-sm-12 col-md">
        <div>
          <BorderColorIcon style={{ cursor: "pointer" }} />
        </div>
      </div>
    </div>
  );
};

export default PathologyLab;
