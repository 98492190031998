import { useContext, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";

import ModeContext from "../../context/mode-context";
import FormControl from "../form/FormControl";
import InputErrorMessage from "../kyc/InputErrorMessage";
import accreditationBodyOptions from "../../utils/accreditation-body-options";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import FieldInfo from "../form/FieldInfo";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { Button, Modal } from "react-bootstrap";
import Map from "../form/Map";

function Locationdetail(props) {
  const {
    type,
    isMainDataSaved,
    setIsShortNameValid,
    areDocsSubmitted,
    hospitalid,
  } = props;
  const { mode } = useContext(ModeContext);
  const [accreditationBodyVal, setAccreditationBodyVal] = useState(null);

  const ref = useRef();

  const formikProps = useFormikContext();

  const handleShortNameBlur = async (event) => {
    const dataMessageSmall = document.querySelector(".resMessageSmall");
    dataMessageSmall?.remove();

    formikProps.setFieldTouched("locationdetails.shortName");

    const shortName = event.target.value;

    if (shortName.trim() === "") return;
    if (shortName.trim().includes(" ")) return;

    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.HOSPITAL.CHECK_FACILITY_SHORT_NAME + "/" + shortName
      );

      if (res.status === 200) {
        const resMessageSmall = document.createElement("small");
        resMessageSmall.innerHTML = res.data.message;
        resMessageSmall.className =
          "d-block text-nowrap text-success text-end h-100 form-text resMessageSmall";
        ref.current.insertAdjacentElement("afterend", resMessageSmall);

        setIsShortNameValid(true);
      } else {
        // formikProps.setFieldError(
        //   "facilityDetails.shortName",
        //   "Entered Short Name is already in use. Please use another one."
        // );

        throw new Error(
          "Entered Short Name is already in use. Please use another one."
        );
      }
    } catch (error) {
      const resMessageSmall = document.createElement("small");
      resMessageSmall.innerHTML = error.message;
      resMessageSmall.className =
        "d-block text-nowrap text-danger text-end h-100 form-text resMessageSmall";
      ref.current.insertAdjacentElement("afterend", resMessageSmall);

      setIsShortNameValid(false);
    }
  };
  /* handle country change */
  const formikprops = useFormikContext();
  const countryOptions = [{ value: "IN", label: "India" }];
  const [selectedCountry, setSelectedCountry] = useState(
    formikProps?.values?.locationdetails?.country
  ); // Default selected country is India
  const [address, setAddress] = useState(
    formikProps?.values?.locationdetails?.address || ""
  );
  const [pin, setPin] = useState(
    formikProps?.values?.locationdetails?.pincode || ""
  );
  const [goglid, setGoglid] = useState(
    formikProps?.values?.locationdetails?.google_place_id || ""
  );
  const [geon, setGeon] = useState(
    formikProps?.values?.locationdetails?.google_geo_lng || ""
  );
  const [lang, setLang] = useState(
    formikProps?.values?.locationdetails?.google_geo_lat || ""
  );
  // console.log(2323, formikProps.values.locationdetails)
  // Function to handle country selection change
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    fetchStates(selectedOption.value);
    fetchCity(selectedOption.value);
    formikprops.setFieldValue("locationdetails.country", selectedOption);
  };
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const fetchStates = async (country) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/states/${country}`
      );

      if (res) {
        setStateList(res.data.states);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchCity = async (country, state) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/cities/${country}${state}`
      );

      if (res) {
        setCityList(res.data.cities);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleState = (selectedCountry, selectedState) => {
    fetchCity(selectedCountry.value, selectedState);
  };

  const options = stateList.map((state) => ({ label: state, value: state }));
  const optionscity = cityList.map((city) => ({ label: city, value: city }));

  const [showMapModal, setShowMapModal] = useState(false);

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleShowMapModal = (name) => {
    setShowMapModal(true);
  };

  const [googleMapValues, setGoogleMapValues] = useState({
    google_place_id: "",
    address: "",
    google_geo_lat: "",
    google_geo_lng: "",
  });

  const updateGoogleMapValues = (values) => {
    setGoogleMapValues(values);
  };

  const handleUpdateGoogleMapPlace = () => {
    if (
      googleMapValues.address &&
      googleMapValues.google_place_id &&
      googleMapValues.google_geo_lat &&
      googleMapValues.google_geo_lng
    ) {
      formikProps.setValues((prevState) => ({
        ...prevState,
        locationdetails: {
          ...prevState.locationdetails,
          // address: googleMapValues.address,
          // google_place_id: googleMapValues.google_place_id,
          // google_geo_lat: googleMapValues.google_geo_lat,
          // google_geo_lng: googleMapValues.google_geo_lng,
          ...googleMapValues,
        },
      }));
  
      handleCloseMapModal();
    }
  };
  
  const handleGeo = (event) => {
    const geo = event.target.value;
    setGeon(geo);
    formikprops.setFieldValue("locationdetails.google_place_id", geo);
  };

  const handleaddress = (event) => {
    const addess = event.target.value;
    setAddress(addess);
    formikProps.setFieldValue("locationdetails.address", addess);
  };
  const handlepin = (event) => {
    const pin = event.target.value;
    setPin(pin);
    formikProps.setFieldValue("locationdetails.pincode", pin);
  };

  const handlegoogleid = (event) => {
    const gid = event.target.value;
    setGoglid(gid);
    formikprops.setFieldValue("locationdetails.google_place_id", gid);
  };

  const handlegeo = (event) => {
    const geo = event.target.value;
    setGeon(geo);
    formikProps.setFieldValue("locationdetails.google_geo_lat", geo);
  };
  const handlelong = (event) => {
    const lan = event.target.value;
    setLang(lan);
    formikProps.setFieldValue("locationdetails.google_geo_l", lan);
  };

  return (
    <>
      <FormControl
        // info="Complete address of the Facility"
        label="Address"
        placeholder="Address"
        type="text"
        isMap
        name="locationdetails.address"
        onChange={handleaddress}
        disabled={isMainDataSaved || areDocsSubmitted}
        // disabled={isMainDataSaved }
        handleShowMapModal={handleShowMapModal}
      />

      <FormControl
        // info="Pincode where Facility is located."
        label="Pincode"
        placeholder="Pincode"
        type="text"
        name="locationdetails.pincode"
        onChange={handlepin}
        // disabled={isMainDataSaved }
      />
      <div className="row" style={{ position: "relative" }}>
      <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
                Country
            </label>


        {/* <FieldInfo
          // info="In case of Individual Doctor Clinic, provide the Accreditation Authority name which issued Doctor’s Registration"
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        /> */}

        <Field name="locationdetails.country" className="col-12 col-md-8">
          {({ field, form }) => (
            <Select
              {...field}
              name="locationdetails.country"
              defaultValue={selectedCountry[0]}
              className="col-12 col-md-8 px-0 h-50"
              placeholder="Country"
              onChange={handleCountryChange}
              options={countryOptions}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>

        {/* <FieldInfo
          // info="Country where Facility is located."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        /> */}
      </div>
      <ErrorMessage
        component={InputErrorMessage}
        name="locationdetails.country"
      />
      <div className="row" style={{ position: "relative" }}>
        <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
          State
        </label>

        {/* <FieldInfo
          // info="State where Facility is located."
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        /> */}

        <Field name="locationdetails.state" className="col-12 col-md-8">
          {({ field, form }) => (
            <Creatable
              {...field}
              name="locationdetails.state"
              options={options}
              className="col-12 col-md-8 px-0 h-50"
              placeholder="State"
              onChange={(option) => {
                form.setFieldValue("locationdetails.state", option, true);
                handleState(selectedCountry, `?state=${option.value}`);
              }}
              handleUpdateGoogleMapPlace
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>

        {/* <FieldInfo
          // info="State where Facility is located."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        /> */}
      </div>
      <ErrorMessage
        component={InputErrorMessage}
        name="locationdetails.state"
      />
      <div className="row" style={{ position: "relative" }}>
        <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
          City
        </label>

        {/* <FieldInfo
          // info="City where Facility is located."
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        /> */}

        <Field name="locationdetails.city" className="col-12 col-md-8">
          {({ field, form }) => (
            <Creatable
              {...field}
              // isDisabled={isMainDataSaved || hospitalid}
              options={optionscity}
              placeholder=" City"
              // className="w-100"
              name="locationdetails.city"
              className="col-12 col-md-8 px-0 h-50"
              onChange={(option) => {
                form.setFieldValue("locationdetails.city", option, true);
              }}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  // alignItems: "center",
                  // height: "3rem",
                  padding: "0px",
                  // width: "100%",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>

        {/* <FieldInfo
          // info="City where Facility is located."
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        /> */}
      </div>
      {/* {!accreditationBodyVal && ( */}
      <ErrorMessage component={InputErrorMessage} name="locationdetails.city" />
      {/* <FormControl
        // info="Complete address of the Facility"
        label="Select Geo Location"
        placeholder="Select Geo Location"
        type="text"
        isMap
        name="locationdetails.google_place_id"
        onChange={handleGeo}
        disabled={isMainDataSaved || areDocsSubmitted}
        // disabled={isMainDataSaved }
        handleShowMapModal={handleShowMapModal}
      /> */}

      {/* <FormControl
        // info="Google Place ID where Facility is located."
        // label="Google Place ID"
        placeholder="Google Place ID"
        type="text"
        name="locationdetails.google_place_id"
        onChange={handlegoogleid}
        // disabled={isMainDataSaved }
        className="facility-contact-2"
      />
      <FormControl
        // info="Geo-Latitude where Facility is located."
        // label="Geo-Latitude"
        placeholder="Geo-Latitude"
        type="text"
        onChange={handlegeo}
        name="locationdetails.google_geo_lat"
        // disabled={isMainDataSaved }
        className="facility-contact-2"
      /> */}
      {/* <FormControl
        // info="Geo-Longitude where Facility is located."
        // label="Geo-Longitude"
        placeholder="Geo-Longitude"
        type="text"
        onChange={handlelong}
        name="locationdetails.google_geo_lng"
        // disabled={isMainDataSaved }
        className="facility-contact-2"
      /> */}

      <Modal
        show={showMapModal}
        size="lg"
        aria-labelledby="custom-modal"
        centered
        contentClassName="border-0"
        onHide={handleCloseMapModal}
      >
        <Modal.Header
          closeButton
          className="border-0 fs-5 text-white"
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-3">
          <Map
            currentValues={formikProps?.values?.locationdetails || {}}
            updateGoogleMapValues={updateGoogleMapValues}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleUpdateGoogleMapPlace}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Locationdetail;
