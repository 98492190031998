import { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "react-bootstrap/Button";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import ModeContext from "../../context/mode-context";

const AppointModal = ({
  isOpen,
  onClose,
  PdfData,
  currentHospital,
  case_id,
  selectedFileCodes,
  setIsModalOpen,
  setSelectedRows
}) => {
  const { mode } = useContext(ModeContext);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const Handleshare = async () => {
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      rxOpdApi.setAuthHeaders(key, secret);

      const body = {
        file_code_list: selectedFileCodes,
      };
      const response = await rxOpdApi.put(
        RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.SHARE_FILE +
          currentHospital.hos_id +
          "/" +
          case_id,

        body,
      );

      if (response.data) {
        // Remove the deleted file from the newfile state
        // await newfiles();
        setShowToast(true);
        setToastType("success");
        setToastMessage(response.data.message);
        setSelectedRows("");
      } else {
        throw new Error(response.data.message || "Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setShowToast(true);
      setToastType("error");
      setToastMessage(
        error.message || "Failed to delete file. Please try again."
      );
    }
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="lg" centered>
        <div className="booklet-modal-content">
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            className="close-button"
          >
            <CloseIcon />
          </IconButton>

          <div className="pdf-container">
            <object
              data={PdfData}
              type="application/pdf"
              className="pdf-object"
            >
              An error occurred while fetching the file, please try again later
            </object>
          </div>
          <div className="share-container">
            <label className="share-label">
              Once you click share, file will be available at 'RxOne - Care to
              Cure app'
            </label>
            <Button onClick={Handleshare} className="share-button">
              Share
            </Button>
          </div>
        </div>
        <style jsx>{`
          .booklet-modal-content {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            position: relative;
            width: 100%;
            height: 90vh;
            max-width: 900px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
          }

          .close-button {
            position: absolute !important;
            top: 10px;
            right: 10px;
            color: black !important;
            z-index: 1000;
          }

          .pdf-container {
            flex-grow: 1;
            width: 100%;
            overflow: hidden;
          }

          .pdf-object {
            width: 100%;
            height: 100%;
          }

          .share-container {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .share-label {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
          }

          .share-button {
            align-self: flex-end;
          }

          @media (max-width: 768px) {
            .booklet-modal-content {
              height: 100vh;
              padding: 15px;
            }

            .close-button {
              top: 5px;
              right: 5px;
            }

            .pdf-container {
              height: calc(100% - 100px);
            }

            .share-container {
              margin-top: 0.5rem;
            }

            .share-label {
              font-size: 0.9rem;
            }
          }
        `}</style>
      </Modal>
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
};

export default AppointModal;
