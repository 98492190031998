import { useContext, useEffect, useState } from "react";

import HospitalsContext from "../../context/hospitals-context";
import ModeContext from "../../context/mode-context";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import AuthContext from "../../context/auth-context";

import WebAppPreview from "./web-app/WebAppPreview";
import { CircularProgress, Grid } from "@mui/material";
import WebAppCurrent from "./web-app/WebAppCurrent";

import { InputSelectElement } from "./web-app/common";
import { Label } from "./common";

import QRModal from "./web-app/QRModal";

function WebAppTab(props) {
  const { logout } = useContext(AuthContext);
  const { mode } = useContext(ModeContext);
  const { isLoading } = useContext(HospitalsContext);

  const [allFacilitiesData, setAllFacilitiesData] = useState([]);
  const [isAllFacilitiesDataLoading, setIsAllFacilitiesDataLoading] =
    useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facility_logo, setFacility_logo] = useState(null);
  const [currentFacilityData, setCurrentFacilityData] = useState({});
  const [previewFacilityData, setPreviewFacilityData] = useState({});
  const [isFetchDataLoading, setIsFetchDataLoading] = useState(false);
  const [facilityImages, setfacilityImages] = useState([]);
  const [facilityQRCode, setFacilityQRCode] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);

  const handleOpenQRModal = () => setShowQRModal(true);
  const handleCloseQRModal = () => setShowQRModal(false);

  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);

  useEffect(() => {
    if (shouldLogout && !showToast) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLogout, showToast]);

  useEffect(() => {
    const fetchApprovedFacilities = async () => {
      const userKeys = localStorage.getItem("usr_keys");

      if (userKeys) {
        setIsAllFacilitiesDataLoading(true);
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_WEB_APPS
          );

          if (
            res?.data?.records &&
            Array.isArray(res.data.records) &&
            res.data.records.length
          ) {
            const records = res.data.records.map((fac) => ({
              label: fac.facility_name,
              value: fac.facility_id,
            }));
            setAllFacilitiesData(records);
            setSelectedFacility(records[0]);
          }
        } catch (error) {
          if (error?.status === 401) {
            if (!document.querySelector(".toast-modal")) {
              setShowToast(true);
              setToastType("error");
              setToastMessage("Invalid session. Please login again.");
              setShouldLogout(true);
            }
          } else {
            setShowToast(true);
            setToastType("error");
            setToastMessage(error?.error?.message || error?.message);
          }
        } finally {
          setIsAllFacilitiesDataLoading(false);
        }
      }
    };

    fetchApprovedFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (selectedFacility && selectedFacility?.value) {
      const fetchData = async () => {
        setIsFetchDataLoading(true);
        const currentData = await fetchCurrentValue();
        const previewData = await fetchPreviewValue();
        const brandLogo = await fetchBrandLogoUrl();
        const facilityImages = await fetchFacilityImages();
        const facilityQRCode = await fetchQRCode();

        if (currentData) {
          setCurrentFacilityData(currentData);
        }
        if (previewData) {
          setPreviewFacilityData(previewData);
        }
        if (brandLogo) {
          setFacility_logo(brandLogo);
        }
        if (facilityImages) {
          setfacilityImages(facilityImages);
        }
        if (facilityQRCode) {
          setFacilityQRCode(facilityQRCode);
        }
        setIsFetchDataLoading(false);
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacility]);

  const fetchBrandLogoUrl = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let brandLogo = "";

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.BRAND_LOGO}/${selectedFacility.value}`
        );
        if (res?.data?.url && typeof res.data.url === "string") {
          brandLogo = res.data.url;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return brandLogo;
  };

  const fetchCurrentValue = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let currentData = {};

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.CURRENT_VALUE}/${selectedFacility.value}`
        );

        if (
          typeof res.data === "object" &&
          !Array.isArray(res.data) &&
          res.data !== null
        ) {
          currentData = res.data;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return currentData;
  };

  const fetchPreviewValue = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let previewData = {};

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.PREVIEW_VALUE}/${selectedFacility.value}`
        );

        if (
          typeof res.data === "object" &&
          !Array.isArray(res.data) &&
          res.data !== null
        ) {
          previewData = res.data;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return previewData;
  };

  const fetchFacilityDoctors = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let doctors = [];

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_DOCTORS}/${selectedFacility.value}`
        );

        if (
          res?.data?.records &&
          Array.isArray(res.data.records) &&
          res.data.records.length
        ) {
          doctors = res.data.records.map((fac) => ({
            label: fac.doctor_name,
            value: fac.doc_id,
          }));
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return doctors;
  };

  const fetchFacilityPharmacies = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let pharmacies = [];

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_PHARMACIES}/${selectedFacility.value}`
        );

        if (
          res?.data?.records &&
          Array.isArray(res.data.records) &&
          res.data.records.length
        ) {
          pharmacies = res.data.records.map((fac) => ({
            label: fac.facility_name,
            value: fac.facility_id,
          }));
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return pharmacies;
  };

  const fetchFacilityPathLabs = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let pathLabs = [];

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_PATH_LABS}/${selectedFacility.value}`
        );

        if (
          res?.data?.records &&
          Array.isArray(res.data.records) &&
          res.data.records.length
        ) {
          pathLabs = res.data.records.map((fac) => ({
            label: fac.facility_name,
            value: fac.facility_id,
          }));
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return pathLabs;
  };

  const updateCurrentValues = async (updatedData) => {
    const userKeys = localStorage.getItem("usr_keys");

    let currentData = {};

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.put(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.PUBLISH_FACILITY_DATA}/${selectedFacility.value}`,
          updatedData
        );

        if (
          typeof res.data === "object" &&
          !Array.isArray(res.data) &&
          res.data !== null
        ) {
          currentData = res.data;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return currentData;
  };

  const updateBrandLogo = async (formData) => {
    const userKeys = localStorage.getItem("usr_keys");

    let brandLogo = "";

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();
        const res = await rxOpdApi.put(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.CHANGE_FACILITY_LOGO}/${selectedFacility.value}`,
          formData,
          true
        );

        if (res?.data?.url && typeof res.data.url === "string") {
          brandLogo = res.data.url;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return brandLogo;
  };

  const addFacilityImage = async (formData) => {
    const userKeys = localStorage.getItem("usr_keys");

    let images = [];

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();
        const res = await rxOpdApi.post(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.ADD_FACILITY_IMAGES}/${selectedFacility.value}`,
          formData,
          true
        );

        if (res?.data?.records && Array.isArray(res?.data?.records)) {
          images = res.data.records;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return images;
  };

  const deleteFacilityImage = async (pic_name) => {
    const userKeys = localStorage.getItem("usr_keys");

    let images = false;

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.delete(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.DELETE_FACILITY_IMAGES}/${selectedFacility.value}/${pic_name}`
        );

        if (res?.data?.records && Array.isArray(res.data.records)) {
          images = res.data.records;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return images;
  };

  const fetchFacilityImages = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let images = [];

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_FACILITY_IMAGES}/${selectedFacility.value}`
        );

        if (res?.data?.records && Array.isArray(res.data.records)) {
          images = res.data.records;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return images;
  };

  const isFacilitySectionVisible = () => {
    if (
      selectedFacility &&
      selectedFacility?.value &&
      currentFacilityData &&
      previewFacilityData &&
      Object.keys(currentFacilityData).length &&
      Object.keys(previewFacilityData).length &&
      !isFetchDataLoading
    ) {
      return true;
    }
    return false;
  };

  const handlePublish = async (updatedData) => {
    const currentData = await updateCurrentValues(updatedData);

    if (currentData) {
      setCurrentFacilityData(currentData);
      setToastType("success");
      setToastMessage(currentData.message);

      const previewData = await fetchPreviewValue();
      if (previewData) {
        setPreviewFacilityData(previewData);
      }
    }
  };

  const handleBrandLogoUpdate = async (formData) => {
    const brandLogo = await updateBrandLogo(formData);

    if (brandLogo) {
      setFacility_logo(brandLogo);
      const previewData = await fetchPreviewValue();
      if (previewData) {
        setPreviewFacilityData(previewData);
      }
    }
  };

  const handleAddFacilityImage = async (formData) => {
    const images = await addFacilityImage(formData);

    if (images) {
      setfacilityImages(images);
      const previewData = await fetchPreviewValue();
      if (previewData) {
        setPreviewFacilityData(previewData);
      }

      return facilityImages;
    }

    return [];
  };

  const handleDeleteFacilityImage = async (pic_name) => {
    const images = await deleteFacilityImage(pic_name);

    if (images) {
      setfacilityImages(images);
      const previewData = await fetchPreviewValue();
      if (previewData) {
        setPreviewFacilityData(previewData);
      }

      return facilityImages;
    }

    return [];
  };

  const fetchQRCode = async () => {
    const userKeys = localStorage.getItem("usr_keys");

    let brandLogo = null;

    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.QR_CODE}/${selectedFacility.value}`
        );

        if (res?.data) {
          brandLogo = res?.data;
        }
      } catch (error) {
        if (error?.status === 401) {
          if (!document.querySelector(".toast-modal")) {
            setShowToast(true);
            setToastType("error");
            setToastMessage("Invalid session. Please login again.");
            setShouldLogout(true);
          }
        } else {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error?.error?.message || error?.message);
        }
      }
    }

    return brandLogo;
  };

  const handleFacilityChange = (options) => {
    setSelectedFacility(options);
    setFacility_logo(null);
    setCurrentFacilityData({});
    setPreviewFacilityData({});
    setfacilityImages([]);
    setFacilityQRCode(null);
    setShowQRModal(false);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          alignContent="center"
          display="flex"
          style={{ width: "100%" }}
        >
          <div>
            <>
              <div className="mx-auto my-0 px-4 pt-4 pb-1">
                <div>
                  <InputSelectElement
                    loading={isAllFacilitiesDataLoading}
                    value={selectedFacility}
                    onChange={handleFacilityChange}
                    options={allFacilitiesData}
                    multiple={false}
                    label="Facility"
                  />
                </div>
              </div>
            </>

            <div className="mx-auto my-0 pt-1 pb-4">
              <Label variant="subtitle2">
                (Only approved individual facilities are listed)
              </Label>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12} display="flex" justifyContent="center">
              {facilityQRCode && isFacilitySectionVisible() ? (
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    facilityQRCode
                  )}`}
                  style={{
                    width: "70px",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  alt="QR Code"
                  onClick={handleOpenQRModal}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              {facilityQRCode && isFacilitySectionVisible() ? (
                <Label variant="subtitle2">(Click here to Expand)</Label>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {isFacilitySectionVisible() ? (
          <>
            <WebAppCurrent
              currentData={currentFacilityData}
              facility_logo={facility_logo}
              facility_id={selectedFacility.value}
              facilityImages={facilityImages}
              fetchFacilityDoctors={fetchFacilityDoctors}
              fetchFacilityPharmacies={fetchFacilityPharmacies}
              fetchFacilityPathLabs={fetchFacilityPathLabs}
              handlePublish={handlePublish}
              handleShowSuccessfullyPubishModal={() => {
                setShowToast(true);
              }}
              handleBrandLogoUpdate={handleBrandLogoUpdate}
              handleAddFacilityImage={handleAddFacilityImage}
              handleDeleteFacilityImage={handleDeleteFacilityImage}
            />
            <WebAppPreview previewData={previewFacilityData} />
          </>
        ) : isFetchDataLoading ? (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            style={{ height: "60vh", display: "flex", width: "100%" }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress sx={{ color: "#00b0f0" }} />
          </Grid>
        ) : null}
      </Grid>
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}

      {showQRModal ? (
        <QRModal
          show={showQRModal}
          onHide={handleCloseQRModal}
          facilityQRCodeSvg={facilityQRCode}
          qrLink={previewFacilityData.web_app_short_url}
        />
      ) : null}
    </>
  );
}

export default WebAppTab;

// Testing of git
