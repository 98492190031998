import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import InputEle from "../register-and-login/InputEle";
import Button from "../ui/Button";
import Toast from "../ui/Toast";
import { rxOneApi } from "../../utils/api/api";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";

function RemoveUserForm({ userName, onHide, staffId, setShallUpdate }) {
  const [inputName, setInputName] = useState("");
  const [removingUser, setRemovingUser] = useState(false);
  const [toast, setToast] = useState({ show: false, type: "", message: "" });
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  // Check if input is not empty
  const isInputValid = inputName.trim().length > 0;

  useEffect(() => {
    if (submittedSuccessfully && !toast.show) {
      setShallUpdate(true);
      onHide();
    }
  }, [submittedSuccessfully, toast.show, setShallUpdate, onHide]);

  const handleRemoveUser = useCallback(async () => {
    console.log("Remove button clicked");
    setRemovingUser(true);
  
    try {
      const userSecret = localStorage.getItem("usr_secret");
      const userToken = localStorage.getItem("usr_token");
      const res = await rxOneApi.axios.delete(
        `${RX_ONE_ENDPOINTS.SETTINGS.USER_DETAILS}/${userToken}/${staffId}`,
        { headers: { usr_secret: userSecret } }
      );

      setToast({ show: true, type: "success", message: res.data.message });
      setSubmittedSuccessfully(true);
    } catch (error) {
      setToast({ show: true, type: "error", message: error.message });
    } finally {
      setRemovingUser(false);
    }
  }, [staffId]);

  return (
    <>
      <p>
        Once the user is removed, no data associated with the user can be
        recovered. <br />
        <br />
        To delete the user, type "DELETE" in the input box and click REMOVE:
      </p>

      <p className="fw-bold text-uppercase">{userName}</p>

      <InputEle name="input" value={inputName} setValue={setInputName} />

      <Row className="justify-content-center my-3">
        <Col xs={6} className="d-flex justify-content-end pe-1">
          <Button
            style={{
              backgroundColor: "white",
              border: "1px solid primary",
              color: "black",
            }}
            onClick={onHide}
            disabled={removingUser}
          >
            Cancel
          </Button>
        </Col>

        <Col xs={6} className="d-flex justify-content-start ps-1">
          <Button
            className="bg-danger border-danger"
            disabled={!isInputValid || removingUser}
            onClick={handleRemoveUser}
          >
            {removingUser ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mx-3"
              />
            ) : (
              "REMOVE"
            )}
          </Button>
        </Col>
      </Row>

      <Toast 
        type={toast.type} 
        show={toast.show} 
        handleToastClose={() => setToast(prev => ({ ...prev, show: false }))}
      >
        {toast.message}
      </Toast>
    </>
  );
}

export default RemoveUserForm;