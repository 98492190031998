import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Form, Button } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Avatar from "@mui/material/Avatar";
import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import EditTableData from "./EditTableData";
import DoctorAvailabilitySwitch from "./DoctorAvailabilitySwitch";
import Dcotormarket from "./Doctormarket";
import Doctormarkets from "./Doctormarkets";
import EditDoctorSchedule from "./EditDoctorSchedule";
import RefreshDataBtn from "../refresh-data/RefreshDataBtn";
import DoctorDetailsModal from "./DoctorDetailsModal";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { Label } from "../facilities/common";

function DoctorsTab(props) {
  const {
    hospitalId,
    registeredDoctors,
    setRegisteredDoctors,
    areDoctorsLoading,
    setAreDoctorsLoading,
    noDataMessage,
    isAnyDocRegPending,
  } = props;

  const { isLoading, hospitals, currentHospital } = useContext(HospitalsContext);
  const [doctorAllDetails, setDoctorAllDetails] = useState({});
  const [onlineBooking, setOnlineBooking] = useState(false);
  const [reAssignAppointment, setReAssignAppointment] = useState(false);
  const [selectedDoctorDetails, setSelectedDoctorDetails] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { mode } = useContext(ModeContext);
  const [errorMessage, setErrorMessage] = useState(noDataMessage);

  const navigate = useNavigate();

  const handleContinueDocReg = (doctorId) => {
    navigate("/app/doctors/register?edit=true&doc_id=" + doctorId, {
      replace: true,
    });
  };

  const handleEditDetails = async (doctor) => {
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.HOSPITAL.FETCH_A_DOCTOR +
          "/" +
          currentHospital.hos_id +
          "/" +
          doctor.doc_id
      );

      const doctordetails = res.data;
      setSelectedDoctorDetails(doctordetails);
      setDoctorAllDetails(doctor);
      setShowDetailsModal(true);
    } catch (error) {
      console.error("Error fetching doctor data:", error);
    }
  };

  const handleOnlineBookingChange = async (doctorId, newValue) => {
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);

      await rxOpdApi.put(
        `${RX_OPD_ENDPOINTS.HOSPITAL.UPDATE_DOCTOR_ONLINE_BOOKING}/${currentHospital.hos_id}/${doctorId}`,
        { online_booking: newValue }
      );

      setOnlineBooking(newValue);
      setRegisteredDoctors((prevDoctors) =>
        prevDoctors.map((doc) =>
          doc.doc_id === doctorId ? { ...doc, online_booking: newValue } : doc
        )
      );
    } catch (error) {
      console.error("Error updating online booking status:", error);
    }
  };

  const handleReAssignAppointmentChange = async (doctorId, newValue) => {
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);

      await rxOpdApi.put(
        `${RX_OPD_ENDPOINTS.HOSPITAL.UPDATE_DOCTOR_REASSIGN_APPOINTMENT}/${currentHospital.hos_id}/${doctorId}`,
        { reassign_appointment: newValue }
      );

      setReAssignAppointment(newValue);
      setRegisteredDoctors((prevDoctors) =>
        prevDoctors.map((doc) =>
          doc.doc_id === doctorId
            ? { ...doc, reassign_appointment: newValue }
            : doc
        )
      );
    } catch (error) {
      console.error("Error updating re-assign appointment status:", error);
    }
  };

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col sm={12} md={3}>
          <Button
            disabled={!isLoading && hospitals.length === 0}
            className="float-start mt-2 mx-3 rounded-pill"
            style={{ background: "#009698" }}
          >
            <Nav.Link
              as={NavLink}
              to="/app/doctors/register"
              className="p-0 text-white"
            >
              Add Providers
            </Nav.Link>
          </Button>
        </Col>
        <Col sm={12} md={9}>
          <RefreshDataBtn
            type="doctors"
            setData={setRegisteredDoctors}
            tableDataLoading={areDoctorsLoading}
            setTableDataLoading={setAreDoctorsLoading}
            setErrorMessage={setErrorMessage}
          />
        </Col>
      </Row>

      {registeredDoctors.map((doctor) => (
        <Row
          key={doctor.doc_id}
          className="shadow p-3 mb-4 align-items-center"
          style={{
            borderRadius: "20px",
            minHeight: "150px",
          }}
        >
          <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
            <Avatar
              variant="circular"
              src={
                process.env.REACT_APP_RX_OPD +
                RX_OPD_ENDPOINTS.HOSPITAL.DOCTORS_PIC +
                "/" +
                mode +
                "/" +
                doctor.doc_id
              }
              className="rounded-circle border border-5 border-primary"
              style={{
                height: "100px",
                width: "100px",
                margin: "auto",
              }}
            />
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-md-0">
            <h5 className="card-title text-primary">
              {doctor.doctor_name}
              <BorderColorIcon
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                }}
                onClick={() => handleEditDetails(doctor)}
              />
            </h5>
            <div>(Speciality: {doctor.speciality})</div>
            <div>(RMP No: {doctor.rmp_num})</div>
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-md-0">
            <div style={{ color: "gray", fontWeight: "bold" }}>
              Consultation Fee
            </div>
            <EditTableData
              type="consult-charges"
              dataPrefix={doctor.currency}
              data={doctor.consult_charges}
              hospitalId={hospitalId}
              doctorId={doctor.doc_id}
            />
            <div style={{ color: "gray", fontWeight: "bold", marginTop: "10px" }}>
              Online Discount
            </div>
            <EditTableData
              type="discount"
              data={doctor.online_discount.substring(
                0,
                doctor.online_discount.length - 1
              )}
              dataPostfix="%"
              hospitalId={hospitalId}
              doctorId={doctor.doc_id}
            />
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-md-0">
            <div style={{ color: "gray", fontWeight: "bold" }}>
              RxOne App
            </div>
            <Dcotormarket
              hospitalId={hospitalId}
              available={doctor.marketplace_flag}
              doctorId={doctor.doc_id}
            />
            <div style={{ color: "gray", fontWeight: "bold", marginTop: "10px" }}>
              Availability
            </div>
            <DoctorAvailabilitySwitch
              hospitalId={hospitalId}
              available={doctor.availability}
              doctorId={doctor.doc_id}
            />
                 <div style={{ color: "gray", fontWeight: "bold", marginTop: "10px" }}>
              Show in prescription
            </div>
            <Doctormarkets
              hospitalId={hospitalId}
              available={doctor.show_in_prescription}
              doctorId={doctor.doc_id}
            />
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-md-0">
            <div style={{ color: "gray", fontWeight: "bold" }}>
              Online Booking
            </div>
            <Form.Check 
              type="switch"
              id={`online-booking-switch-${doctor.doc_id}`}
              checked={doctor.online_booking}
              onChange={(e) => handleOnlineBookingChange(doctor.doc_id, e.target.checked)}
              className="custom-switch"
            />
            <div style={{ color: "gray", fontWeight: "bold", marginTop: "10px" }}>
              Re-assign Appointment
            </div>
            <Form.Check 
              type="switch"
              id={`reassign-appointment-switch-${doctor.doc_id}`}
              checked={doctor.reassign_appointment}
              onChange={(e) => handleReAssignAppointmentChange(doctor.doc_id, e.target.checked)}
              className="custom-switch"
            />
          </Col>

          <Col xs={6} md={1} className="mb-3 mb-md-0">
            <div style={{ color: "gray", fontWeight: "bold" }}>Schedule</div>
            <EditDoctorSchedule
              hospitalId={hospitalId}
              doctorId={doctor.doc_id}
            />
          </Col>

          <Col xs={6} md={1} className="mb-3 mb-md-0">
  <Button
    disabled
    className={`text-capitalize w-100 fw-bold border-2 btn btn-outline-${
      doctor.registration_status === "Confirmed" ? "success" : "danger"
    }`}
    style={{
      fontSize: '0.8rem',
      padding: '0.25rem 2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      minHeight: '50px'
    }}
  >
    {doctor.registration_status === "Confirmed" ? (
      <>
        <CheckIcon style={{ fontSize: '1rem', marginBottom: '2px' }} />
        <span style={{marginLeft: "-0rem"}}>Confirmed</span>
      </>
    ) : (
      <>
        <AssignmentLateIcon style={{ fontSize: '1rem', marginBottom: '2px' }} />
        <span style={{marginLeft: "-0rem"}}>{doctor.registration_status}</span>
      </>
    )}
  </Button>
  {doctor.registration_status !== "Confirmed" && (
    <div
      style={{ cursor: "pointer", marginTop: "5px" }}
      onClick={() => handleContinueDocReg(doctor.doc_id)}
    >
      <Label style={{ fontSize: "0.75rem", color: "#ff8000" }}>
        Continue Registration
      </Label>
    </div>
  )}
</Col>
        </Row>
      ))}

      {showDetailsModal && (
        <DoctorDetailsModal
          doctor={selectedDoctorDetails}
          doctorAllDetails={doctorAllDetails}
          operation="edit"
          onHide={() => setShowDetailsModal(false)}
          onEdit={handleEditDetails}
        />
      )}
    </Container>
  );
}

export default DoctorsTab;