import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Grid } from "@mui/material";
import Mobileimg from "../../assets/images/static/image.png";
import folderimg from "../../assets/images/static/folder.png";
import printerimg from "../../assets/images/static/printer.png";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOpdApi } from "../../utils/api/api";
import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Selecthos from "./Selecthos";
import ClearIcon from "@mui/icons-material/Clear";
import Toast from "../ui/Toast";
import BookletModal from "./BookletModal";

const BookletTab = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { mode } = useContext(ModeContext);
  const fileInputRef = useRef(null);
  const [pdfData, setPdfData] = useState(null);
  const steps = [
    { image: Mobileimg, title: "1. Create Booklet" },
    { image: folderimg, title: "2. Add Images/Files" },
    { image: printerimg, title: '3. Select "Print Booklet"' },
  ];

  const [isAllFacilitiesDataLoading, setIsAllFacilitiesDataLoading] =
    useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [currentFacilityId, setCurrentFacilityId] = useState(null);
  const { currentHospital } = useContext(HospitalsContext);
  const [allFacilitiesData, setAllFacilitiesData] = useState([]);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [frontPages, setFrontPages] = useState([]);
  const [backPages, setBackPages] = useState([]);
  const [currentPageType, setCurrentPageType] = useState(null);
  const { isLoading } = useContext(HospitalsContext);
  const [aredataLoading, setAredataLoading] = useState(false);
  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];
  const noImages = frontPages.length === 0 && backPages.length === 0;
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleClose = () => setShowModal(false);

  const handlePopupClose = () => setShowPopup(false);
  const handlePopupShow = () => setShowPopup(true);

  const handleShow = async () => {
    if (currentFacilityId) {
      await newfiles(currentFacilityId);
      setShowModal(true);
    } else {
      // Handle the case when no facility is selected
      setShowToast(true);
      setToastType("error");
      setToastMessage("Please select a facility first.");
    }
  };

  useEffect(() => {
    // Set the initial facility ID when the component mounts
    if (currentHospital && currentHospital.hos_id) {
      setCurrentFacilityId(currentHospital.hos_id);
      newfiles(currentHospital.hos_id);
    }
  }, [currentHospital]);

  useEffect(() => {
    const fetchApprovedFacilities = async () => {
      const userKeys = localStorage.getItem("usr_keys");

      if (userKeys) {
        setIsAllFacilitiesDataLoading(true);
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.WEB_APP.LIST_WEB_APPS
          );

          if (
            res?.data?.records &&
            Array.isArray(res.data.records) &&
            res.data.records.length
          ) {
            const records = res.data.records.map((fac) => ({
              label: fac.facility_name,
              value: fac.facility_id,
            }));
            setAllFacilitiesData(records);
            setSelectedFacility(records[0]);
          }
        } catch (error) {
          handleApiError(error);
        } finally {
          setIsAllFacilitiesDataLoading(false);
        }
      }
    };

    fetchApprovedFacilities();
  }, [isLoading, mode]);

  const handleApiError = (error) => {
    if (error?.status === 401) {
      if (!document.querySelector(".toast-modal")) {
        setShowToast(true);
        setToastType("error");
        setToastMessage("Invalid session. Please login again.");
      }
    } else {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error?.error?.message || error?.message);
    }
  };

  const updateCurrentValues = async (file, pageType) => {
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys && currentFacilityId) {
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);

        const formData = new FormData();
        formData.append("facility_booklet_pic", file);

        await rxOpdApi.put(
          `${RX_OPD_ENDPOINTS.BOOKLET.BOOKLET_PHOTO}/${currentFacilityId}/${pageType}`,
          formData
        );

        const localUrl = URL.createObjectURL(file);

        if (pageType === "front") {
          setFrontPages([...frontPages, localUrl]);
        } else {
          setBackPages([...backPages, localUrl]);
        }
        setShowPopup(false);

        setShowToast(true);
        setToastType("success");
        setToastMessage("File uploaded successfully");
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error?.error?.message || error?.message);
      }
    }
  };

  const handleDeleteImage = async (pageType, index) => {
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys && currentFacilityId) {
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);

        await rxOpdApi.delete(
          `${RX_OPD_ENDPOINTS.BOOKLET.REMOVE_IMAGE}/${currentFacilityId}/${pageType}/${index}`
        );

        if (pageType === "front") {
          setFrontPages(frontPages.filter((_, i) => i !== index));
        } else {
          setBackPages(backPages.filter((_, i) => i !== index));
        }

        setShowToast(true);
        setToastType("success");
        setToastMessage("Image deleted successfully");
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleFileUpload = (pageType) => {
    setCurrentPageType(pageType);
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // await checkFileSize(file);
        await updateCurrentValues(file, currentPageType);
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error.message);
      }
    }
  };

  const newfiles = async (facilityId) => {
    setAredataLoading(true);
    // Clear existing images before fetching new ones
    setFrontPages([]);
    setBackPages([]);

    const userKeys = localStorage.getItem("usr_keys");
    if (userKeys) {
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);

      try {
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.BOOKLET.FETCH_IMG}/${facilityId}`
        );
        if (res.data) {
          setFrontPages(res.data.front_pages || []);
          setBackPages(res.data.back_pages || []);

          if (
            res.data.front_pages.length === 0 &&
            res.data.back_pages.length === 0
          ) {
            // setShowToast(true);
            // setToastType("info");
            // setToastMessage(res.data.message);
          }
        }
      } catch (error) {
        handleApiError(error);
      } finally {
        setAredataLoading(false);
      }
    }
  };

  const handleHospitalSelect = (facilityId) => {
    setCurrentFacilityId(facilityId);
    newfiles(facilityId);
  };

  

  const handleCloseModal = () => {
    // Close the modal and reset the PDF data
    setIsModalOpen(false);
    // setPdfData(null);
  };



  const handlePrescriptionDownload = async () => {
        setAredataLoading(true);

    try {
      // Set authentication headers
      rxOpdApi.setAuthHeaders(key, secret);
  
      // Generate the prescription file (PUT request)
      const PrescriptionGenerate = await rxOpdApi.put(
        RX_OPD_ENDPOINTS.BOOKLET.BOOKLET_VIEW + "/" + `${currentFacilityId}`,
        ""
      );
  
      if (PrescriptionGenerate) {
        const apiUrl =
        process.env.REACT_APP_RX_OPD +
        RX_OPD_ENDPOINTS.BOOKLET.BOOKLET_GET +
        "/" +
        currentFacilityId
  
      // Fetch the PDF data
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: "Basic " + btoa(key + ":" + secret),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the PDF blob to a URL and set it in state
          const blobUrl = URL.createObjectURL(blob);
          setPdfData(blobUrl);
          setAredataLoading(false);

          // Open the modal to display the PDF
          setIsModalOpen(true)
        });
        // setAredataLoading(false);
        // setShowToast(true);
        // setToastType("success");
        // setToastMessage(PrescriptionGenerate.data.message);
      }
    } catch (err) {
      console.error("Error in handlePrescriptionDownload:", err);
    }
  };
  
  
  return (
    <>
      {aredataLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Grid container>
        <Col xs={12} md={7} className="px-4 px-md-4">
          <Selecthos
            type="appointment"
            setShowModal={setShowModal}
            onHospitalSelect={handleHospitalSelect}
          />
        </Col>

        <Button
          onClick={handleShow}
          style={{
            // marginTop: "2rem",
            // marginBottom: ".5rem",
            marginLeft: "1rem",
            background: "#4d9aa6",
            borderColor: "#4d9aa6",
          }}
        >
          Editor+
        </Button>
        <Container fluid className="text-center">
          <Row className="my-4">
            <Col>
              <h2 className="mb-3">
                Create beautiful custom booklet/folder to share with your
                customers
              </h2>
              <h3 className="mb-5">How it works?</h3>
            </Col>
          </Row>
          <Row>
            {steps.map((step, index) => (
              <Col key={index} sm={12} md={4} className="mb-4">
                <Image
                  src={step.image}
                  alt={step.title}
                  fluid
                  className="mb-3"
                />
                <h4>{step.title}</h4>
              </Col>
            ))}
          </Row>
        </Container>
      </Grid>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        {/* Use onClick to trigger the handleClose function when the ClearIcon is clicked */}
        <div style={{ float: "inline-end", cursor: "pointer" }}>
          <ClearIcon
            onClick={handleClose}
            style={{ float: "inline-end", cursor: "pointer" }} // Optional: Add cursor to indicate it's clickable
          />
        </div>
        <Modal.Body
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "300px" }}
        >
          <div className="mb-3 text-center">
            <h5>Front Pages</h5>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {frontPages.map((url, index) => (
                <div key={index} className="position-relative m-2">
                  <Image
                    src={url}
                    alt={`Front page ${index + 1}`}
                    fluid
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button
                    variant="link"
                    size="sm"
                    className="position-absolute top-0 end-0 p-0"
                    onClick={() => handleDeleteImage("front", index)}
                    style={{ color: "red" }}
                  >
                    <RemoveCircleOutlineIcon />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "blue",
              position: "relative",
              margin: "20px 0",
            }}
          >
            <Button
              variant="primary"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handlePopupShow}
            >
              +
            </Button>
          </div>

          <div className="mt-3 text-center">
            <h5>Back Pages</h5>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {backPages.map((url, index) => (
                <div key={index} className="position-relative m-2">
                  <Image
                    src={url}
                    alt={`Back page ${index + 1}`}
                    fluid
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button
                    variant="link"
                    size="sm"
                    className="position-absolute top-0 end-0 p-0"
                    onClick={() => handleDeleteImage("back", index)}
                    style={{ color: "red" }}
                  >
                    <RemoveCircleOutlineIcon />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div>
          <Button
            disabled={noImages}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              cursor: noImages ? "not-allowed" : "pointer",
              opacity: noImages ? 0.5 : 1,
            }}
            onClick={handlePrescriptionDownload}
          >
            View/Generate
          </Button>
          <BookletModal isOpen={isModalOpen} onClose={handleCloseModal}  hosid={currentFacilityId} pdfData={pdfData} />

        </div>
              
        </Modal.Body>
      </Modal>

      <Modal show={showPopup} onHide={handlePopupClose} size="sm" centered>
        <div style={{ float: "inline-end", cursor: "pointer" }}>
          <ClearIcon
            onClick={handlePopupClose}
            style={{ float: "inline-end", cursor: "pointer" }} // Optional: Add cursor to indicate it's clickable
          />
        </div>
        <Modal.Title
          style={{ color: "red", fontSize: "15px", textAlign: "center" }}
        >
          (Upload only A4 size Image/File)
        </Modal.Title>
        <Modal.Body style={{ background: "#f2f2f2" }}>
          <div className="d-flex justify-content-around flex-column">
            <Button variant="primary" onClick={() => handleFileUpload("front")}>
              Add Front Page
            </Button>
            <Button
              variant="primary"
              onClick={() => handleFileUpload("back")}
              style={{ marginTop: "1rem" }}
            >
              Add Back Page
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal open={openModal} onClose={handleCloseModal}>
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <div style={{ marginTop: "2px" }}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleCloseModal}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>

    <div style={{ height: "500px", width: "100%" }}>
      <object
        data={pdfData}
        type="application/pdf"
        style={{ width: "800px", height: "500px" }}
      >
        An error occurred while fetching the file, please try again later
      </object>
    </div>
  </div>
</Modal> */}



      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
};

export default BookletTab;
