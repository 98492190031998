import { useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";

import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import Form from "../form/Form";
import FacilityDetails from "./FacilityDetails";
import RegistrationDocs from "./RegistrationDocs";
import Facilitytype from "./Facilitytype";
import Locationdetail from "./Locationdetail";
import Toast from "../ui/Toast";
import { rxOneApi, rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { useFormikContext } from "formik";

const initialValues = {
  facilityType: {
    facility_type: "",
    facility_name: "",
    short_name: "",
    facility_description: "",
  },
  facilityDetails: {
    hosp_accreditation_by: "",
    hosp_registration_no: "",
    email: "",
    phone1: "",
    phone2: "",
    gstin: "",
    gstin_legal_name: "",
    gstin_trade_name: "",
  },

  locationdetails: {
    address: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    google_place_id: "",
    google_geo_lat: "",
    google_geo_lng: "",
  },

  // uploadDocs: {
  //   registrationCertificate: "",
  //   hospitalLogo: "",
  // },
};

const validationSchema = Yup.object().shape({
  facilityType: Yup.object().shape({
    facility_type: Yup.mixed().required("Facility type is required"),
    facility_name: Yup.string().required("Facility Name is required"),
    short_name: Yup.string()
      .trim()
      .matches(/^\S+$/, "Short Name cannot contain whitespaces")
      .required("Facility Short Name is required"),
    facility_description: Yup.string().required(
      "facility description is required"
    ),
  }),

  facilityDetails: Yup.object().shape({
 
    hosp_accreditation_by: Yup.object().required("Accreditation Body is required"),
    hosp_registration_no: Yup.string().required("Registration No. is required"),
    // bedsCount: Yup.string(),
    email: Yup.string()
      .required("Contact Email is required")
      .email("Invalid email format"),
      phone1: Yup.string()
      .required("Contact Number (1) is required")
      .matches(/^[6-9]\d{9}$/gi, "Invalid phone number")
      .label("Contact Number (1)"),
      phone2: Yup.string()
      .matches(/^[6-9]\d{9}$/gi, "Invalid phone number")
      .label("Contact Number (2)"),
    gstin: Yup.string(),
    gstin_legal_name: Yup.string(),
    gstin_trade_name: Yup.string(),
  }),
  locationdetails: Yup.object().shape({
    address: Yup.string().required("Address is required"),
    pincode: Yup.string().required("Pincode is required"),
    country: Yup.object().required("Country is required"),
    state: Yup.object().required("State is required"),
    city: Yup.object().required("City is required"),
    google_place_id: Yup.string(),
    google_geo_lat: Yup.string(),
    google_geo_lng: Yup.string(),
  }),

  // uploadDocs: Yup.object().shape({
  //   registrationCertificate: Yup.mixed().required(
  //     "Registration Certificate is required"
  //   ),
  //   hospitalLogo: Yup.mixed().required("Hospital Logo is required"),
  // }),
});

function RegisterFacility(props) {
  const { phoneNumber } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hospitalid = params.get("hosp_id");

  const { mode } = useContext(ModeContext);

  const [formValues, setFormValues] = useState(null);
  const [isShortNameValid, setIsShortNameValid] = useState(false);
  const [isFielddisable, setisFielddisable] = useState(true);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [hospitalId, setHospitalId] = useState(null); //when user click on update button then hospital id is set
//   const selectedCountryCodes = sessionStorage.getItem("selectedCountryCod");
//   const selectedCountryCod1 = sessionStorage.getItem("selectedCountryCod1");

// console.log(999887, selectedCountryCodes,selectedCountryCod1 )
  const [formNavItems, setFormNavItems] = useState([
    {
      label: "Facility Type",
      identifier: "facilityType",
      isActive: true,
      element: Facilitytype,
      elementProps: {
        setIsShortNameValid: setIsShortNameValid,
        hospitalid,
      },

      elementWrapperClassName: "min-w-50",
      formHeading:
        "Please provide the details of the Facility (Hospital/Clinic/Care Facility) to be registered",
    },
    {
      label: "Facility Details",
      identifier: "facilityDetails",
      isActive: false,
      // element: <FacilityDetails />,
      element: FacilityDetails,
      elementProps: {
        setIsShortNameValid: setIsShortNameValid,
        hospitalid,
      },
      elementWrapperClassName: "min-w-50",
      formHeading:
        "Please provide the details of the Facility (Hospital/Clinic) to be registered.",
    },
    {
      label: "Location Details",
      identifier: "locationdetails",
      isActive: false,
      // element: <RegistrationDocs />,
      element: Locationdetail,
      elementWrapperClassName: "min-w-50",
      formHeading:
        "Please upload the Facility (or Doctor's) Registration Certificate and Logo (Brand).",
    },
    {
      label: "Upload Proofs & Submit",
      identifier: "uploadDocs",
      isActive: false,
      // element: <RegistrationDocs />,
      element: RegistrationDocs,
      elementProps:{
        hospitalid,
      },
      elementWrapperClassName: "min-w-50",
      formHeading:
        "Please upload the Facility (or Doctor's) Registration Certificate and Logo (Brand).",
    },
  ]);
  const [isHosInitialValLoading, setHosInitialValLoading] = useState(true);

  const { currentHospital, isLoading } = useContext(HospitalsContext);
  const [callEffect, setCallEffect] = useState(true);

  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  
  useEffect(() => {
    if (hospitalId)
      if (dirty === true) {
        setIsEditModeOn(false);
      } else {
        setIsEditModeOn(true);
      }
  }, [dirty]);


  useEffect(() => {
    if (searchParams.get("edit")) {
      const toBeEditedHospId = searchParams.get("hosp_id");

      const fetchHospitalData = async () => {
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FECTH_HOS_REGISTRATION +
              "/" +
              toBeEditedHospId
          );
          const regis2res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FETCH_REGISTRATION_STEP2 +
              "/" +
              toBeEditedHospId
          );
          const regis3res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FETCH_REGISTRATION_STEP3 +
              "/" +
              toBeEditedHospId
          );

          if (res && regis2res && regis3res) {
            const toBeEditedHospData = {
                resData: res.data,
                regis2resData: regis2res.data,
                regis3resData: regis3res.data,
            };


            setFormValues({
              facilityType: {
               facility_type: toBeEditedHospData?.resData?.facility_type || "",
                facility_name: toBeEditedHospData?.resData?.hosp_name || "",
                short_name: toBeEditedHospData?.resData?.short_name || "",
                facility_description: toBeEditedHospData?.resData?.facility_description || "",
              },

              facilityDetails: {
                
                hosp_accreditation_by: {
                  label: toBeEditedHospData?.regis2resData?.hosp_accreditation_by|| "",
                  value: toBeEditedHospData?.regis2resData?.hosp_accreditation_by || "",
                },
                hosp_registration_no: toBeEditedHospData?.regis2resData?.hosp_registration_no|| "",
                // bedsCount: toBeEditedHospData.beds_count,
                email: toBeEditedHospData?.regis2resData?.email|| "",
                phone1: toBeEditedHospData?.regis2resData?.phone1?.replace("")||"",
                phone2: toBeEditedHospData?.regis2resData?.phone2
                  ? toBeEditedHospData?.regis2resData?.phone2?.replace("")
                  : "",

                gstin: toBeEditedHospData?.regis2resData.gstin ? toBeEditedHospData?.regis2resData.gstin : "",
                gstin_legal_name: toBeEditedHospData?.regis2resData?.gstin_legal_name
                  ? toBeEditedHospData?.regis2resData?.gstin_legal_name
                  : "",
                gstin_trade_name: toBeEditedHospData?.regis2resData?.gstin_trade_name
                  ? toBeEditedHospData?.regis2resData?.gstin_trade_name
                  : "",
              },
              locationdetails: {
                address: toBeEditedHospData?.regis3resData?.address||"",
                pincode: toBeEditedHospData?.regis3resData?.pincode||"",
                country: { 
                label: toBeEditedHospData?.regis3resData?.country||"",
                value: toBeEditedHospData?.regis3resData?.country||"",
                },
                state:{
                    label:toBeEditedHospData?.regis3resData?.state||"",
                    value:toBeEditedHospData?.regis3resData?.state||"",
                }, 
                city: {
                  label: toBeEditedHospData?.regis3resData?.city||"",
                  value: toBeEditedHospData?.regis3resData?.city||"",
                },
                google_place_id: toBeEditedHospData?.regis3resData?.google_place_id||"",
                google_geo_lat: toBeEditedHospData?.regis3resData?.google_geo_lat||"",
                google_geo_lng: toBeEditedHospData?.regis3resData?.google_geo_lng||"",
              },

              // uploadDocs: {
              //   registrationCertificate: "",
              //   hospitalLogo: "",
              // },
            });

            setHospitalId(toBeEditedHospId);
            setIsEditModeOn(true);
          } else {
            throw new Error("Something went wrong. Please try later.");
          }
          
        } catch (error) {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error.message);
          setErrorOccurred(true);
          
        } finally {
          setHosInitialValLoading(false);
        }
      };

      fetchHospitalData();
    } else {
      setFormValues({
        facilityType: {
          facility_type: "",
          facility_name: "",
          short_name: "",
          facility_description: "",
        },
        facilityDetails: {
          hosp_accreditation_by: "",
          hosp_registration_no: "",
          // bedsCount: "0",
          email: "",
          phone1: "",
          phone2: "",
          
          gstin: "",
          gstin_legal_name: "",
          gstin_trade_name: "",
        },
        locationdetails: {
          address: "",
          pincode: "",
          country: "",
          state: "",
          city: "",
          google_place_id: "",
          google_geo_lat: "",
          google_geo_lng: "",
        },

        // uploadDocs: {
        //   registrationCertificate: "",
        //   hospitalLogo: "",
        // },
      });
      setHosInitialValLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!showToast && errorOccurred) {
      navigate("/app/facilities/OPD", { replace: true });
    }
  }, [showToast, errorOccurred]);

  // console.log(56565656, hospitalId, hospitalid)


  // const handleSaveBtnClick4 = async (
  //   values,
  //   currentItemNumber,
  //   setFieldError
  // ) => {
  //   const userKeys = localStorage.getItem("usr_keys");
  //   const userModeKey = JSON.parse(userKeys)[mode];
  //   const key = userModeKey[`${mode}_key`];
  //   const secret = userModeKey[`${mode}_secret`];

  //   const body = {
  //     // hosp_name: values.hospitalName,
  //     // short_name: values.shortName,
  //     hosp_accreditation_by: values.hosp_accreditation_by.value.toUpperCase(),
  //     hosp_registration_no: values.hosp_registration_no,
  //     email: values.email,
  //     // pincode: values.pincode,
  //     // city: values.city.value,
  //     // state: values.state.value,
  //     // country: values.country.value,
  //     // google_place_id: values.google_place_id
  //     //   ? values.google_place_id
  //     //   : undefined,
  //     // google_geo_lat: values.google_geo_lat ? values.google_geo_lat : undefined,
  //     // google_geo_lng: values.google_geo_lng ? values.google_geo_lng : undefined,
  //     gstin: values.gstin ? values.gstin : undefined,
  //     gstin_legal_name: values.gstin_legal_name
  //       ? values.gstin_legal_name
  //       : undefined,
  //     gstin_trade_name: values.gstin_trade_name
  //       ? values.gstin_trade_name
  //       : undefined,
  //     // beds_count: values.bedsCount !== "" ? values.bedsCount : "0",
  //     phone1: "+91" + values.phone1,
  //     phone2: values.phone2 ? "+91" + values.phone2 : "",
  //     // address: values.address,
  //   };

  //   const body1 = {
  //     facility_type: values.facility_type,
  //     facility_name: values.facility_name,
  //     short_name: values.short_name,
  //     facility_description: values.facility_description,
  //   };
  //   const updateparams = {
  //     hosp_name: values.hospitalName,
  //     email: values.email,
  //     country: values.country.value,
  //     city: values.city.value,
  //     state: values.state.value,
  //     pincode: values.pincode,
  //     google_place_id: values.google_place_id,
  //     google_geo_lat: values.google_geo_lat,
  //     google_geo_lng: values.google_geo_lng,
  //     gstin: values.gstin,
  //     gstin_legal_name: values.gstin_legal_name,
  //     gstin_trade_name: values.gstin_trade_name,
  //     beds_count: values.bedsCount !== "" ? values.bedsCount : "0",
  //     phone1: "+91" + values.phone1,
  //     phone2: values.phone2 ? "+91" + values.phone2 : "",
  //     address: values.address,
  //   };
  //   try {
  //     //conditionally calling api if hospitalid is then call update api
  //     if (hospitalId) {
  //       rxOpdApi.setAuthHeaders(key, secret);
  //       const res = await rxOpdApi.put(
  //         RX_OPD_ENDPOINTS.HOSPITAL.UPDATE_HOSPITAL + "/" + hospitalId,
  //         updateparams
  //       );
  //       if (res) {
  //         return { success: true, message: res.data.message };
  //       } else {
  //         throw new Error("Something went wrong. Please try later.");
  //       }
  //     } else {
  //       const res = await rxOpdApi.post(
  //         // RX_OPD_ENDPOINTS.HOSPITAL.REGISTER_DOCTOR,
  //         RX_OPD_ENDPOINTS.HOSPITAL.REGISTRATION_STEP_1,
  //         body1
  //       );
  //       if (res) {
  //         setHospitalId(res.data.hos_id);
  //         if (!isShortNameValid) {
  //           setFieldError(
  //             "facilityType.shortName",
  //             "Entered Short Name is already in use. Please use another one."
  //           );

  //           const dataMessageSmall = document.querySelector(".resMessageSmall");
  //           dataMessageSmall?.remove();

  //           return {
  //             success: false,
  //             message:
  //               "Entered Short Name is already in use. Please use another one.",
  //           };
  //         }
  //         return { success: true, message: res.data.message };
  //       } else {
  //         throw new Error("Something went wrong. Please try later.");
  //       }
  //     }
  //   } catch (error) {
  //     return { success: false, message: error.message };
  //   }
  // };

  const handleSaveBtnClick = async (values, itemNumber, setFieldError) => {
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys && !isLoading) {

    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];

    try {
      switch (itemNumber) {
        case 1:
          // if(isEditModeOn) {
          //   return { success: true };

          // }else{ 
          const body = {
            facility_type: values?.facility_type||"",
            facility_name: values?.facility_name||"",
            short_name: values?.short_name||"",
            facility_description: values?.facility_description||"",
          };
          const res1 = await rxOpdApi.post(
            RX_OPD_ENDPOINTS.HOSPITAL.REGISTRATION_STEP_1,
            body
          );
          if (res1) {
            setHospitalId(res1.data.hos_id);
            params.set('hosp_id', res1.data.hos_id);
            navigate(`?${params.toString()}`, { replace: true });
            
            if (!isShortNameValid) {
              setFieldError(
                "facilityType.shortName",
                "Entered Short Name is already in use. Please use another one."
              );
              const dataMessageSmall =
                document.querySelector(".resMessageSmall");
              dataMessageSmall?.remove();
              return {
                success: false,
                message:
                  "Entered Short Name is already in use. Please use another one.",
              };
            }
            return { success: true, message: res1.data.message };
          // }
        }
          break;

        case 2: {
          // if(isEditModeOn) {
          //   return { success: true };

          // }else{ 


            const body2 = {
              hosp_accreditation_by:
                values?.hosp_accreditation_by?.value?.toUpperCase() || "",
              hosp_registration_no: values?.hosp_registration_no || "",
              email: values?.email || "",
              phone1: (sessionStorage.getItem("selectedCountryCod") || '') + values?.phone1 || "",
              phone2: (sessionStorage.getItem("selectedCountryCod1") || '') + values?.phone2 || "",
              gstin: values.gstin ? values.gstin : undefined,
              gstin_legal_name: values?.gstin_legal_name || "",
              gstin_trade_name: values?.gstin_trade_name || "",
            };
            const res = await rxOpdApi.put(
              RX_OPD_ENDPOINTS.HOSPITAL.REGISTRATION_STEP_2 + "/" + hospitalId,
              body2,
              { headers: { "Content-Type": "application/json" } }
            );
            if (res) {
              return { success: true, message: res.data.message };
            }
          // }
        }
          break;

        case 3:
          // if(isEditModeOn) {
          //   return { success: true };

          // }else{ 
          const body3 = {
            address: values?.address || "",
            pincode: values?.pincode || "",
            country: values?.country?.label || "",
            state: values?.state?.label || "",
            city: values?.city?.label || "",
            ...(values.google_place_id && { google_place_id: values.google_place_id }),
            google_geo_lat: values?.google_geo_lat || "",
            google_geo_lng: values?.google_geo_lng || "",
          };
          
          const res3 = await rxOpdApi.put(
            RX_OPD_ENDPOINTS.HOSPITAL.REGISTRATION_STEP_3 + "/" + hospitalId,
            body3,
            { headers: { "Content-Type": "application/json" } }
          );
          if (res3) {
            return { success: true, message: res3.data.message };
          }
        // }
          break;

        // default:
        //   throw new Error("Invalid case");
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  }
  };

  const handleSubmit = async (values) => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];

    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];

    try {
      // const hospitalRegDocsFormData = new FormData();
      // hospitalRegDocsFormData.append(
        // "uploadDocs"      );
      // hospitalRegDocsFormData.append(
      //   "registration_certificate",
      //   values.uploadDocs.registrationCertificate
      // );

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.post(
        RX_OPD_ENDPOINTS.HOSPITAL.DOCU_SUBMISSION_STEP4 + "/" + hospitalId,
        
      );

      if (res) {
        return { success: true, message: res.data.message };
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  };



  return (
    <>
      <Form
        type={hospitalId ?  "Update Facility":"Register Facility"}
        initialValues={formValues}
        validationSchema={validationSchema}
        formNavItems={formNavItems}
        setFormNavItems={setFormNavItems}
        onSaveBtnClick={handleSaveBtnClick}
        onSubmit={handleSubmit}
        isLoading={isLoading || isHosInitialValLoading}
        areDocsSubmitted={isEditModeOn ? isEditModeOn : false}
        redirectUrl="/app/facilities/list"
      />

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default RegisterFacility;
