import React from "react";
import { Label } from "../../common";
import PharmacyCard from "./PharmacyCard";

const Pharmacies = ({ pharmacies, name }) => {
  return (
    <div>
      <Label fontWeight="bold" className="p-3 pb-0" variant="h5">
        {name}
      </Label>
      {pharmacies.map((pharmacy, index) => (
        <PharmacyCard key={index} pharmacy={pharmacy} />
      ))}
    </div>
  );
};

export default Pharmacies;
