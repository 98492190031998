import { useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, IconButton } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SendIcon from "@mui/icons-material/Send";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import Button from "../../../ui/Button";
import TickerTextsBox from "./TickerTextsBox";
import { convertDataForPublishing } from "../../../../utils/web-app";
import OfferedServices from "./OfferedServices";
import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";

import styles from "./WebAppCurrent.module.css";
import { InputTextAreaElement, InputTextElement } from "../common";
import Map from "./Map";
import { Label } from "../../common";

const initialValues = {
  facility_name: "",
  facility_address: "",
  google_place_id: "",
  ticker_text_list: [],
  facility_welcome_message: "",
  facebook_link: "",
  linkedin_link: "",
  twitter_link: "",
  youtube_link: "",
  enable_consultation: false,
  doc_id_list: [],
  enable_pharmacy: false,
  ph_id_list: [],
  enable_lab: false,
  lab_id_list: [],
};


const limitedSpecialCharactersForFacilityName = /[^0-9A-Za-z-() ]/gi;

const validate = (values) => {
  const errors = {};

  if (!values.facility_name) {
    errors.facility_name = "Required";
  } else if (values.facility_name.length <= 3) {
    errors.facility_name = "Must be 3 characters or more";
  }

  if (!values.facility_address.length) {
    errors.facility_address = "Required";
  }

  if (!values.google_place_id.length) {
    errors.google_place_id = "Required";
  }
  // else if (values.facility_address.length <= 20) {
  //   errors.facility_address = "Must be 20 characters or more";
  // }

  if (!values.facility_welcome_message) {
    errors.facility_welcome_message = "Required";
  } else if (values.facility_welcome_message.length <= 20) {
    errors.facility_welcome_message = "Must be 20 characters or more";
  }

  if (values.enable_consultation && !values.doc_id_list.length) {
    errors.doc_id_list = "Required";
  }

  if (values.enable_pharmacy && !values.ph_id_list.length) {
    errors.ph_id_list = "Required";
  }

  if (values.enable_lab && !values.lab_id_list.length) {
    errors.lab_id_list = "Required";
  }

  return errors;
};

const WebAppCurrent = ({
  currentData,
  facility_logo,
  facility_id,
  facilityImages,
  fetchFacilityDoctors,
  fetchFacilityPharmacies,
  fetchFacilityPathLabs,
  handlePublish,
  handleShowSuccessfullyPubishModal,
  handleBrandLogoUpdate,
  handleAddFacilityImage,
  handleDeleteFacilityImage,
}) => {
  // const [errorDetails, setErrorDetails] = useState({});
  const [tickerText, setTickerText] = useState("");
  const [isDataBeingSaved, setIsDataBeingSaved] = useState(false);
  const [isLogoUpdateLoading, setIsLogoUpdateLoading] = useState(false);
  const [facImages, setFacImages] = useState([]);

  const [showFacilityImageDeleteModal, setShowFacilityImageDeleteModal] =
    useState(false);
  const [facilityImageDeleteName, setFacilityImageDeleteName] = useState("");
  const [isFacilityImageBeingSaved, setIsFacilityImageBeingSaved] =
    useState(false);

  const [googleMapValues, setGoogleMapValues] = useState({
    google_place_id: "",
    facility_address: "",
  });



  const [showMapModal, setShowMapModal] = useState(false);

  const logoImageRef = useRef(null);
  const facilityImageRef = useRef(null);

  const formik = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: async (values) => {
      setIsDataBeingSaved(true);
      handlePublish(values)
        .then(() => {
          handleShowSuccessfullyPubishModal();
          setIsDataBeingSaved(false);
        })
        .catch((error) => {
          console.error(error);
          setIsDataBeingSaved(false);
        });
    },
  });
  const {
    values: currentValues,
    setValues: setCurrentValues,
    errors: errorDetails,
    handleBlur,
    touched,
  } = formik;

  const updateGoogleMapValues = (values) => {
    setGoogleMapValues((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const handleCloseFacilityImageDeleteModal = () => {
    setFacilityImageDeleteName("");
    setShowFacilityImageDeleteModal(false);
    setIsFacilityImageBeingSaved(false);
  };

  const handleShowFacilityImageDeleteModal = (name) => {
    setFacilityImageDeleteName(name);
    setShowFacilityImageDeleteModal(true);
  };

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleShowMapModal = (name) => {
    setShowMapModal(true);
  };

  useEffect(() => {
    if (Object.keys(currentData).length) {
      const publishData = convertDataForPublishing(currentData);
      setCurrentValues(publishData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    if (
      facilityImages &&
      Array.isArray(facilityImages) &&
      facilityImages.length
    ) {
      setFacImages(facilityImages);
    }
  }, [facilityImages]);

  const handleOnChange = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "facility_name":
        value = value.replace(limitedSpecialCharactersForFacilityName, "");
        setCurrentValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
      case "facility_address":
      case "facility_welcome_message":
      case "twitter_link":
      case "youtube_link":
      case "facebook_link":
      case "linkedin_link":
      case "enable_consultation":
      case "doc_id_list":
      case "enable_pharmacy":
      case "ph_id_list":
      case "enable_lab":
      case "lab_id_list":
        setCurrentValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleOnTickerTextChange = (e) => {
    setTickerText(e.target.value);
  };

  const handleTickerSubmit = () => {
    if (tickerText) {
      setCurrentValues((prevState) => {
        const currentTickerText = [...prevState.ticker_text_list];
        currentTickerText.unshift(tickerText);

        return {
          ...prevState,
          ticker_text_list: currentTickerText,
        };
      });

      setTickerText("");
    }
  };

  const handleRemoveTickerText = (index) => {
    setCurrentValues((prevState) => {
      const clonedTickerTextArray = [...prevState.ticker_text_list];
      clonedTickerTextArray.splice(index, 1);

      return {
        ...prevState,
        ticker_text_list: clonedTickerTextArray,
      };
    });
  };

  const handleImgClick = () => {
    if (logoImageRef?.current?.click) {
      logoImageRef.current.click();
    }
  };

  const handleLogoUpload = async (e) => {
    const updatingLogo = e?.currentTarget?.files[0];
    if (!updatingLogo) {
      return;
    }

    setIsLogoUpdateLoading(true);

    const formData = new FormData();
    formData.append("facility_logo", updatingLogo);
    await handleBrandLogoUpdate(formData);

    setIsLogoUpdateLoading(false);
  };

  const handleFacilityImgClick = () => {
    if (facilityImageRef?.current?.click) {
      facilityImageRef.current.click();
    }
  };

  const handleFacilityImageUpload = async (e) => {
    const updatingFacilityImage = e?.currentTarget?.files[0];
    if (!updatingFacilityImage) {
      return;
    }

    setFacImages((prevState) => [...prevState, { isLoading: true }]);

    const formData = new FormData();
    formData.append("pic", updatingFacilityImage);
    await handleAddFacilityImage(formData);
  };

  const handleFacilityImageDelete = async () => {
    setIsFacilityImageBeingSaved(true);

    await handleDeleteFacilityImage(facilityImageDeleteName);
    handleCloseFacilityImageDeleteModal();
  };

  const handleUpdateGoogleMapPlace = () => {
    if (googleMapValues.facility_address && googleMapValues.google_place_id) {
      formik.setFieldValue(
        "facility_address",
        googleMapValues.facility_address
        );
        formik.setFieldValue("google_place_id", googleMapValues.google_place_id);
        handleCloseMapModal();
      }
    };

  return (
    <>
      <Grid item lg={6} md={12} sm={12} className="p-4">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item lg={2} md={2} sm={3} xs={3}>
                <div className={styles.facilityLogoWrapper}>
                  <input
                    type="file"
                    onChange={handleLogoUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={logoImageRef}
                  />
                  {facility_logo && !isLogoUpdateLoading ? (
                    <img
                      src={
                        facility_logo + "?v=" + Math.random() * Math.random()
                      }
                      alt="Facility Logo"
                      className="border border-4 border-primary"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={handleImgClick}
                    />
                  ) : (
                    <div
                      className="border border-4 border-primary"
                      style={{
                        width: "90px",
                        height: "90px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "0.5rem",
                      }}
                      onClick={handleImgClick}
                    >
                      {isLogoUpdateLoading ? (
                        <CircularProgress sx={{ color: "#00b0f0" }} />
                      ) : (
                        <AddIcon sx={{ color: "red", fontSize: "30px" }} />
                      )}
                    </div>
                  )}
                  <div className={styles.removeFacilityImage}>
                    <IconButton
                      onClick={handleImgClick}
                      className={styles.facilityLogoIcon}
                    >
                      <AutorenewIcon fontSize="large" />
                    </IconButton>
                  </div>
                </div>
              </Grid>
              <Grid item lg={9.75} md={9.75} sm={8.5} xs={8.75}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <InputTextElement
                      placeholder="Facility Name"
                      name="facility_name"
                      value={currentValues.facility_name}
                      onChange={handleOnChange}
                      onBlur={handleBlur}
                      error={
                        touched.facility_name
                          ? errorDetails.facility_name
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item lg={11} md={11} xs={11}>
                        <InputTextElement
                          disabled={true}
                          name="facility_address"
                          value={currentValues.facility_address}
                          placeholder="Address"
                          onChange={handleOnChange}
                          onBlur={handleBlur}
                          error={
                            touched.facility_address
                              ? errorDetails.facility_address
                              : false
                          }
                        />
                      </Grid>
                      <Grid item lg={1} md={1} xs={1}>
                        <IconButton
                          onClick={handleShowMapModal}
                          sx={{
                            ...(touched.google_place_id &&
                            errorDetails.google_place_id
                              ? {
                                  border: "0.5px solid red",
                                }
                              : {}),
                          }}
                        >
                          <AddLocationIcon
                            sx={{
                              color: "#e62625",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item lg={11} md={11} xs={10}>
                    <InputTextElement
                      placeholder="Add Ticker text"
                      variant="standard"
                      value={tickerText}
                      onChange={handleOnTickerTextChange}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={!tickerText.length}
                      onClick={handleTickerSubmit}
                    >
                      <SendIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TickerTextsBox
                  ticker_texts={currentValues.ticker_text_list}
                  handleRemoveTickerText={handleRemoveTickerText}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <Label fontWeight="bold">Welcome message</Label>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputTextAreaElement
                  value={currentValues.facility_welcome_message}
                  name="facility_welcome_message"
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  error={
                    touched.facility_welcome_message
                      ? errorDetails.facility_welcome_message
                      : false
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <Label fontWeight="bold">Social Links:</Label>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        lg={1}
                        md={1}
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FacebookIcon
                          fontSize="large"
                          style={{
                            color: "#4267B2",
                          }}
                        />
                      </Grid>
                      <Grid item lg={11} md={11} xs={10}>
                        <InputTextElement
                          name="facebook_link"
                          value={currentValues.facebook_link}
                          placeholder="Insert Facebook Page URL"
                          onChange={handleOnChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        lg={1}
                        md={1}
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LinkedInIcon
                          fontSize="large"
                          style={{
                            color: "#0072b1",
                          }}
                        />
                      </Grid>
                      <Grid item lg={11} md={11} xs={10}>
                        <InputTextElement
                          name="linkedin_link"
                          value={currentValues.linkedin_link}
                          placeholder="Insert LinkedIn Page URL"
                          onChange={handleOnChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        lg={1}
                        md={1}
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TwitterIcon
                          fontSize="large"
                          style={{
                            color: "#00acee",
                          }}
                        />
                      </Grid>
                      <Grid item lg={11} md={11} xs={10}>
                        <InputTextElement
                          name="twitter_link"
                          value={currentValues.twitter_link}
                          placeholder="Insert X (Twitter) Page URL"
                          onChange={handleOnChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        lg={1}
                        md={1}
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <YouTubeIcon
                          fontSize="large"
                          style={{
                            color: "#CD201F",
                          }}
                        />
                      </Grid>
                      <Grid item lg={11} md={11} xs={10}>
                        <InputTextElement
                          name="youtube_link"
                          value={currentValues.youtube_link}
                          placeholder="Insert YouTube URL"
                          onChange={handleOnChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <Label fontWeight="bold">Facility Images:</Label>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div
                  className={styles.facilityImagesWrapper}
                  // style={{
                  //   width: document.documentElement.clientWidth || "100%",
                  // }}
                >
                  {facImages && Array.isArray(facImages) && facImages.length ? (
                    <div className={styles.facilityImages}>
                      {facImages
                        .slice(0, facImages.length - 1)
                        .sort(function (a, b) {
                          return a.pic_name
                            .toLowerCase()
                            .localeCompare(b.pic_name.toLowerCase());
                          })
                          .map((image, index) =>
                          image.url ? (
                            <span
                            className={styles.facilityImageContainer}
                            key={index}
                            >
                              <img
                                src={
                                  image.url +
                                  "?v=" +
                                  Math.random() * Math.random()
                                }
                                alt="Facility images"
                                className={`border border-4 border-primary ${styles.facilityImage}`}
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  borderRadius: "0.5rem",
                                  marginBottom: "1px",
                                  marginTop: "10px",
                                }}
                                key={index}
                                />
                              <div className={styles.removeFacilityImage}>
                                <IconButton
                                  onClick={() =>
                                    handleShowFacilityImageDeleteModal(
                                      image.pic_name
                                      )
                                    }
                                    >
                                  <RemoveIcon
                                    fontSize="large"
                                    sx={{ color: "red" }}
                                    />
                                </IconButton>
                              </div>
                            </span>
                          ) : image.isLoading ? (
                            <div
                              style={{
                                minWidth: "90px",
                                height: "90px",
                                border: "1px solid red",
                                borderRadius: "0.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "15px",
                                marginBottom: "1px",
                                marginTop: "10px",
                              }}
                              onClick={(event) =>{
                                handleImgClick()}}
                            >
                              <CircularProgress
                                size="20px"
                                sx={{ color: "#00b0f0" }}
                              />
                            </div>
                          ) : null
                        )}
                      {facImages[facImages.length - 1]?.url ? (
                        <img
                          src={
                            facImages[facImages.length - 1].url +
                            "?v=" +
                            Math.random() * Math.random()
                          }
                          alt="Facility images"
                          className="border border-4 border-primary"
                          style={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "0.5rem",
                            marginRight: "15px",
                            marginBottom: "1px",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            handleFacilityImgClick();
                          }}
                                                />
                      ) : null}
                      <input
                        type="file"
                        onChange={handleFacilityImageUpload}
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={facilityImageRef}
                      />
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <Label fontWeight="bold">Offered Services:</Label>
              </Grid>
              <OfferedServices
                currentValues={currentValues}
                fetchFacilityDoctors={fetchFacilityDoctors}
                fetchFacilityPharmacies={fetchFacilityPharmacies}
                fetchFacilityPathLabs={fetchFacilityPathLabs}
                handleOnChange={handleOnChange}
                errorDetails={errorDetails}
                handleBlur={handleBlur}
                touched={touched}
                key={currentValues.facility_name}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    disabled={isDataBeingSaved}
                    type="submit"
                    onClick={formik.handleSubmit}
                    style={{
                      backgroundColor: "rgb(77, 154, 166)",
                      border: "none",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    {isDataBeingSaved ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mx-3"
                      />
                    ) : (
                      "PUBLISH"
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        show={showFacilityImageDeleteModal}
        size="md"
        aria-labelledby="custom-modal"
        centered
        contentClassName="border-0"
        onHide={handleCloseFacilityImageDeleteModal}
      >
        <Modal.Header
          closeButton
          className="border-0 fs-5 text-white"
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-3">
          Do you want to delete this image?
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFacilityImageBeingSaved}
            onClick={handleFacilityImageDelete}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            {isFacilityImageBeingSaved ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mx-3"
              />
            ) : (
              "Yes"
            )}
          </Button>
          <Button
            disabled={isFacilityImageBeingSaved}
            onClick={handleCloseFacilityImageDeleteModal}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showMapModal}
        size="lg"
        aria-labelledby="custom-modal"
        centered
        contentClassName="border-0"
        onHide={handleCloseMapModal}
      >
        <Modal.Header
          closeButton
          className="border-0 fs-5 text-white"
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-3">
          <Map
            currentValues={currentValues}
            updateGoogleMapValues={updateGoogleMapValues}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleUpdateGoogleMapPlace}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WebAppCurrent;
