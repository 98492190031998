import { useState } from "react";
import AppointmentsTab from "../components/opd/AppointmentsTab";
import AppointmentsTab2 from "../components/opd/AppointmentsTab2";
import InvoicesTab from "../components/opd/InvoicesTab";
import OrdersTab from "../components/opd/OrdersTab";
import PaymentsTab from "../components/opd/PaymentsTab";
import TrendsTab from "../components/opd/appointments-form/TrendsTab";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import PageContentRoutes from "../utils/app-content-routes";
import OrdersModal from './../components/opd/OrdersModal';

import DisplayOrder from "../components/opd/DisplayOrder";
import AddOrderForm from "../components/opd/AddOrderForm";


function Opd() {
  const [showOrderDetails,setShowOrderDetails]=useState(false)
  const [orderData,setOrderData]=useState({})
  const opdRoutes = [
    { path: "appointments", component: <AppointmentsTab2 /> },
    // {
    //   path: "payments",
    //   component: <PaymentsTab />,
    // },
    // { path: "Orders", component: showOrderDetails?<OrdersModal sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/>:<OrdersTab sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/> },
    // { path: "invoices", component: <InvoicesTab /> },
    // { path: "Trends", component: <TrendsTab /> },
  ];
  function openOrderDetails(open){
    setShowOrderDetails(open)
  }
  function  sendOrderData(orderId,category){
    setOrderData(orderId,category)
  }
 
  return (
    <PageContentLayout>
      <PageNav routes={opdRoutes} />
      
      <PageContentRoutes routes={[...opdRoutes]} />
        
        {/* { path: "orders/details", component: <DisplayOrder/> }, { path: "orders/create", component: <AddOrderForm /> } */}

    
    </PageContentLayout>
  );
}

export default Opd;
