import { useState, useEffect } from "react";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import OrdersModal from "../components/opd/OrdersModal";
import PageContentRoutes from "../utils/app-content-routes";
import DisplayOrder from "../components/opd/DisplayOrder";
import InvoicesTab from "../components/opd/InvoicesTab";
import TrendsTab from "../components/opd/appointments-form/TrendsTab";
import OrdersTab from "../components/opd/OrdersTab";
import AddOrderForm from "../components/opd/AddOrderForm";
function Billing() {
  const [showOrderDetails,setShowOrderDetails]=useState(false)
  const [orderData,setOrderData]=useState({})
  const billingRoute = [
    { path: "Orders", component: showOrderDetails?<OrdersModal sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/>:<OrdersTab sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/> },
    { path: "invoices", component: <InvoicesTab /> },
    { path: "Trends", component: <TrendsTab /> },
  ];
  function openOrderDetails(open){
    setShowOrderDetails(open)
  }
  function  sendOrderData(orderId,category){
    setOrderData(orderId,category)
  }

  return <PageContentLayout>
<PageNav routes={billingRoute}/>
<PageContentRoutes routes={[...billingRoute,{ path: "orders/details", component: <DisplayOrder/> }, { path: "orders/create", component: <AddOrderForm /> }]} />

  </PageContentLayout>;
}

export default Billing;
