import Modal from "react-bootstrap/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BookletModal = ({ isOpen, onClose, pdfData }) => {

  return (
    <Modal show={isOpen} onHide={onClose} size="lg" centered>
      <div className="booklet-modal-content">
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
        
        <div className="pdf-container">
          <object
            data={pdfData}
            type="application/pdf"
            className="pdf-object"
          >
            An error occurred while fetching the file, please try again later
          </object>
        </div>
      </div>
      <style jsx>{`
        .booklet-modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 8px;
          position: relative;
          width: 100%;
          height: 90vh;
          max-width: 900px;
          margin: 0 auto;
        }
        
        .close-button {
          position: absolute !important;
          top: -9px;
          right: 10px;
          color: black !important;
          z-index: 1000;
        }
        
        .pdf-container {
          height: calc(100% - 40px);
          width: 100%;
        }
        
        .pdf-object {
          width: 100%;
          height: 100%;
        }
        
        @media (max-width: 768px) {
          .booklet-modal-content {
            height: 100vh;
            padding: 10px;
          }
          
          .close-button {
            top: -11px;
            right: 0px;
          }
          
          .pdf-container {
            height: calc(100% - 20px);
          }
        }
      `}</style>
    </Modal>
  );
};

export default BookletModal;