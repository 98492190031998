import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../ui/Button";
import { rxOpdApi } from "../../utils/api/api";
import HospitalsContext from "../../context/hospitals-context";
import ModeContext from "../../context/mode-context";
import { useSearchParams } from "react-router-dom";
// import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import Toast from "../ui/Toast";
import Prompt from "../ui/Prompt";
const DoctorDetailsModal = ({
  doctor,
  onEdit,
  operation,
  onHide,
  doctorAllDetails,
}) => {
  // console.log(5555555, doctorAllDetails);
  const initializeStateFromDoctor = (doctor) => {
    // console.log("2323223", doctor);
    return {
      email: doctor.email || "",
      phone: doctor.phone || "",
      firstName: doctor.firstname || "",
      lastName: doctor.lastname || "",
      qualification: doctor.qualification || "",
      speciality: doctor.speciality || "",
      gender: doctor.gender || "",
      title: doctor.title || "",
      primary_specialization: doctor.primary_specialization || "",
      regYear: doctor.rmp_reg_year || "", // Map rmp_reg_year
      birthYear: doctor.rmp_birth_year || "", // Map birthYear
      degreeYear: doctor.rmp_degree_year || "", // Map rmp_degree_year
      accreditationby: doctor.rmp_accred_by || "",
      rmpNo: doctor.rmp_num || "", // Map rmp_num
      system_of_medicine: doctor.system_of_medicine || "", // Map system_of_medicine
      consultCharges: doctor.consult_charge || "",
      currency: doctor.currency || "",
      regDate: doctor.reg_date || "",
      regSubmitted: doctor.reg_submitted || false,
      regSubmittedOn: doctor.reg_submitted_on || "",
      onlineDiscount: doctor.online_discount || "",
      marketplaceFlag: doctor.marketplace_flag || false,
      availability: doctor.availability || false,
    };
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [editedDoctor, setEditedDoctor] = useState(
    initializeStateFromDoctor(doctor)
  );
  const [deletingDoctorId, setDeletingDoctorId] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const { mode } = useContext(ModeContext);
  const toBeEditedDocId = searchParams.get("doc_id");
  const { currentHospital, isLoading } = useContext(HospitalsContext);
  const [doctorId, setDoctorId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isDoctorBeingDeleted, setIsDoctorBeingDeleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const userKeys = localStorage.getItem("usr_keys");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDoctor((prevDoctor) => ({ ...prevDoctor, [name]: value }));
  };

  const fetchRegisteredDoctors = async () => {
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
  
    try {
      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS.HOSPITAL.ALL_DOCTORS}/${currentHospital.hos_id}`
      );
      // Do something with the response
      return res;
    } catch (err) {
      console.error('Error fetching registered doctors:', err);
      // Handle the error appropriately
      throw err;
    }
  };

  const handleSaveChanges = async () => {
    try {
      // Prepare the body for the API request based on editedDoctor state
      const body = {
        phone: editedDoctor.phone,
        email: editedDoctor.email,
        firstname: editedDoctor.firstName,
        lastname: editedDoctor.lastName,
        qualification: editedDoctor.qualification,
        speciality: editedDoctor.speciality,
      };

      // Call the API endpoint based on the operation (add/update)

      // Update the endpoint and handle the PUT request for updating doctor details
      let endPoint =
        RX_OPD_ENDPOINTS.HOSPITAL.UPDATE_DOCTOR +
        "/" +
        currentHospital.hos_id +
        "/" +
        doctorAllDetails.doc_id;

      const res = await rxOpdApi.put(endPoint, body);

      // Handle state updates based on API response
      setDoctorId(res.data.doc_id);
      setModalMessage(res.data.message);
      setModalVisible(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return { success: true, message: res.data.message };
    } catch (error) {
      return { success: false, message: error.message };
    }
  };

  const handleRemoveDoctor = async (doctorId) => {
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys) {
      setIsDoctorBeingDeleted(true);
      setDeletingDoctorId(doctorId);

      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.delete(
          RX_OPD_ENDPOINTS.HOSPITAL.DELETE_DOCTOR +
            "/" +
            currentHospital.hos_id +
            "/" +
            doctorAllDetails.doc_id
        );

        if (res) {
          // setDeletedDoctorId(doctorId);
          await fetchRegisteredDoctors();
          setShowToast(true);
          setToastType("success");
          setToastMessage(res.data.message);
        } else {
          throw new Error("Something went wrong. Please try later");
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error.message);
      } finally {
        setShowPrompt(false);
        setIsDoctorBeingDeleted(false);
      }
    }
  };

  const handleRemoveClick = (doctorId) => {
    setDeletingDoctorId(doctorId);
    setShowPrompt(true);
  };
  return (
    <Modal show={onEdit} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {operation === "add"
            ? "Add New User"
            : "Update the Details of Selected User"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={editedDoctor.email}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter phone"
              name="phone"
              value={editedDoctor.phone}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter first name"
              name="firstName"
              value={editedDoctor.firstName}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter last name"
              name="lastName"
              value={editedDoctor.lastName}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formQualification">
            <Form.Label>Qualification</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter qualification"
              name="qualification"
              value={editedDoctor.qualification}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSpeciality">
            <Form.Label>Speciality</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter speciality"
              name="speciality"
              value={editedDoctor.speciality}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="button"
          style={{
            backgroundColor: "red",
            border: "1px solid red",
            color: "white",
          }}
          onClick={() => handleRemoveClick(editedDoctor.doc_id)}
        >
          Remove
        </Button>
        <Button variant="primary" onClick={() => handleSaveChanges()}>
          Save Changes
        </Button>
      </Modal.Footer>

      {/* <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        centered // Add centered prop to center the modal
      >
        <Modal.Header closeButton>
          <Modal.Title>profile updated successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalVisible(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}

      {showPrompt && (
        <Prompt
          show={showPrompt}
          handleNevermind={setShowPrompt}
          handleGoAhead={() => handleRemoveDoctor(deletingDoctorId)}
          isGoAheading={isDoctorBeingDeleted}
        >
          Are you sure you would like to Remove Doctor? It will remove all
          records associated with the doctor.
        </Prompt>
      )}
      {modalVisible && (
        <Toast type={"success"} show={true} handleToastClose={onHide}>
          {modalMessage}
        </Toast>
      )}
    </Modal>
  );
};

export default DoctorDetailsModal;
