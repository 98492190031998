import { useContext, useEffect, useState } from "react";

import ModeContext from "../context/mode-context";
import HospitalsContext from "../context/hospitals-context";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import PageContentRoutes from "../utils/app-content-routes";
import { rxOpdApi } from "../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../utils/api/apiEndPoints";
import PatientTab from "../components/patients/PatinetTab";
import Select from "react-select";
import { Field, Form, Formik} from "formik";
import * as Yup from "yup";
import PatientForm from "../components/opd/appointments-form/PatientForm";
import EditPatientModal from "../components/patients/EditPatientModal";
import dayjs from "dayjs";
import { Modal } from "react-bootstrap";
import PatientUpload from "../components/patients/PatientUpload";
import DateRange from '../components/date-range/DateRange';
import Col from "react-bootstrap/Col";

const endDateVal = new Date();
const startDateVal = new Date();
startDateVal.setDate(endDateVal.getDate() -30);
endDateVal.setDate(endDateVal.getDate() +1);

const getYyyyMmDdDate = (date) => {
  const month = date.getMonth() + 1;
  const d = date.getDate();

  const mm = month.toString().length === 1 ? `0${month}` : `${month}`;
  const dd = d.toString().length === 1 ? `0${d}` : `${d}`;

  return `${dd}-${mm}-${date.getFullYear()}`;
};

function Patients() {
  const { mode } = useContext(ModeContext);
  const [registeredDoctors, setRegisteredDoctors] = useState([]);
  const [areDoctorsLoading, setAreDoctorsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [filterTitle, setFilterTitle] = useState({});

  const [selectedDocId, setSelectedDocId] = useState({
    label: "All Doctors",
    value: "all",
  });

  const currentDate = dayjs();
  const defaultStartDate = currentDate.subtract(3, "day");
  const defaultEndDate = currentDate.add(1, "day");
  const [dateRange, setDateRange] = useState({
    from: defaultStartDate,
    to: defaultEndDate,
  });
  const [startDate, setStartDate] = useState(startDateVal);
  const [endDate, setEndDate] = useState(endDateVal);
  const [isLoadingPatients, setIsLoadingPatients] = useState(false);
  const [isLoadPmodal, setIsLoadPmodal] = useState(0);
  const [filterList, setFilterList] = useState([]);
  const [show, setShow] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [body, setBody] = useState({});
  const [selectedPatient, setSelectedPatient] = useState("");
  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedProfileData, setselectedProfileData] = useState([]);
  const [prescriptionList, setprescriptionList] = useState([]);
  const [createPatientForm, setCreatePatientForm] = useState(false);
  const handleAddNewPatient = () => setCreatePatientForm(true);
  const {
    isLoading,
    currentHospital,
    changeCurrentHospital,
    hospitals,
    noHospsMessage,
  } = useContext(HospitalsContext);
  const handleHospitalChange = ({ value }) => {
    changeCurrentHospital(value);
  };

  const allDoctorsOption = { doc_id: "all", doc_name: "All Doctors" };

  useEffect(() => {
    setLoadingDocs(true);
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys) {
      const fetchRegisteredDoctors = async () => {
        setAreDoctorsLoading(true);

        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.LIST_ALL_DOCTORS +
                "/" +
                currentHospital?.hos_id
          );

          if (res) {
            const doctorsWithAllOption = [
              allDoctorsOption,
              ...res.data.records,
            ];
            setRegisteredDoctors(doctorsWithAllOption);
          } else {
            throw new Error("Something went wrong. Please try again.");
          }
        } catch (error) {
          setRegisteredDoctors([]);
          setErrorMessage(error.message);
        } finally {
          setLoadingDocs(false);
        }
      };

      fetchRegisteredDoctors();
    }
  }, [mode, isLoading, currentHospital]);

  useEffect(() => {
    const fetchRegisteredPatinet = async () => {
      setIsLoadingPatients(true);
      let url =
        RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.PATIENT_LIST +
        "/" +
        currentHospital?.hos_id +
        "?start_date=" +
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}` +
        
        "&end_date=" +
        `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`
      ;
      if (selectedDocId.value !== "all") {
        url += "?doc_id=" + selectedDocId.value;
      }
      try {
        const res = await rxOpdApi.post(url, body);
        setpatientList(res.data.records);
      } catch (error) {
        setpatientList([]);
        setErrorMessage(error.message);
      } finally {
        setIsLoadingPatients(false);
      }
    };
    fetchRegisteredPatinet();
  }, [currentHospital, selectedDocId, body,startDate,endDate]);
  /* LIST ADVANCE FILTER LIST*/
  useEffect(() => {
    const fetchAdvanceFilterLookup = async () => {
      try {
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.PATIENT_FILTER_LIST
        );
        const transformData = res?.data?.records.map((item) => ({
          label: item.filter_display,
          value: item.filter,
        }));
        setFilterList(transformData);
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        console.log("finally");
      }
    };
    fetchAdvanceFilterLookup();
  }, []);
  /*  PATIENT */

  useEffect(() => {fetchData(true)
  }, [mode, selectedPatient, currentHospital]);
  
  useEffect(()=>{fetchData(false)},[dateRange])
  /*Download patients */

  function fetchData(showLoader){
    
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys) {
      const fetchPatientAppointment = async () => {
        if(showLoader)
          setIsLoadPmodal(2)
        else 
          setIsLoadPmodal(1)
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.PATIENT_INFO}/${currentHospital.hos_id}/${selectedPatient}`
        );
        if (res) {
          setselectedProfileData(res.data);
        }

        const medicalrecords = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.MEDICAL_RECORDS}/${currentHospital.hos_id}/${selectedPatient}`
        );
        if (medicalrecords) {
          setprescriptionList(medicalrecords.data.records);
        }

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.PATIENT_APPOINTMENT_LIST +
                "/" +
                currentHospital?.hos_id +
                "/" +
                selectedPatient +
                "/" +
                dateRange.from.format("YYYY-MM-DD") +
                "/" +
                dateRange.to.format("YYYY-MM-DD")
          );

          if (res) {
            setAppointmentList(res.data.records);
          } else {
            throw new Error("Something went wrong. Please try again.");
          }
        } catch (error) {
          console.log("catch");
        } finally {
          console.log("fi");
          setIsLoadPmodal(0)
        }
      };

      fetchPatientAppointment();
    }
  }

  const DownloadPatient = async () => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    try {
      rxOpdApi.setAuthHeaders(key, secret);
      const url =
        RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.DOWNLOAD_REGISTERED_PATIENTS +
        "/" +
        currentHospital?.hos_id;
      const ody = {};
      const res = await rxOpdApi.post(url, ody);
      if (res) {
        const csvData = new Blob([res.data], { type: "text/csv" });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(csvData);
        a.download = "registered_patients.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        throw new Error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.log("catch");
    } finally {
      console.log("fi");
    }
  };
  const handleDownloadSampleFile = () => {
    const sampleFileUrl = "/Bulk_Upload_Template.xlsx";

    const downloadLink = document.createElement("a");
    downloadLink.href = sampleFileUrl;
    downloadLink.target = "_blank";
    downloadLink.download = "Bulk_Upload_Template.xlsx";

    downloadLink.click();
  };
  /* handle excel file upload */
  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => setShowUpload(true);

  const PatinetsRoute = [
    {
      path: "patients",
      component: (
        <PatientTab
          isLoadingPatients={isLoadingPatients}
          registeredPatient={patientList}
          setSelectedPatient={setSelectedPatient}
          handleShow={handleShow}
        />
      ),
    },
  ];

  return (
    <PageContentLayout>
      <PageNav routes={PatinetsRoute} />

      <div className="row mb-4 ">
        <div className="col col-12 col-md-4 mx-lg-3 ">
          <div className="row ">
            <div>Select Facility:</div>
            <div className="col col-12">
              <Select
                defaultValue={{
                  label: (
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_RX_OPD +
                          (mode === "test" ? "test/" : "") +
                          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO +
                          "/" +
                          currentHospital?.hos_id +
                          "?v=" +
                          Math.random() * Math.random()
                        }
                        height="30px"
                        width="30px"
                        style={{ marginRight: "2px" }}
                          // onError="this.src='https://fastly.picsum.photos/id/40/200/200.jpg?hmac=xkvWvgGjMuaPySCsshiYpLBOaphxinRhPkMRgx-LIYQ';"
                      />
                      {currentHospital?.hosp_name}
                    </div>
                  ),
                  value: currentHospital?.hos_id,
                }}
                options={hospitals?.map((hospital) => ({
                  label: (
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_RX_OPD +
                          (mode === "test" ? "test/" : "") +
                          RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO +
                          "/" +
                          hospital?.hos_id +
                          "?v=" +
                          Math.random() * Math.random()
                        }
                        height="30px"
                        width="30px"
                        onError="this.src='https://fastly.picsum.photos/id/40/200/200.jpg?hmac=xkvWvgGjMuaPySCsshiYpLBOaphxinRhPkMRgx-LIYQ';"
                      />
                      {hospital?.hosp_name}
                    </div>
                  ),
                  value: hospital?.hos_id,
                }))}
                onChange={(selectedOption) => {
                  handleHospitalChange(selectedOption);
                  setSelectedDocId({ label: "All Doctors", value: "all" });
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    padding: "0px",
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                  }),
                  container: (containerStyles) => ({
                    ...containerStyles,
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <div className="col col-12 col-md-4 ">
          <div className="row ">
            <div>Select Doctor:</div>
            <div className="col col-12">
              <Select
                isLoading={loadingDocs}
                options={registeredDoctors?.map((doc) => ({
                  label: doc.doc_name,
                  value: doc.doc_id,
                }))}
                value={selectedDocId}
                onChange={({ value, label }) => {
                  setSelectedDocId({ label: label, value: value });
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row  align-items-center px-3 mb-4 " >

     
               <Col
            xs={12}
            lg={8}
            className="d-flex mt-3 mt-lg-0 align-items-center position-relative"
          >

            <DateRange
            
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              label="Registered between (select range):"
            />
          
          </Col>
          </div>

            <div style={{maxWidth:"86%"}}> 
      <Formik
        initialValues={{ filter: "", filter_value: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setBody({
            filter: values.filter.value,
            filter_value: values.filter_value,
          });
          setFilterTitle(values.filter.label);
          setFormSubmitted(true);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          filter: Yup.mixed().required("filter is required"),
          filter_value: Yup.mixed().required("Filter value is required"),
        })}
      >
        {({  isSubmitting, dirty, resetForm }) => (
          <Form>
            <div className="d-flex flex-row mx-lg-3 my-lg-1">
              <div className="col-3">
                <Field name="filter">
                  {({ field, form }) => (
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Advance Search"
                      {...field}
                      name="filter"
                      options={filterList}
                      styles={{
                        control: (controlStyles) => ({
                          ...controlStyles,
                          border: "2px solid #b3c6e7",
                          borderRadius: "0",
                        }),
                      }}
                      onChange={(option) => {
                        form.setFieldValue("filter", option, true);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="input-group input-group-lg">
                <Field name="filter_value">
                  {({ field, form }) => (
                    <input
                      {...field}
                      name="filter_value"
                      className="inputGroup-sizing-default"
                      onChange={(event) => {
                        const option = event.target.value;
                        // console.log("option", option);
                        form.setFieldValue("filter_value", option, true);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="col-8 mx-lg-3">
                {formSubmitted ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      resetForm();
                      setBody({});
                      setFormSubmitted(false);
                    }}
                  >
                    Clear
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={dirty ? false : true}
                    className="btn "
                    style={{background:"#009698",color:"white"}}

                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
            <div className="mx-lg-3">
              {formSubmitted && body.filter ? (
                <div>
                  <span>
                    Showing results for{" "}
                    <span style={{ fontWeight: "bold" }}>-</span>{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {filterTitle} : {body.filter_value}
                  </span>
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
      </div>
      <EditPatientModal
        setDateRange={setDateRange}
        dateRange={dateRange}
        show={show}
        onHide={handleClose}
        appointmentList={appointmentList}
        selectedProfileData={selectedProfileData}
        editid={selectedPatient}
        prescriptionList={prescriptionList}
        selectedPatient={selectedPatient}
        loading={isLoadPmodal}
        onClose={()=>{
        }}


        
      />
      <PatientForm
        addToExistingProfile={true}
        show={createPatientForm}
        onHide={() => setCreatePatientForm(false)}
        type="scretch"
      />
      <PageContentRoutes routes={PatinetsRoute} />
      <div className=" mx-lg-3 my-lg-1 d-flex justify-content-between">
        <div className="d-flex flex-row">
          <div>
            <button
              className="btn "
              type="button"
              onClick={handleAddNewPatient}
              style={{background:"#009698",color:"white"}}

            >
              Add New
            </button>
          </div>
          <div className="d-flex flex-column">
            <button
              className="btn  mx-lg-3"
              type="button"
              onClick={handleShowUpload}
              style={{background:"#009698",color:"white"}}

            >
              Bulk Upload
            </button>

            <button
              className="btn btn-link"
              

              type="button"
              onClick={handleDownloadSampleFile}
            >
              Download Sample File
            </button>
          </div>
        </div>
        <div>
          <button
            className="btn mx-lg-3"
            type="button"
            onClick={() => DownloadPatient(currentHospital.hos_id)}
            style={{background:"#009698",color:"white"}}

          >
            Export Patient
          </button>
        </div>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          style={{ minHeight: "200px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              minHeight: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"       
            }}
          >
            <PatientUpload currentHospital={currentHospital?.hos_id} />
          </Modal.Body>
        </Modal>
      </div>
    </PageContentLayout>
  );
}

export default Patients;
