import { useContext,  useState} from 'react'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import '../opd/SendPaymentLink.css'
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { rxOpdApi } from "../../utils/api/api";
import HospitalsContext from '../../context/hospitals-context';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ModeContext from '../../context/mode-context';
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Toast from '../ui/Toast';



const SendPaymentLink = (props) => {
const [validate, setValidate] =useState(true);
const navigate = useNavigate();
const { mode } = useContext(ModeContext);  


//  const formikProps = useFormikContext();
const [open, setOpen] = useState(true);
const[message, setMessage] = useState(null);
const handleClose = () => setOpen(false);

  const { currentHospital } = useContext( HospitalsContext );
const{ hos_id} = currentHospital
const{mobile, email, customerName,orderid,category} = props
const[sms,setSms] = useState(false)
const[mail,setMail] = useState(false)
const[whatsapp,setWhatsapp] = useState(false)
const [showToast,setShowToast]=useState(false);
const [toastType,setToastType]=useState();
const[toastMessage,setToastMessage]=useState();

const initialValues =  {
    countryCode: '+91',
    Mobileno: `${mobile}`,
    Email: `${email}`,
    sms:'true',
    whatsapp:'true',
    mail:'true',

}

const onSubmit = values =>{
}
console.log("hospitalid",hos_id);
const validationSchema =Yup.object({
    countryCode:Yup.string().required("required"),
    Mobileno:Yup.number().required(""),
    Email:Yup.string().email().required("required"),
    sms:Yup.boolean(),
    mail:Yup.boolean(),
    whatsapp:Yup.boolean(),
})

  //console.log("props",props); 
  // const{mobile, email, customerName,orderid,category} = props

  const formik = useFormik({
initialValues,
onSubmit,
validationSchema,    
 })

 const BackToMenu = 
 () => 
   { navigate ('/app/opd/Orders')  
     
   };

 const submitHandler = (e) => {

  postData();
setValidate(!validate);

 }

 
const postData = async() => {
  
  // alert("hi");    

  try {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];

    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    
    // const values = formikProps.values.patientProfile;

    rxOpdApi.setAuthHeaders(key, secret);
    const res = await rxOpdApi.post(
      RX_OPD_ENDPOINTS.HOSPITAL.OPD.ORDER_PAY_LINK + 
      `/${hos_id}/${category}/${orderid}`
       ,
      {
        
        "send_sms":`${formik.values.sms}`,
        "send_email":`${formik.values.mail}`,
        "send_wa":`${formik.values.whatsapp}`,
        "phone":`${formik.values.Mobileno}`,
        "email":`${formik.values.Email}`
        }
    );


    if (res) {
      setToastMessage(res.data.message);
      setShowToast("true");
      setToastType("success");
    console.log("response",toastMessage,toastType,showToast)

    }
  } catch (error) {
  setMessage(error.message)
    console.log("error",error.message);
  } finally {
    
   
  }
};

const handleSmschange=()=>{

  formik.values.sms = !formik.values.sms
  console.log("sms1",formik.values.sms)
setSms(!sms)
}

const handlemailchange=()=>{

  formik.values.mail = !formik.values.mail
  console.log("mail1",formik.values.mail)
setMail(!mail)
}
 

const handlwhatschange=()=>{

  formik.values.whatsapp = !formik.values.whatsapp
  console.log("wht1",formik.values.whatsapp)
setWhatsapp(!whatsapp)
}

return (
<>
  <Modal
  open={open}
  onClose={handleClose}
>
   <Typography >


 <div className="Pcontainer"> 

<form onSubmit={submitHandler}>
        <div className="Container2">
          <div className="Head1">Send Payment Link</div>
          <div className="Head2 Head1">
            Confirm {customerName} contact details
            <br /> before sending payment link.
          </div>
          <div>
          <div className="Countrycode">
            
                        <label htmlFor="countryCode">  </label>
                        <input className='box1' type='text' name = "countryCode" id ="countryCode"
                        onChange = {formik.handleChange}
                        value ={formik.values.countryCode}
                        onBlur={formik.handleBlur} >
                        </input>
                        {(formik.errors.countryCode && formik.touched && formik.values.countryCode!=="" )&& <div>{formik.errors.countryCode}</div>}

                        <label htmlFor="Mobileno">  </label>
                        <input className='box2' type='number' name = "Mobileno" id ="Mobileno"
                        onChange = {formik.handleChange}
                          value ={formik.values.Mobileno}
                          onBlur={formik.handleBlur} >
                          </input>
                          {/* {((formik.values.Mobileno !== mobile) && formik.touched) && <div>"Mobile number Mismatch "</div> } */}
                        {(formik.errors.Mobileno && formik.touched && formik.values.Mobileno!== "")&& <div>{formik.errors.Mobileno}</div>}
                        
                        </div>

                        <label htmlFor="Email">  </label>
                        <input className='mailbox' type='text' name = "Email" id ="Email"
                        onChange = {formik.handleChange}
                          value ={formik.values.Email}
                          onBlur={formik.handleBlur} >
                          </input>
                        {(formik.errors.Email && formik.touched && formik.values.Email!=="")&& <div>{formik.errors.Email}</div>}
                      
    </div>
    <Container id="imp">
          <Row>
            <Col>
              <img className='imgsendpaymentlink1' style={{paddingLeft:"4px",paddingRight:"5px"}} src="https://th.bing.com/th?id=OIP.cuaF9TX540ijKbmWQogH5AHaHX&w=250&h=249&c=8&rs=1&q lt=90&o=6&dpr=1.5&pid=3.1&rm=2" alt="sms" />
            </Col>
            <Col>
            <img className="imgsendpaymentlink1" style={{paddingLeft:"6px",paddingRight:"4px"}} src="https://th.bing.com/th?id=OIP.g9gWiUAsg0Wgt9KnIjMinAHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2" alt="mail" />
          
              </Col>
              <Col>
               <img className='imgsendpaymentlink1' src="https://www.bing.com/th?id=OIP.wZxNTFj2NXxwn4eYy-KsigHaHe&w=150&h=152&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2" alt="whatap" />
           
            </Col>
          </Row> 
          </Container>
          
          <Container id="imp" >
             <Row>
            <Col>
            <div>
          {console.log("smsnear",formik.values)}
            <FormControlLabel style={{margin:0,padding:0}}
                    control={
            <Switch style={{margin:0}} checked={formik.values.sms} onChange={handleSmschange} name="sms" />
          }
        
        />
         
        {/* <input  type="checkbox" id="sms" name="sms"  
             onChange = {formik.handleChange}
                value ={formik.values.sms}
                onBlur={formik.handleBlur}
                />
            <label htmlFor="sms"></label> */}
              </div>
             </Col>
             
           
            <Col>
            <div >
            <FormControlLabel style={{margin:0,padding:0}}
                    control={
            <Switch style={{margin:0}} checked={formik.values.mail} onChange={handlemailchange}   name = "mail" />
          }
        
        />
          {/* <input type="checkbox" id="mail" name="mail" 
            
            onChange={formik.handleChange}
                value ={formik.values.mail}
                onBlur={formik.handleBlur} 
                    />
              <label htmlFor="mail"></label> */}
</div>
            </Col> 
             <Col>
            <div >
              
            <FormControlLabel style={{margin:0,padding:0}}
                    control={
            <Switch style={{margin:0}} checked={formik.values.whatsapp} onChange={handlwhatschange}   name = "whatsapp" />
          }
        
        />
            {/* <input type="checkbox" id="whatsapp" name="whatsapp" 
              onChange={formik.handleChange}
            value ={!formik.values.whatsapp}
            onBlur={formik.handleBlur} 
                />
             <label htmlFor="whatsapp"></label> */}
            </div>
            </Col>
           
              </Row>
             </Container>
             
             <button type="Submit"  className='sendpaymentlinkfooter' onSubmit={()=>setValidate(!validate)}> Send</button>

            </div>
                    </form>
                    </div>

        </Typography>
        </Modal>
        {console.log("sned paymentlink",toastMessage,toastType,showToast)
}
{showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
          autohide={true}
          autohideDelay={3000}
        >
          {toastMessage}
        </Toast>
      )} 
   {(!showToast && toastType==="success" ) && BackToMenu()}

          




        </>)}
export default SendPaymentLink
// {message && <div> {message}</div>}
