import { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOpdApi } from "../../utils/api/api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext, Field } from "formik";
import RegisterFacility from "./RegisterFacility";
import ModeContext from "../../context/mode-context";
import { render } from "@testing-library/react";
import FormControl from "../form/FormControl";
import Creatable from "react-select/creatable";
import FieldInfo from "../form/FieldInfo";
import Select from "react-select";


const Facilitytype = (props) => {
  const {
    isMainDataSaved,
    areDocsSubmitted,
    setIsShortNameValid,
    onFacilityTypeChange,
  } = props;

  const formikProps = useFormikContext();

  const { mode } = useContext(ModeContext);
  const { ref } = useRef();
  const shortNameRef = useRef(null); // Reference to the short name input element

  const [facilityTitles, setFacilityTitles] = useState([]);
  const [selectedFacilityType, setSelectedFacilityType] = useState(
    formikProps?.values?.facilityType?.facility_type || ""
  );
  const [facilityName, setFacilityName] = useState(
    formikProps?.values?.facilityType?.facility_name || ""
  );
  const [shortName, setShortName] = useState(
    formikProps?.values?.facilityType?.short_name || ""
  );
  const [facilityIntroduction, setFacilityIntroduction] = useState(
    formikProps?.values?.facilityType?.facility_description || ""
  );
  const [data, setData] = useState("");

  const [shortNameMessage, setShortNameMessage] = useState("");
  // const [isShortNameValid, setIsShortNameValid] = useState(true);
  const shouldPassProp = false;

  useEffect(() => {
    const fetchFacilityTitles = async () => {
      try {
        const response = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.SYSTEM_OF_MEDICNE
        );
        if (response.status === 200) {
          // const titles = response.data.records.map((record) => record.title);
          setFacilityTitles(response.data.records);
        }
      } catch (error) {
        console.error("Error fetching Facility Titles:", error);
      }
    };

    fetchFacilityTitles(); // Call the API when the component mounts
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const handleFacilityTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedFacilityType(selectedType);
    formikProps.setFieldValue("facilityType.facility_type", selectedType);

    // onFacilityTypeChange(selectedType);
  };

  const handleShortNameBlur = async (event) => {
    const shortName = event.target.value;
    // formikProps.setFieldTouched("facilitytype.shortname", shortName);

    if (shortName.trim() === "") return;
    if (shortName.trim().includes(" ")) return;

    try {
      const response = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.HOSPITAL.CHECKING_NAME + "/" + shortName
      );
      if (response.status === 200) {
        setShortNameMessage(response.data.message);
        setIsShortNameValid(true);
      }
    } catch (error) {
      setShortNameMessage(error.message);
      setIsShortNameValid(false);
    }
  };

  const handleFacilityNameChange = (event) => {
    const name = event.target.value;
    setFacilityName(name);

    // Set the value of the "facilityName" field in formik context
    formikProps.setFieldValue("facilityType.facility_name", name);
  };

  const handleFacilityDescriptionChange = (event) => {
    const description = event.target.value;
    setFacilityIntroduction(description);

    // Set the value of the "facilityDescription" field in formik context
    formikProps.setFieldValue("facilityType.facility_description", description);
  };
  const handleShortNameChange = (event) => {
    const name = event.target.value;
    setShortName(name);

    // Set the value of the "shortName" field in formik context
    formikProps.setFieldValue("facilityType.short_name", name);
  };

  return (
    <>
      <Form className="mt-5" style={{ caretColor: "none" }}>
        {/* <Form.Group className="mb-3" controlId="formFacilityType">
          <Form.Label style={{ float: "inline-start", marginLeft: "-151px" }}>
            Select Facility Type
          </Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              onChange={handleFacilityTypeChange}
              value={selectedFacilityType}
              // disabled={isMainDataSaved}
            >
              <option value="">Select Facility Type</option>
              {facilityTitles.map((title, index) => (
                <option key={index} value={title.code}>
                  {title.title}
                </option>
              ))}
            </Form.Control>
            <FontAwesomeIcon
              // icon={faAngleDown}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            />
          </div>
        </Form.Group> */}
        
        <div className="row" style={{ position: "relative",height: "4.7rem",marginBottom: "-32px", }}>

          {/* <FieldInfo
            // info="In case of Individual Doctor Clinic, provide the Accreditation Authority name which issued Doctor’s Registration"
            classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
          /> */}
          <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center" style={{marginTop: "-35px"}}  >
            Select Facility Type
          </label>
          <Field
            name="facilityType.facility_type"
            className="col-12 col-md-8"
            
          >
            {({ field, form }) => (
              <select
                {...field}
                name="facilityType.facility_type"
                className="col-12 col-md-8 px-0 h-50"
                onChange={(event) => {
                  const option = event.target.value;
                  form.setFieldValue(
                    "facilityType.facility_type",
                    option,
                    true
                  );
                  handleFacilityTypeChange(
                    facilityTitles,
                    `?facility_type=${option}`
                  );
                }}
                style={{border: "2px solid #b3c6e7", marginBottom: "20px", }}
              >
                <option value="">Select Facility Type</option>
                {facilityTitles.map((title, index) => (
                  <option key={index} value={title.code}>
                    {title.title}
                  </option>
                ))}
              </select>
            )}
          </Field>
        </div>
        {/* <Form.Group className="mb-3" controlId="formEmail"> */}
        {/* <Form.Label style={{marginLeft: "-115px", float: "inline-start"}}>Facility Name</Form.Label> */}
        <FormControl
          label="Facility Name"
          type="text"
          placeholder="Facility Name"
          name="facilityType.facility_name"
          // disabled={isMainDataSaved }
          // value={facilityName}
          onChange={handleFacilityNameChange}
          style={{marginTop: 0}}
        />
        {/* </Form.Group> */}
        <Form.Group className="mb-3" controlId="formEmail">
          {/* <Form.Label style={{float: "inline-start", marginLeft: "-160px"}}>Facility Short Name</Form.Label> */}
          <FormControl
            label="Facility Short Name"
            type="text"
            placeholder="Facility Short Name (Unique ID) *"
            name="facilityType.short_name"
            ref={shortNameRef}
            onBlur={handleShortNameBlur}
            // disabled={isMainDataSaved}
            // value={shortName}
            onChange={handleShortNameChange}
          />
          {shortNameMessage !== "" && (
            <p
              className={
                shortNameMessage ===
                "Go ahead with the short name for the Facility."
                  ? "valid-message"
                  : "invalid-message"
              }
            >
              {shortNameMessage}
            </p>
          )}
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formEmail">
      <Form.Label style={{float: "inline-start", marginLeft: "-290px"}}>Provide brief introduction of the facility </Form.Label> */}

        <FormControl
          label="About Facility"
          as="textarea"
          rows={5}
          placeholder="Provide brief introduction of the facility (to be shown in the Facility Profile) *"
          name="facilityType.facility_description"
          // disabled={isMainDataSaved}
          // value={facilityIntroduction}
          onChange={handleFacilityDescriptionChange}
          style={{ border: "2px solid #b3c6e7", resize: "none", borderRadius: "5px", marginTop: "-10px" }} 
          />
        {/* </Form.Group> */}
      </Form>
    </>
  );
};
export default Facilitytype;
