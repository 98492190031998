import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form as FormikForm, Formik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import ModeContext from "../../context/mode-context";
import DoctorSchedule from "./DoctorSchedule";
import InputErrorMessage from "../kyc/InputErrorMessage";
import Button from "../ui/Button";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";

const recurringValidationSchema = Yup.object().shape({
  update: Yup.object().shape({
    appointmentType: Yup.string().required("Appointment Type is required"),
    weekdays: Yup.array()
      .min(1, "Select at least one of the weekdays")
      .required("Weekdays is required"),
    // morning_start_time: Yup.string(),
    // morning_end_time: Yup.string(),
    // afternoon_start_time: Yup.string(),
    // afternoon_end_time: Yup.string(),
    // evening_start_time: Yup.string(),
    // evening_end_time: Yup.string(),
    slot_duration: Yup.string().required("Slot Duration is required"),
    // scheduleEndDate: Yup.date().required("End Date is required"),
  }),
});

const dayValidationSchema = Yup.object().shape({
  update: Yup.object().shape({
    appointmentType: Yup.string().required("Appointment Type is required"),
    // schedule_date: Yup.date().required("Schedule Date is required"),
    // declareOff:Yup.array().required(),
    // startTime: Yup.string().required("Start Time is required"),
    // endTime: Yup.string().required("End Time is required"),
    // slot_duration: Yup.string().required("Slot Duration is required"),
  }),
});

function UpdateScheduleModal(props) {
  const { hospitalId, doctorId, setShowRegistrationDocsModal, show, onHide } =
    props;
  const [dirty, setDirty] = useState(false);
  const { mode } = useContext(ModeContext);

  const [updateRecurringSchedule, setUpdateRecurringSchedule] = useState(true);
  const [formValues, setFormValues] = useState("");
  const [formValidationSchema, setFormValidationSchema] = useState(null);
  const [fetchingSchedule, setFetchingSchedule] = useState(true);
  const [appointType, setAppointType] = useState("online");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [selectDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const fetchDocSchedule = async () => {
      setFetchingSchedule(true);

      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        let endPoint = "";
        let dateString = "";

        if (updateRecurringSchedule) {
          endPoint = RX_OPD_ENDPOINTS.HOSPITAL.FETCH_DOCTOR_RECURRING_SCHEDULE;
        } else {
          endPoint = RX_OPD_ENDPOINTS.HOSPITAL.FETCH_DOCTOR_DAY_SCHEDULE;
          const currentMonth = selectDate.getMonth() + 1;
          dateString =
            selectDate.getFullYear() +
            "-" +
            currentMonth +
            "-" +
            selectDate.getDate();
        }

        rxOpdApi.setAuthHeaders(key, secret);
        const scheduleRes = await rxOpdApi.get(
          `${endPoint}/${hospitalId}/${doctorId}/${appointType}${
            updateRecurringSchedule ? "" : "/" + dateString
          }`
        );

        if (scheduleRes) {
          if (updateRecurringSchedule) {
            if (Object.keys(scheduleRes.data).length === 0) {
              setShowToast(true);
              setToastType("info");
              setToastMessage("No schedule found!");
              setFormValues({
                update: {
                  appointmentType: appointType,
                  weekdays: "",
                  morning_start_time: "",
                  morning_end_time: "",
                  afternoon_start_time: "",
                  afternoon_end_time: "",
                  evening_start_time: "",
                  evening_end_time: "",
                  slot_duration: "",
                  schedule_end_date: "",
                },
              });
            } else {
              const date = new Date(scheduleRes.data.schedule_end_date);
              setFormValues({
                update: {
                  appointmentType: scheduleRes.data.appointment_type,
                  weekdays: scheduleRes.data.weekdays.map((day) =>
                    day.toString()
                  ),
                  morning_start_time: scheduleRes.data.morning_start_time
                    ? scheduleRes.data.morning_start_time
                    : undefined,
                  morning_end_time: scheduleRes.data.morning_end_time
                    ? scheduleRes.data.morning_end_time
                    : undefined,
                  afternoon_start_time: scheduleRes.data.afternoon_start_time
                    ? scheduleRes.data.afternoon_start_time
                    : undefined,
                  afternoon_end_time: scheduleRes.data.afternoon_end_time
                    ? scheduleRes.data.afternoon_end_time
                    : undefined,
                  evening_start_time: scheduleRes.data.evening_start_time
                    ? scheduleRes.data.evening_start_time
                    : undefined,
                  evening_end_time: scheduleRes.data.evening_end_time
                    ? scheduleRes.data.evening_end_time
                    : undefined,
                  slot_duration: scheduleRes.data.slot_duration
                    ? scheduleRes.data.slot_duration
                    : undefined,
                  schedule_end_date: scheduleRes.data.schedule_end_date
                    ? new Date(scheduleRes.data.schedule_end_date)
                    : undefined,
                },
              });
            }

            setFormValidationSchema(recurringValidationSchema);
          } else {
            const defaultScheduleDate = new Date();
            defaultScheduleDate.setDate(defaultScheduleDate.getDate() + 1);
            if (scheduleRes.data.message) {
              setShowToast(true);
              setToastType("info");
              setToastMessage(scheduleRes.data.message);
              setFormValues({
                update: {
                  appointmentType: appointType,
                  schedule_date: selectDate,
                  declareOff: "",
                  morning_start_time: "",
                  morning_end_time: "",
                  afternoon_start_time: "",
                  afternoon_end_time: "",
                  evening_start_time: "",
                  evening_end_time: "",
                  slot_duration: "10",
                },
              });
            } else {
              setFormValues({
                update: {
                  is_available: scheduleRes.data.is_available
                    ? scheduleRes.data.is_available
                    : undefined,
                  appointmentType: appointType,
                  morning_start_time: scheduleRes.data.morning_start_time
                    ? scheduleRes.data.morning_start_time
                    : undefined,
                  morning_end_time: scheduleRes.data.morning_end_time
                    ? scheduleRes.data.morning_end_time
                    : undefined,
                  afternoon_start_time: scheduleRes.data.afternoon_start_time
                    ? scheduleRes.data.afternoon_start_time
                    : undefined,
                  afternoon_end_time: scheduleRes.data.afternoon_end_time
                    ? scheduleRes.data.afternoon_end_time
                    : undefined,
                  evening_start_time: scheduleRes.data.evening_start_time
                    ? scheduleRes.data.evening_start_time
                    : undefined,
                  evening_end_time: scheduleRes.data.evening_end_time
                    ? scheduleRes.data.evening_end_time
                    : undefined,
                  slot_duration: scheduleRes.data.slot_duration.toString()
                    ? scheduleRes.data.slot_duration.toString()
                    : undefined,
                  schedule_date: scheduleRes.data.slot_duration
                    ? scheduleRes.data.schedule_date
                    : undefined,
                },
              });
            }

            setFormValidationSchema(dayValidationSchema);
          }
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error.message);
      } finally {
        setFetchingSchedule(false);
      }
    };

    fetchDocSchedule();
  }, [
    updateRecurringSchedule,
    appointType,
    doctorId,
    hospitalId,
    mode,
    selectDate,
  ]);
  const appointmentTypeOptions = [
    { value: "online", label: "Tele-Consultation" },
    { value: "in-person", label: "In-person" },
  ];
  const handleChange = async (value) => {
    setAppointType(value);
  };

  const handleSubmit = async (values) => {
    const vals = { ...values.update };

    setIsSubmitting(true);

    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];

    try {
      let endPoint;
      let body;
      let dateString;

      if (updateRecurringSchedule) {
        endPoint = RX_OPD_ENDPOINTS.HOSPITAL.CREATE_DOCTOR_RECURRING_SCHEDULE;
        body = {
          appointment_type: vals.appointmentType,
          days_list: vals.weekdays.map((day) => +day).sort((a, b) => a - b),

          morning_start_time: vals.morning_start_time
            ? vals.morning_start_time
            : undefined,
          morning_end_time: vals.morning_end_time
            ? vals.morning_end_time
            : undefined,
          afternoon_start_time: vals.afternoon_start_time
            ? vals.afternoon_start_time
            : undefined,
          afternoon_end_time: vals.afternoon_end_time
            ? vals.afternoon_end_time
            : undefined,
          evening_start_time: vals.evening_start_time
            ? vals.evening_start_time
            : undefined,
          evening_end_time: vals.evening_end_time
            ? vals.evening_end_time
            : undefined,

          slot_duration: vals.slot_duration,
          schedule_end_date:
            vals.schedule_end_date.getFullYear() +
            "-" +
            (vals.schedule_end_date.getMonth() + 1) +
            "-" +
            vals.schedule_end_date.getDate(),
        };
      } else {
        dateString =
          selectDate.getFullYear() +
          "-" +
          (selectDate.getMonth() + 1) +
          "-" +
          selectDate.getDate();
        endPoint = RX_OPD_ENDPOINTS.HOSPITAL.CREATE_DOCTOR_DAY_SCHEDULE;
        body = {
          is_available: vals.is_available.toString(),
          morning_start_time: vals.morning_start_time
            ? vals.morning_start_time
            : undefined,
          morning_end_time: vals.morning_end_time
            ? vals.morning_end_time
            : undefined,
          afternoon_start_time: vals.afternoon_start_time
            ? vals.afternoon_start_time
            : undefined,
          afternoon_end_time: vals.afternoon_end_time
            ? vals.afternoon_end_time
            : undefined,
          evening_start_time: vals.evening_start_time
            ? vals.evening_start_time
            : undefined,
          evening_end_time: vals.evening_end_time
            ? vals.evening_end_time
            : undefined,
          slot_duration: vals.slot_duration,
          schedule_date: dateString,
        };
      }

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.put(
        `${endPoint}/${hospitalId}/${doctorId}${
          updateRecurringSchedule ? "" : "/" + appointType + "/" + dateString
        }`,
        body
      );

      if (res) {
        setShowToast(true);
        setToastType("success");
        setToastMessage(res.data?.message);
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
    // setIsLoading(true);

    // setToastType("error");
    //   setShowToast(true);
    //   setToastMessage(error.message);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="custom-modal"
        centered
        dialogClassName="vw-100"
        contentClassName="m-0 p-3"
      >
        <Modal.Header
          closeButton
          className="justify-content-center text-center w-100 update-schedule-modal"
        >
          {/* <div> */}
          <h1 className="h5 text-muted text-center">
            Update Recurring Schedule
          </h1>

          <BootstrapForm.Check
            type="switch"
            className="d-inline ms-2"
            checked={updateRecurringSchedule}
            value={updateRecurringSchedule}
            onChange={() => setUpdateRecurringSchedule((prev) => !prev)}
          />
          {/* </div> */}
        </Modal.Header>
        <Modal.Body>
          {!fetchingSchedule ? (
            <>
              <Formik
                initialValues={formValues}
                validationSchema={formValidationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <FormikForm>
                    <Container>
                      <Row>
                        <label className="col-12 col-sm-5 d-inline-flex align-items-center justify-content-start justify-content-sm-end fw-bold py-1 schedule-modal-odd-row">
                          Appointment Type:
                        </label>

                        <BootstrapForm.Group className="col-12 col-sm-7 d-flex align-items-center">
                          <Field name="update.appointmentType">
                            {({ field, meta }) => (
                              <>
                                {appointmentTypeOptions?.map((option) => (
                                  <React.Fragment key={option.value}>
                                    <input
                                      {...field}
                                      type="radio"
                                      name="update.appointmentType"
                                      value={option.value}
                                      checked={appointType === option.value}
                                      onChange={() =>
                                        handleChange(option.value)
                                      }
                                    />

                                    <label className="ms-2 me-4">
                                      {option.label}
                                    </label>
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </Field>
                        </BootstrapForm.Group>

                        <ErrorMessage
                          component={InputErrorMessage}
                          name="update.appointmentType"
                        />
                      </Row>
                    </Container>

                    <DoctorSchedule
                      setSelectedDate={setSelectedDate}
                      selectDate={selectDate}
                      setDirty={setDirty}
                      type="update"
                      isItDaySche={!updateRecurringSchedule}
                    />

                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        disabled={isSubmitting}
                        className="mx-3"
                        onClick={onHide}
                        style={{
                          backgroundColor: "white",
                          border: "1px solid primary",
                          color: "black",
                        }}
                      >
                        Cancel
                      </Button>

                      <Button disabled={isSubmitting} type="submit">
                        {!isSubmitting ? (
                          "Save"
                        ) : (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mx-3"
                          />
                        )}
                      </Button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </>
          ) : (
            <div className="text-center fw-bold w-100">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default UpdateScheduleModal;
